import { clsx } from 'clsx';
import { Fragment, ReactNode, useState } from 'react';
import {
  FieldArray,
  Formik,
  FormikTouched,
  useField,
  useFormikContext,
} from 'formik';
import { orderBy } from 'lodash-es';

import {
  ConstraintWithNumber as IConstraintWithNumber,
  ConstraintWithRange,
  ConstraintWithStatement as IConstraintWithStatement,
  DisplayLogicAndGroup,
  DisplayLogicOrGroups,
  ReactSelectValue,
} from '../../types/forms';
import {
  DisplayLogicLogicalModifier,
  QUESTION_TYPE,
  Question,
  QuestionConcept,
} from '../../types/domainModels';
import { errorsToTouched } from '../../util/forms';
import { getConceptOptions, getOptionOptions } from '../../util/formOptions';
import {
  getEmptyAndGroup,
  getEmptyConstraintWithNumber,
  getEmptyConstraintWithRange,
  getEmptyConstraintWithStatement,
  hasConfiguredDisplayLogic,
  MODIFIER_OPTIONS_DEFAULT,
  MODIFIER_OPTION_WITHIN,
  validateDisplayLogic,
} from '../../util/displayLogic';
import { isIdeaPresenterQuestion } from '../../util/questions';
import { QuestionGroup } from '../../types/internal';

import AddButton from '../common/forms/AddButton';
import Button from '../common/forms/Button';
import FormInput from '../common/forms/FormInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import Modal, { ModalHeader } from '../common/Modal';
import Select from '../common/forms/Select';
import WordSeparator from '../common/WordSeparator';
import XButton from '../common/forms/XButton';

interface DisplayLogicFormData {
  modalDisplayLogic: DisplayLogicOrGroups;
}

export interface Props {
  canAddOrGroups?: boolean;
  isWithinMonadicLoop: boolean;
  namePrefix: string;
  onCloseModal(): void;
  questionOptions: QuestionGroup<Question>[] | ReactSelectValue<Question>[];
  type?: 'block' | 'concept' | 'option' | 'question';
}

const DisplayLogicEditModal = ({
  canAddOrGroups = true,
  isWithinMonadicLoop,
  namePrefix,
  onCloseModal,
  questionOptions,
  type = 'question',
}: Props) => {
  const displayLogicValuesFieldName = `${namePrefix}.values`;
  const [
    { value: displayLogicValues },
    displayLogicValuesMeta,
    displayLogicValuesHelpers,
  ] = useField<DisplayLogicOrGroups>(displayLogicValuesFieldName);
  const [, , displayLogicEnabledHelpers] = useField<boolean>(
    `${namePrefix}.enabled`,
  );

  const [validateOnBlur, setValidateOnBlur] = useState(
    !!displayLogicValuesMeta.error,
  );

  function closeWithoutApply() {
    if (!hasConfiguredDisplayLogic(displayLogicValues)) {
      displayLogicEnabledHelpers.setValue(false);
    }

    onCloseModal();
  }

  return (
    <Modal
      header={
        <ModalHeader onClickClose={closeWithoutApply}>
          Display Logic
        </ModalHeader>
      }
      onCloseModal={closeWithoutApply}
      position="top"
      size="auto"
    >
      <Formik<DisplayLogicFormData>
        initialErrors={{
          modalDisplayLogic: displayLogicValuesMeta.error,
        }}
        initialTouched={
          displayLogicValuesMeta.error
            ? // The cast to FormikTouched<DisplayLogicFormData> is not ideal but Formik TypeScript
              // support is not great in the current version so this is a workaround until they have
              // better support.
              ({
                modalDisplayLogic: errorsToTouched(
                  displayLogicValuesMeta.error,
                ),
              } as FormikTouched<DisplayLogicFormData>)
            : undefined
        }
        initialValues={{ modalDisplayLogic: displayLogicValues }}
        onSubmit={(formData) => {
          const newDisplayLogic = formData.modalDisplayLogic;

          if (hasConfiguredDisplayLogic(newDisplayLogic)) {
            displayLogicValuesHelpers.setValue(newDisplayLogic);
          } else {
            displayLogicValuesHelpers.setValue([]);
            displayLogicEnabledHelpers.setValue(false);
          }

          onCloseModal();
        }}
        // Validation on blur is annoying if you haven't yet attempted to submit the form
        // because it may alert you to something you were already going to address.
        validate={(formData) => {
          const errors = validateDisplayLogic(formData.modalDisplayLogic);
          if (errors) {
            return { modalDisplayLogic: errors };
          }

          return {};
        }}
        validateOnBlur={validateOnBlur}
        validateOnChange={false}
      >
        <DisplayLogicForm
          canAddOrGroups={canAddOrGroups}
          isWithinMonadicLoop={isWithinMonadicLoop}
          onClickClose={closeWithoutApply}
          onSubmit={() => {
            setValidateOnBlur(true);
          }}
          questionOptions={questionOptions}
          type={type}
        />
      </Formik>
    </Modal>
  );
};

export default DisplayLogicEditModal;

const DisplayLogicForm = ({
  canAddOrGroups,
  isWithinMonadicLoop,
  onClickClose,
  onSubmit,
  questionOptions,
  type,
}: {
  canAddOrGroups: boolean;
  isWithinMonadicLoop: boolean;
  onClickClose(): void;
  onSubmit(): void;
  questionOptions: QuestionGroup<Question>[] | ReactSelectValue<Question>[];
  type: 'block' | 'concept' | 'option' | 'question';
}) => {
  const { submitForm } = useFormikContext();
  const [{ value: modalDisplayLogic }] =
    useField<DisplayLogicFormData['modalDisplayLogic']>('modalDisplayLogic');

  return (
    <div>
      <div className="w-edit-display-logic-modal">
        <div>
          <FieldArray
            name="modalDisplayLogic"
            render={(arrayHelpers) => {
              return (
                <>
                  {modalDisplayLogic.length === 0 ? (
                    <p className="mb-4 text-sm">
                      No display logic is currently configured.
                    </p>
                  ) : (
                    <p className="mb-2 text-sm">Display this {type} if:</p>
                  )}
                  {modalDisplayLogic.map((_orGroup, index) => {
                    return (
                      <Fragment key={index}>
                        <div>
                          <div
                            className={clsx('relative', {
                              // We only add the left padding if we can add OR groups which means we have a line
                              // on the left side of each or group to add visual grouping.
                              // '': canAddOrGroups,
                            })}
                          >
                            <DisplayLogicOrGroup
                              index={index}
                              isWithinMonadicLoop={isWithinMonadicLoop}
                              onRemoveGroup={() => {
                                arrayHelpers.remove(index);
                              }}
                              questionOptions={questionOptions}
                            />
                          </div>
                        </div>
                        <WordSeparator
                          word={
                            index !== modalDisplayLogic.length - 1
                              ? 'or'
                              : undefined
                          }
                        />
                      </Fragment>
                    );
                  })}
                  {(modalDisplayLogic.length === 0 || canAddOrGroups) && (
                    <div className="flex">
                      <AddButton
                        label={
                          modalDisplayLogic.length === 0
                            ? 'Add Display Logic'
                            : 'or'
                        }
                        onClick={() => {
                          arrayHelpers.push([getEmptyAndGroup()]);
                        }}
                      />
                    </div>
                  )}
                </>
              );
            }}
          />
        </div>
      </div>
      <div className="mt-12 flex flex-row-reverse gap-3">
        <Button
          hierarchy="primary"
          onClick={() => {
            submitForm();

            onSubmit();
          }}
          size="md"
          type="button"
        >
          Save Display Logic
        </Button>
        <Button
          hierarchy="secondary-gray"
          onClick={onClickClose}
          size="md"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

const DisplayLogicOrGroup = ({
  index,
  isWithinMonadicLoop,
  onRemoveGroup,
  questionOptions,
}: {
  index: number;
  isWithinMonadicLoop: boolean;
  onRemoveGroup(): void;
  questionOptions: QuestionGroup<Question>[] | ReactSelectValue<Question>[];
}) => {
  const orGroupPrefix = `modalDisplayLogic.${index}`;
  const [{ value: orGroup }] = useField<DisplayLogicOrGroups[0]>(orGroupPrefix);

  return (
    <FieldArray
      name={orGroupPrefix}
      render={(arrayHelpers) => {
        return (
          <div>
            <div>
              {orGroup.map((_orGroup, index) => {
                return (
                  <DisplayLogicRow
                    key={index}
                    index={index}
                    isWithinMonadicLoop={isWithinMonadicLoop}
                    namePrefix={orGroupPrefix}
                    onClickRemoveRow={() => {
                      arrayHelpers.remove(index);

                      if (orGroup.length === 1) {
                        onRemoveGroup();
                      }
                    }}
                    questionOptions={questionOptions}
                  />
                );
              })}
            </div>
            <div className="flex mt-2">
              <AddButton
                label="and"
                onClick={() => {
                  arrayHelpers.push(getEmptyAndGroup());
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

const DisplayLogicRow = ({
  index,
  isWithinMonadicLoop,
  namePrefix,
  onClickRemoveRow,
  questionOptions,
}: {
  index: number;
  isWithinMonadicLoop: boolean;
  namePrefix: string;
  onClickRemoveRow?: () => void;
  questionOptions: QuestionGroup<Question>[] | ReactSelectValue<Question>[];
}) => {
  const fieldNamePrefix = `${namePrefix}.${index}`;
  const questionFieldName = `${fieldNamePrefix}.question`;
  const modifierFieldName = `${fieldNamePrefix}.modifier`;
  const constraintsFieldName = `${fieldNamePrefix}.constraints`;

  const [{ value: question }] =
    useField<DisplayLogicAndGroup['question']>(questionFieldName);
  const [{ value: logicalModifier }, , logicalModifierHelpers] =
    useField<DisplayLogicAndGroup['modifier']>(modifierFieldName);
  const [, , constraintsHelpers] =
    useField<DisplayLogicAndGroup['constraints']>(constraintsFieldName);

  const isNumberQuestion =
    question?.value.questionTypeId === QUESTION_TYPE.NUMBER;

  let monadicConcepts: { label: string; value: QuestionConcept | null }[] = [];

  let constraintsContent: ReactNode = null;
  if (!question) {
    // This empty select is just decoration before a user chooses a question. It looks
    // better to have this than an empty space.
    constraintsContent = (
      <Select
        onChange={() => {
          // pass
        }}
        options={[]}
        value={null}
      />
    );
  } else if (isIdeaPresenterQuestion(question.value)) {
    constraintsContent = (
      <ConstraintWithConcepts
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        question={question.value}
      />
    );
  } else if (question.value.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
    constraintsContent = (
      <ConstraintWithOptions
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        question={question.value}
      />
    );
  } else if (question.value.questionTypeId === QUESTION_TYPE.SCALE) {
    constraintsContent = (
      <ConstraintWithRanges
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        optionRangeSeparator={
          <span className="text-xs">
            with a value
            <br />
            between
          </span>
        }
        question={question.value}
      />
    );
  } else if (question.value.questionTypeId === QUESTION_TYPE.RANKING) {
    constraintsContent = (
      <ConstraintWithRanges
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        optionRangeSeparator={
          <span className="text-xs">
            with a rank
            <br />
            value between
          </span>
        }
        question={question.value}
      />
    );
  } else if (question.value.questionTypeId === QUESTION_TYPE.MATRIX) {
    constraintsContent = (
      <ConstraintWithStatement
        logicalModifier={logicalModifier?.value}
        namePrefix={constraintsFieldName}
        question={question.value}
      />
    );
  } else if (isNumberQuestion) {
    constraintsContent = (
      <ConstraintWithNumber namePrefix={constraintsFieldName} />
    );
  }

  let filteredModifiers = MODIFIER_OPTIONS_DEFAULT;
  if (isNumberQuestion) {
    // Number questions currently only support a single modifier.
    filteredModifiers = [MODIFIER_OPTION_WITHIN];
  }

  if (question?.value.monadicId) {
    const questions = questionOptions.flatMap((q) => {
      if ('options' in q) {
        return q.options.map((q) => q.value);
      }

      return q.value;
    });
    const monadicQuestions = questions.filter(
      (q) => q.monadicId === question.value.monadicId,
    );

    const monadicConceptQuestion = orderBy(monadicQuestions, (q) => q.sort)[0];

    if (monadicConceptQuestion) {
      monadicConcepts =
        monadicConceptQuestion.concepts?.map((ctm) => ({
          label: ctm.description,
          value: ctm,
        })) ?? [];

      monadicConcepts = [
        { label: 'Any concept', value: null },
        ...monadicConcepts,
      ];
    }
  }

  return (
    <div>
      <div className="flex py-2 space-x-2 items-start">
        <div className="flex-shrink-0 w-2/6">
          <FormSearchSelectInput<ReactSelectValue<Question>>
            name={questionFieldName}
            onChange={(newQuestion) => {
              if (!newQuestion || newQuestion.value.id === question?.value.id) {
                return;
              }

              constraintsHelpers.setError('');

              if (
                newQuestion.value.questionTypeId === QUESTION_TYPE.RANKING ||
                newQuestion.value.questionTypeId === QUESTION_TYPE.SCALE
              ) {
                constraintsHelpers.setValue([getEmptyConstraintWithRange()]);
              } else if (
                newQuestion.value.questionTypeId === QUESTION_TYPE.MATRIX
              ) {
                constraintsHelpers.setValue([
                  getEmptyConstraintWithStatement(),
                ]);
              } else if (
                newQuestion.value.questionTypeId === QUESTION_TYPE.NUMBER
              ) {
                logicalModifierHelpers.setValue(MODIFIER_OPTION_WITHIN);
                constraintsHelpers.setValue([getEmptyConstraintWithNumber()]);
              } else if (isIdeaPresenterQuestion(newQuestion.value)) {
                constraintsHelpers.setValue([{ concepts: [] }]);
              } else {
                constraintsHelpers.setValue([{ options: [] }]);
              }
            }}
            options={questionOptions}
            placeholder="Question..."
          />
        </div>
        <div className="text-sm text-gray-800 flex items-center justify-center bg-gray-300 rounded w-12 h-[38px]">
          is
        </div>
        <div className="flex-shrink-0 w-24">
          <FormSearchSelectInput
            // Number questions currently one have one option for the modifier (which is set during
            // selection of the number question).
            isDisabled={isNumberQuestion}
            name={`${fieldNamePrefix}.modifier`}
            options={filteredModifiers}
          />
        </div>
        <div className="flex-grow">{constraintsContent}</div>
        {onClickRemoveRow && (
          <div className="mt-3">
            <IconBackground
              onClick={onClickRemoveRow}
              size="small"
              title="Remove"
            >
              <div className="w-3 h-3">
                <Icon id="trash" />
              </div>
            </IconBackground>
          </div>
        )}
      </div>
      {!isIdeaPresenterQuestion(question?.value) &&
        question?.value?.monadicId && (
          <>
            <div className="flex w-full">
              <span className="flex-shrink-0 mt-2 mr-2 text-xs">
                when {isWithinMonadicLoop ? 'current' : ''} concept seen is
              </span>
              <div className="flex-shrink-0 w-64">
                <FormSearchSelectInput
                  name={`${fieldNamePrefix}.concept`}
                  options={monadicConcepts}
                />
              </div>
            </div>
          </>
        )}
    </div>
  );
};

const ConstraintWithStatement = ({
  logicalModifier,
  namePrefix,
  question,
}: {
  logicalModifier: DisplayLogicLogicalModifier | undefined;
  namePrefix: string;
  question: {
    matrixOptions: { sort: number; title: string }[];
    options: {
      description: string | null;
      isActive: boolean;
      sort: number;
      title: string;
    }[];
  };
}) => {
  const [{ value: constraints }] =
    useField<IConstraintWithStatement[]>(namePrefix);

  const options = orderBy(question.matrixOptions, (o) => o.sort).map(
    (option) => {
      return {
        label: option.title,
        value: option,
      };
    },
  );

  return (
    <FieldArray
      name={namePrefix}
      render={(arrayHelpers) => {
        return (
          <div>
            <div className="space-y-2">
              {constraints.map((_range, index) => {
                const constraintFieldName = `${namePrefix}.${index}`;

                return (
                  <div key={index} className="flex space-x-2">
                    <div className="w-1/2">
                      <FormSearchSelectInput
                        name={`${constraintFieldName}.statement`}
                        options={getOptionOptions({
                          options: question.options,
                        })}
                      />
                    </div>
                    <div className="w-1/2">
                      <FormSearchSelectInput
                        isMulti={logicalModifier !== 'is'}
                        name={`${constraintFieldName}.options`}
                        options={options}
                      />
                    </div>
                    {constraints.length > 1 && (
                      <div className="mt-3">
                        <XButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          title="Remove"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex mt-2">
              <AddButton
                label={logicalModifier === 'is' ? 'and' : 'or'}
                onClick={() => {
                  arrayHelpers.push(getEmptyConstraintWithStatement());
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

const ConstraintWithConcepts = ({
  logicalModifier,
  namePrefix,
  question,
}: {
  logicalModifier: DisplayLogicLogicalModifier | undefined;
  namePrefix: string;
  question: { concepts?: { description: string; id: number }[] };
}) => {
  return (
    <FormSearchSelectInput
      isMulti={logicalModifier !== 'is'}
      name={`${namePrefix}.0.concepts`}
      options={getConceptOptions({ concepts: question.concepts ?? [] })}
    />
  );
};

const ConstraintWithOptions = ({
  logicalModifier,
  namePrefix,
  question,
}: {
  logicalModifier: DisplayLogicLogicalModifier | undefined;
  namePrefix: string;
  question: {
    options: {
      description: string | null;
      isActive: boolean;
      sort: number;
      title: string;
    }[];
  };
}) => {
  return (
    <FormSearchSelectInput
      isMulti={logicalModifier !== 'is'}
      name={`${namePrefix}.0.options`}
      options={getOptionOptions({ options: question.options })}
    />
  );
};

const ConstraintWithRanges = ({
  logicalModifier,
  namePrefix,
  optionRangeSeparator,
  question,
}: {
  logicalModifier: DisplayLogicLogicalModifier | undefined;
  namePrefix: string;
  optionRangeSeparator?: ReactNode;
  question: {
    options: {
      description: string | null;
      isActive: boolean;
      sort: number;
      title: string;
    }[];
  };
}) => {
  const [{ value: constraints }] = useField<ConstraintWithRange[]>(namePrefix);

  return (
    <FieldArray
      name={namePrefix}
      render={(arrayHelpers) => {
        return (
          <div>
            <div className="space-y-2">
              {constraints.map((_range, index) => {
                const constraintFieldName = `${namePrefix}.${index}`;

                return (
                  <div key={index} className="flex space-x-2">
                    <div className="flex-grow">
                      <FormSearchSelectInput
                        name={`${constraintFieldName}.option`}
                        options={getOptionOptions({
                          options: question.options,
                        })}
                      />
                    </div>
                    {optionRangeSeparator}
                    <div className="flex space-x-2">
                      <div className="w-16">
                        <FormInput
                          name={`${constraintFieldName}.range.start`}
                          size="md"
                          type="number"
                        />
                      </div>
                      <span className="mt-2 text-xs">and</span>
                      <div className="w-16">
                        <FormInput
                          name={`${constraintFieldName}.range.end`}
                          size="md"
                          type="number"
                        />
                      </div>
                    </div>
                    {constraints.length > 1 && (
                      <div className="mt-3">
                        <XButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          title="Remove"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex mt-2">
              <AddButton
                label={logicalModifier === 'is' ? 'and' : 'or'}
                onClick={() => {
                  arrayHelpers.push(getEmptyConstraintWithRange());
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

const ConstraintWithNumber = ({
  namePrefix,
}: {
  namePrefix: string;
}): JSX.Element => {
  const [{ value: constraints }] =
    useField<IConstraintWithNumber[]>(namePrefix);

  return (
    <FieldArray
      name={namePrefix}
      render={(arrayHelpers) => {
        return (
          <div>
            <div className="space-y-2">
              {constraints.map((_range, index) => {
                const constraintFieldName = `${namePrefix}.${index}`;

                return (
                  <div key={index} className="flex space-x-2">
                    <div className="flex-grow">
                      <FormInput
                        name={`${constraintFieldName}.range.start`}
                        size="md"
                        type="number"
                      />
                    </div>
                    <span className="mt-2 text-xs">and</span>
                    <div className="flex-grow">
                      <FormInput
                        name={`${constraintFieldName}.range.end`}
                        size="md"
                        type="number"
                      />
                    </div>
                    {constraints.length > 1 && (
                      <div className="mt-3">
                        <XButton
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                          title="Remove"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
};
