import { ReactNode } from 'react';

import { Survey, SurveyWave } from 'types/domainModels';
import { useModal } from 'hooks/modals';
import DiscardWaveModal from 'components/common/DiscardWaveModal';
import Icon from 'components/common/Icon';
import SaveWaveModal from 'components/common/SaveWaveModal';

const SurveyWaveTitle = ({
  buttons,
  description,
  title,
}: {
  buttons: ReactNode;
  description?: string;
  title: string;
}) => {
  return (
    <div className="space-y-1 w-full">
      <div className="flex items-center space-x-2">
        <div className="text-base font-medium text-black">{title}</div>

        {buttons}
      </div>

      {description && (
        <p className="text-gray-d-600 text-xs truncate">{description}</p>
      )}
    </div>
  );
};

export default SurveyWaveTitle;

export const SurveyWaveTitleEditPage = ({ survey }: { survey?: Survey }) => {
  if (!survey || !survey.wave || survey.wave.waveValue <= 1) {
    return null;
  }

  const { wave } = survey;

  return (
    <div className="flex items-center h-8 mb-5">
      <SurveyWaveTitle
        buttons={
          <>
            <SurveyWaveDelete surveyId={survey.id} wave={wave} />
            <SurveyWaveEdit wave={wave} />
          </>
        }
        description={wave.description}
        title={wave.title}
      />
    </div>
  );
};

export const SurveyWaveDelete = ({
  surveyId,
  wave,
}: {
  surveyId: number;
  wave: SurveyWave;
}) => {
  const {
    isOpen: isDiscardWaveModalOpen,
    onCloseModal: onCloseDiscardWaveModal,
    setIsOpen: setDiscardWaveModalOpen,
  } = useModal();

  return (
    <>
      <button
        className="w-3.5 h-3.5 text-gray-d-500"
        onClick={() => {
          setDiscardWaveModalOpen(true);
        }}
        type="button"
      >
        <Icon id="trash" />
      </button>

      {isDiscardWaveModalOpen && (
        <DiscardWaveModal
          onCloseModal={onCloseDiscardWaveModal}
          surveyId={surveyId}
          wave={wave}
        />
      )}
    </>
  );
};

export const SurveyWaveEdit = ({ wave }: { wave: SurveyWave }) => {
  const {
    isOpen: isSaveWaveModalOpen,
    onCloseModal: onCloseSaveWaveModal,
    setIsOpen: setSaveWaveModalOpen,
  } = useModal();

  return (
    <>
      <button
        className="w-3.5 h-3.5 text-gray-d-500"
        onClick={() => {
          setSaveWaveModalOpen(true);
        }}
        type="button"
      >
        <Icon id="edit" />
      </button>

      {isSaveWaveModalOpen && (
        <SaveWaveModal
          onCloseModal={onCloseSaveWaveModal}
          onWaveSaved={onCloseSaveWaveModal}
          surveyId={wave.surveyId}
          wave={wave}
        />
      )}
    </>
  );
};
