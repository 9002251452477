import { useState } from 'react';

import { DataUrl } from 'types/domainModels';
import { getMediaUrl, isDataUrl } from '../../util/media';

import Tooltip from './Tooltip';

// Instead of constantly trying to load the user's image, if it fails to load,
// we store it here so other instaces of the UserBubble component can quickly
// fall back to displaying the user's initials. On page reload, we'll try again.
const FAILED_IMAGE_USER_IDS = new Set<number>();

const backgroundColors = [
  '#DBF8D1', // mint
  '#FBDFC5', // peach
  '#FEE4E2', // error-100
  '#D1E9FF', // blue-100
  '#F7F7F7', // gray-100
];
const textColors = [
  '#027A48', // green-700
  '#B54708', // warning-700
  '#B42318', // error-700
  '#175CD3', // blue-700
  '#585858', // gray-700
];

const UserBubble = ({
  user,
  withTooltip = true,
}: {
  user: {
    id: number;
    firstName: string;
    image: DataUrl | Record<string, unknown> | null;
    lastName: string;
  };
  withTooltip?: boolean;
}): JSX.Element => {
  const [imageFailedToLoad, setImageFailedToLoad] = useState(
    FAILED_IMAGE_USER_IDS.has(user.id),
  );

  let initials = '';
  if (user.firstName && user.lastName) {
    initials = `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
  }

  const bgColor = backgroundColors[user.id % backgroundColors.length];
  const textColor = textColors[user.id % textColors.length];

  const content = (
    <div className="flex overflow-hidden items-center justify-center w-8 h-8 rounded-full">
      {isDataUrl(user.image) && !imageFailedToLoad ? (
        <img
          className="object-cover h-full w-full"
          onError={() => {
            FAILED_IMAGE_USER_IDS.add(user.id);
            setImageFailedToLoad(true);
          }}
          src={getMediaUrl(user.image)}
        />
      ) : (
        <div
          className="flex items-center justify-center w-full h-full text-xs cursor-default"
          style={{
            background: bgColor,
            color: textColor,
          }}
        >
          {initials}
        </div>
      )}
    </div>
  );

  return withTooltip ? (
    <Tooltip trigger={content}>
      {user.firstName} {user.lastName}
    </Tooltip>
  ) : (
    content
  );
};

export default UserBubble;
