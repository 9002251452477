import { Cropper, ReactCropperElement } from 'react-cropper';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

import Button from 'components/common/forms/Button';
import Modal, { ModalHeader } from 'components/common/Modal';
import SliderToggle from 'components/common/SliderToggle';

const CropModal = ({
  imageFormat,
  imageToCrop,
  onCloseModal,
  onCrop,
}: {
  imageFormat: string;
  imageToCrop: string;
  onCloseModal(): void;
  onCrop(imageData: string): void;
}): JSX.Element => {
  const cropperRef = useRef<ReactCropperElement | null>(null);

  const [usingBackground, setUsingBackground] = useState<boolean>(true);

  const handleCrop = () => {
    const imageData = cropperRef.current?.cropper
      .getCroppedCanvas()
      .toDataURL();
    if (imageData) {
      onCrop(imageData);
    }
  };

  return (
    <Modal
      header={<ModalHeader onClickClose={onCloseModal}>Crop Image</ModalHeader>}
      onCloseModal={onCloseModal}
      position="full-screen"
      size="auto"
    >
      <div className="h-full flex flex-grow flex-col">
        <p className="mb-4 text-sm">
          Drag the blue box below to crop your image. By default, the entire
          image will be shown.
        </p>
        {imageFormat === 'png' && (
          <div className="flex justify-end mb-2">
            <div className="w-28">
              <SliderToggle
                isFluidWidth={false}
                labels={['Background']}
                onChange={() => {
                  setUsingBackground(!usingBackground);
                }}
                value={usingBackground}
              />
            </div>
          </div>
        )}

        <CropArea
          cropperRef={cropperRef}
          imageToCrop={imageToCrop}
          usingBackground={usingBackground}
        />

        <div className="mt-8 flex gap-3 flex-row-reverse">
          <Button
            grow
            hierarchy="primary"
            onClick={() => {
              handleCrop();
            }}
            size="lg"
            type="button"
          >
            Done
          </Button>
          <Button
            grow
            hierarchy="secondary-gray"
            onClick={onCloseModal}
            size="lg"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CropModal;

const CropArea = ({
  cropperRef,
  imageToCrop,
  usingBackground,
}: {
  cropperRef: MutableRefObject<ReactCropperElement | null>;
  imageToCrop: string;
  usingBackground: boolean;
}) => {
  const cropperContainer = useRef<HTMLDivElement | null>(null);
  const [cropperHeight, setCropperHeight] = useState<number | undefined>(
    undefined,
  );

  // The crop modal is a full screen modal and we want the area to crop the image
  // to take up as much space as possible. We need to set a hardcoded height or else
  // the Cropper component will expand depending on the size of the uploaded image.
  // So first, we measure the available space (as our flex container will allow) and
  // then use that as the hardcoded height.
  useEffect(() => {
    if (cropperContainer.current) {
      setCropperHeight(cropperContainer.current.offsetHeight);
    }
  }, []);

  return (
    <div
      ref={cropperContainer}
      className="flex flex-grow items-center justify-center w-crop-image-modal bg-pure-black overflow-hidden"
      style={{
        height: cropperHeight,
      }}
    >
      {cropperHeight !== undefined && (
        <div className="w-4/5 h-4/5">
          <Cropper
            ref={cropperRef}
            autoCrop={true}
            autoCropArea={1}
            src={imageToCrop}
            style={{
              width: '100%',
              height: '99%', // this prevents a strip of white under the cropper; why?
              backgroundColor: usingBackground ? '#fff' : '#000',
            }}
            viewMode={1}
            zoomOnWheel={false}
          />
        </div>
      )}
    </div>
  );
};
