import FormCheckbox from '../../common/forms/FormCheckbox';

const RandomizeMatrixOptions = () => {
  return (
    <FormCheckbox
      checkboxLabel="Randomize Options"
      name="features.randomizeMatrixOptions"
    />
  );
};

export default RandomizeMatrixOptions;
