import invariant from 'tiny-invariant';

type EnvVars = {
  API_BASE_URL: string;
  APP_ENV: 'local' | 'staging' | 'production' | 'test';
  CLOUDINARY_CLOUD_NAME: string;
  FULLSTORY_ORG_ID: string;
  NODE_ENV: string;
  RESPONDENT_HOST: string;
  SEGMENT_WRITE_KEY: string;
  STRIPE_APP_ID: string;
};

const ALLOWED_APP_ENVS = ['local', 'staging', 'production', 'test'] as const;

function isAllowedAppEnv(appEnv: string): appEnv is EnvVars['APP_ENV'] {
  return ALLOWED_APP_ENVS.includes(appEnv as EnvVars['APP_ENV']);
}

let ENV_VARS: {
  [key in keyof EnvVars]: EnvVars[key];
};

if (process.env.NODE_ENV === 'test') {
  ENV_VARS = {
    API_BASE_URL: 'https://test-api.useglass.com',
    APP_ENV: 'test',
    CLOUDINARY_CLOUD_NAME: '',
    FULLSTORY_ORG_ID: '',
    NODE_ENV: 'test',
    RESPONDENT_HOST: 'https://test-respondent.useglass.com',
    SEGMENT_WRITE_KEY: 'test-key',
    STRIPE_APP_ID: 'test-stirpe-app-id',
  };
} else {
  invariant(import.meta.env.VITE_API_BASE_URL, 'VITE_API_BASE_URL must be set');
  invariant(
    import.meta.env.VITE_APP_ENV &&
      isAllowedAppEnv(import.meta.env.VITE_APP_ENV),
    `APP_ENV must be one of: ${ALLOWED_APP_ENVS.join(', ')}`,
  );
  invariant(
    import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
    'VITE_CLOUDINARY_CLOUD_NAME must be set',
  );
  invariant(
    import.meta.env.VITE_RESPONDENT_HOST,
    'VITE_RESPONDENT_HOST must be set',
  );
  invariant(
    import.meta.env.VITE_SEGMENT_WRITE_KEY,
    'VITE_SEGMENT_WRITE_KEY must be set',
  );
  invariant(
    import.meta.env.VITE_STRIPE_APP_ID,
    'VITE_STRIPE_APP_ID must be set',
  );

  ENV_VARS = {
    API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
    APP_ENV: import.meta.env.VITE_APP_ENV,
    CLOUDINARY_CLOUD_NAME: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
    FULLSTORY_ORG_ID: import.meta.env.VITE_FULLSTORY_ORG_ID,
    NODE_ENV: import.meta.env.NODE_ENV,
    RESPONDENT_HOST: import.meta.env.VITE_RESPONDENT_HOST,
    SEGMENT_WRITE_KEY: import.meta.env.VITE_SEGMENT_WRITE_KEY,
    STRIPE_APP_ID: import.meta.env.VITE_STRIPE_APP_ID,
  };
}

export function getEnvVar<EnvVar extends keyof EnvVars>(
  envVar: EnvVar,
): EnvVars[EnvVar] {
  return ENV_VARS[envVar];
}
