import { minutesToMilliseconds } from 'date-fns';
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { sortBy } from 'lodash-es';

import {
  changeOrganization,
  createOrganization,
  fetchOrganizations,
} from 'services/backend/organizations';
import { ReactQueryFunctionCallbacks } from 'types/internal';
import { useAuth } from 'contexts/auth';

export const organizationQueries = {
  list: queryOptions({
    queryFn: () => fetchOrganizations(),
    queryKey: ['organizations'],
    staleTime: minutesToMilliseconds(10),
  }),
};

export function useChangeOrganization(
  opts: ReactQueryFunctionCallbacks<typeof changeOrganization>,
) {
  return useMutation({ ...opts, mutationFn: changeOrganization });
}

export function useCreateOrganization(
  opts?: ReactQueryFunctionCallbacks<typeof createOrganization>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: createOrganization,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(organizationQueries.list);

      opts?.onSuccess?.(...args);
    },
  });
}

/**
 * Returns the current organization the user is viewing. A user can belong to one or more organizations. If they
 * belong to multiple, they can "switch into" an organization to view its data.
 */
export function useCurrentOrganization() {
  const { data } = useAuth();

  const { data: fetchOrgsResponse } = useQuery(organizationQueries.list);
  const organizations = fetchOrgsResponse?.organizations ?? [];

  return organizations.find((org) => org.id === data?.organizationId);
}

export function useOrderedOrganizations() {
  const { data: fetchOrganizationsResponse, isLoading: isLoadingOrgs } =
    useQuery(organizationQueries.list);
  const organizations = sortBy(
    (fetchOrganizationsResponse?.organizations ?? []).map((org) => {
      return { label: org.name, value: org.id };
    }),
    (org) => org.label,
  );

  return { isLoadingOrgs, organizations };
}
