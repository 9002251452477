import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { cloneElement, ReactNode, useState } from 'react';

const PopoverNew = ({
  children,
  hoverDelay = 200,
  open = undefined,
  openOnClick = true,
  openOnHover = false,
  placement = 'top',
  setIsOpen = undefined,
  trigger,
}: {
  children: ReactNode;
  hoverDelay?: number;
  open?: boolean;
  openOnClick?: boolean;
  openOnHover?: boolean;
  placement?: Placement;
  setIsOpen?(open: boolean): void;
  trigger: JSX.Element;
}) => {
  const [internalOpen, setInternalOpen] = useState(false);

  const isOpen = open ?? internalOpen;
  const setOpen = setIsOpen ?? setInternalOpen;

  const { context, refs, strategy, x, y } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setOpen,
    middleware: [
      offset(({ placement }) => ({
        // The value of "8" takes into account the space for the arrow and then a little
        // extra to separate the tooltip from its trigger element.
        mainAxis: placement === 'top' ? 8 : 8,
      })),
      flip(),
      shift({ padding: 8 }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { enabled: openOnClick }),
    useHover(context, {
      enabled: openOnHover,
      handleClose: safePolygon(),
      restMs: hoverDelay,
    }),
    useRole(context, { role: 'dialog' }),
    useDismiss(context),
  ]);

  return (
    <>
      {cloneElement(
        trigger,
        getReferenceProps({ ref: refs.setReference, ...trigger.props }),
      )}

      <FloatingPortal>
        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              className="z-40 max-w-xs rounded bg-white shadow-lg"
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
              {...getFloatingProps()}
            >
              {children}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
};

export default PopoverNew;
