import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';

import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { SurveyWave } from '../../types/domainModels';
import { useDeleteWave } from 'hooks/backend/surveys';

import Button from './forms/Button';
import ButtonLoading from './forms/ButtonLoading';
import Modal, { ModalHeader } from './Modal';

const DiscardWaveModal = ({
  onCloseModal,
  surveyId,
  wave,
}: {
  onCloseModal(): void;
  surveyId: number;
  wave: SurveyWave;
}): JSX.Element => {
  const navigate = useNavigate();
  const discardWaveBtn = useRef<HTMLButtonElement | null>(null);

  const { isPending: isDeletingWave, mutate: deleteWave } = useDeleteWave({
    onError: () => {
      showErrorMessage(
        'There was a problem discarding this wave. Please contact support.',
      );
    },
    onSuccess: () => {
      showSuccessMessage('The wave was discarded successfully.');
      onCloseModal();
      navigate(`/campaign/${wave.surveyId}`);
    },
  });

  return (
    <Modal
      header={
        <ModalHeader
          onClickClose={onCloseModal}
          supportingText={`Are you sure you want to discard the ${wave.title} wave? This action cannot be reversed.`}
        >
          Discard Wave
        </ModalHeader>
      }
      initialFocus={discardWaveBtn}
      onCloseModal={onCloseModal}
      size="sm"
    >
      <div className="flex flex-row-reverse mt-8 gap-3">
        <ButtonLoading
          ref={discardWaveBtn}
          grow
          hierarchy="destructive"
          isLoading={isDeletingWave}
          onClick={() => deleteWave({ surveyId, waveId: wave.id })}
          size="lg"
        >
          Yes, discard wave
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onCloseModal}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default DiscardWaveModal;
