import { minutesToMilliseconds } from 'date-fns';
import { queryOptions } from '@tanstack/react-query';

import { listPanelProviders } from 'services/backend/panelProviders';

export const panelProviderQueries = {
  all: ['panelProviders'],
  list: () =>
    queryOptions({
      queryFn: () => listPanelProviders(),
      queryKey: panelProviderQueries.all,
      staleTime: minutesToMilliseconds(30),
    }),
};
