import { minutesToMilliseconds } from 'date-fns';
import {
  queryOptions,
  skipToken,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  cloneSurvey,
  cloneSurveyInLucid,
  createSurvey,
  createSurveyFromTemplate,
  createSurveyTemplate,
  createWave,
  deleteSurvey,
  deleteWave,
  editWave,
  fetchIncidence,
  fetchSurvey,
  fetchSurveyAudienceSlices,
  fetchSurveyCompletes,
  fetchSurveyTemplates,
  fetchWaves,
  relaunchExternalSurvey,
  removeRespondents,
  updateInboundCensus,
  updateParticipants,
  updateSurvey,
  updateSurveyStatus,
} from 'services/backend/surveys';
import { questionQueries } from './questions';
import { questionQuotaQueries } from './questionQuotas';
import { ReactQueryFunctionCallbacks } from 'types/internal';
import { SURVEY_STATUSES } from 'constants/surveyStatuses';
import { variableQuotaQueries } from './surveyVariableQuotas';

export const surveyQueries = {
  all: () => ['surveys'],
  allAudienceSlices: (opts: { surveyId: number }) => [
    ...surveyQueries.all(),
    opts.surveyId,
    'audience-slices',
  ],
  allWaves: (opts: { surveyId: number }) => [
    ...surveyQueries.all(),
    opts.surveyId,
    'waves',
  ],
  incidence: ({
    surveyId,
    waveId,
  }: {
    surveyId: number;
    waveId: number | undefined;
  }) =>
    queryOptions({
      enabled: !!waveId,
      queryFn: waveId ? () => fetchIncidence({ surveyId, waveId }) : skipToken,
      queryKey: [...surveyQueries.all(), surveyId, 'incidence', waveId],
    }),
  results: (opts: { surveyId: number }) => [
    ...surveyQueries.all(),
    opts.surveyId,
    'results',
  ],
  survey: (opts: { surveyId: number }) =>
    queryOptions({
      queryFn: () => fetchSurvey({ surveyId: opts.surveyId }),
      queryKey: [...surveyQueries.all(), opts.surveyId],
    }),
  surveyAudienceSlices: (opts: { surveyId: number; waveIds: number[] }) =>
    queryOptions({
      queryFn: () =>
        fetchSurveyAudienceSlices({
          surveyId: opts.surveyId,
          waveIds: opts.waveIds,
        }),
      queryKey: [
        ...surveyQueries.allAudienceSlices({ surveyId: opts.surveyId }),
        opts.waveIds,
      ],
    }),
  surveyCompletes: (opts: { surveyId: number; waveIds: number[] }) =>
    queryOptions({
      queryFn: () =>
        fetchSurveyCompletes({
          surveyId: opts.surveyId,
          waveIds: opts.waveIds,
        }),
      queryKey: [
        ...surveyQueries.all(),
        opts.surveyId,
        'completes',
        opts.waveIds,
      ],
      staleTime: minutesToMilliseconds(0.25),
    }),
  surveyTemplates: queryOptions({
    queryFn: fetchSurveyTemplates,
    queryKey: ['survey-templates'],
    staleTime: minutesToMilliseconds(5),
  }),
  waves: (opts: { surveyId: number }) =>
    queryOptions({
      queryFn: () => fetchWaves({ surveyId: opts.surveyId }),
      queryKey: surveyQueries.allWaves({ surveyId: opts.surveyId }),
    }),
};

export function useCloneSurvey(
  opts?: ReactQueryFunctionCallbacks<typeof cloneSurvey>,
) {
  return useMutation({ ...opts, mutationFn: cloneSurvey });
}

export function useCloneSurveyInLucid(
  opts?: ReactQueryFunctionCallbacks<typeof cloneSurveyInLucid>,
) {
  return useMutation({ ...opts, mutationFn: cloneSurveyInLucid });
}

export function useCreateSurveyFromTemplate(
  opts?: ReactQueryFunctionCallbacks<typeof createSurveyFromTemplate>,
) {
  return useMutation({ ...opts, mutationFn: createSurveyFromTemplate });
}

export function useCreateSurveyTemplate(
  opts?: ReactQueryFunctionCallbacks<typeof createSurveyTemplate>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: createSurveyTemplate,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: surveyQueries.surveyTemplates.queryKey,
      });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useDeleteSurvey(
  opts?: ReactQueryFunctionCallbacks<typeof deleteSurvey>,
) {
  return useMutation({ ...opts, mutationFn: deleteSurvey });
}

export function useDeleteWave(
  opts?: ReactQueryFunctionCallbacks<typeof deleteWave>,
) {
  const { mutateAsync: updateSurveyStatus } = useUpdateSurveyStatus();

  return useMutation({
    ...opts,
    mutationFn: async ({
      surveyId,
      waveId,
    }: {
      surveyId: number;
      waveId: number;
    }) => {
      const deleteWaveResult = await deleteWave({ waveId });

      await updateSurveyStatus({
        data: { statusId: SURVEY_STATUSES.COMPLETED.id },
        surveyId,
      });

      return deleteWaveResult;
    },
  });
}

export function useRelaunchExternalSurvey(
  opts?: ReactQueryFunctionCallbacks<typeof relaunchExternalSurvey>,
) {
  return useMutation({ ...opts, mutationFn: relaunchExternalSurvey });
}

export function useRemoveRespondents(
  opts?: ReactQueryFunctionCallbacks<typeof removeRespondents>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: removeRespondents,
    onSuccess: (...args) => {
      // This invalidation is a little more vague than I would like, but the intent is to
      // invalidate the question results queries because open-ended questions could have
      // had some of their respondents removed, in which case the answers should no longer
      // show up on the question analytics page.
      queryClient.invalidateQueries({ queryKey: surveyQueries.all() });
      queryClient.invalidateQueries({ queryKey: questionQueries.all() });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useSaveSurvey(
  opts?: ReactQueryFunctionCallbacks<typeof createSurvey | typeof updateSurvey>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: async (
      data: Parameters<typeof createSurvey | typeof updateSurvey>[0],
    ) => {
      if ('surveyId' in data) {
        const updateSurveyResult = await updateSurvey(data);

        await queryClient.invalidateQueries(
          surveyQueries.survey({ surveyId: data.surveyId }),
          { throwOnError: true },
        );

        return updateSurveyResult;
      }

      return createSurvey(data);
    },
  });
}

export function useSaveWave(
  opts?: ReactQueryFunctionCallbacks<typeof createWave | typeof editWave>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: async (
      data: Parameters<typeof createWave | typeof editWave>[0],
    ) => {
      if ('waveId' in data) {
        return editWave(data);
      }

      await updateSurveyStatus({
        data: { statusId: SURVEY_STATUSES.DRAFT.id },
        surveyId: data.surveyId,
      });

      return createWave(data);
    },
    onError: (...args) => {
      // Attempt to roll-back the survey status. Ideally creating a new wave would be a single
      // call because we'll be in a weird state if this rollback fails.
      if ('surveyId' in args[1]) {
        updateSurveyStatus({
          data: { statusId: SURVEY_STATUSES.COMPLETED.id },
          surveyId: args[1].surveyId,
        });
      }

      opts?.onError?.(...args);
    },
    onSuccess: (...args) => {
      queryClient.invalidateQueries(
        surveyQueries.survey({ surveyId: args[0].wave.surveyId }),
      );

      opts?.onSuccess?.(...args);
    },
  });
}

export function useUpdateParticipants(
  opts?: ReactQueryFunctionCallbacks<typeof updateParticipants>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: updateParticipants,
    onSuccess: (...args) => {
      const surveyID = args[1].surveyID;
      queryClient.invalidateQueries({
        queryKey: surveyQueries.allWaves({ surveyId: surveyID }),
      });
      queryClient.invalidateQueries({
        queryKey: surveyQueries.allAudienceSlices({ surveyId: surveyID }),
      });
      queryClient.invalidateQueries({
        queryKey: questionQuotaQueries.all({ surveyId: surveyID }),
      });
      queryClient.invalidateQueries({
        queryKey: variableQuotaQueries.all({ surveyId: surveyID }),
      });

      opts?.onSuccess?.(...args);
    },
  });
}

export function useUpdateInboundCensus(
  opts?: ReactQueryFunctionCallbacks<typeof updateInboundCensus>,
) {
  return useMutation({ ...opts, mutationFn: updateInboundCensus });
}

export function useUpdateSurvey(
  opts?: ReactQueryFunctionCallbacks<typeof updateSurvey>,
) {
  return useMutation({ ...opts, mutationFn: updateSurvey });
}

export function useUpdateSurveyStatus(
  opts?: ReactQueryFunctionCallbacks<typeof updateSurveyStatus>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: updateSurveyStatus,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(
        surveyQueries.survey({ surveyId: args[1].surveyId }),
      );

      opts?.onSuccess?.(...args);
    },
  });
}
