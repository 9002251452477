import { ReactNode, useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';

import { DataUrl } from '../../types/domainModels';
import { getMediaUrl, MediaPreview, MediaWithPreview } from 'util/media';
import { mediaUploadMachine } from '../surveyEdit/machines/mediaUpload';

import Button from './forms/Button';
import CircleLoader from 'components/common/CircleLoader';
import CropModal from 'components/common/CropModal';
import FileUpload from 'components/common/forms/FileUpload';
import Icon from 'components/common/Icon';
import Popover from 'components/common/PopoverNew';

const MediaUploadWithCrop = ({
  disabled = false,
  media,
  onCrop,
  onPreviewLoaded,
  onUploadError,
  onUploaded,
  trigger,
}: {
  disabled?: boolean;
  media: MediaWithPreview;
  onCrop(preview: MediaPreview): void;
  onPreviewLoaded(preview: MediaPreview): void;
  onUploadError(): void;
  onUploaded(dataUrl: DataUrl): void;
  trigger: ReactNode;
}): JSX.Element => {
  const mediaData = media.data;
  const previewData = media.preview?.data ?? '';
  // If we already have uploaded media data, then there's no need to upload it again.
  const previewDataToUpload = mediaData ? '' : previewData;
  const mediaType = mediaData ? mediaData.resource_type : media.preview?.type;

  const [state, send] = useMachine(
    mediaUploadMachine.provide({
      actions: {
        logMediaUploadError: () => {
          onUploadError();
        },
        processUploadedMedia: (_, event) => {
          onUploaded(event.mediaData);
        },
      },
    }),
    { input: { mediaData: previewDataToUpload } },
  );
  const isUploading = state.matches('uploading');

  useEffect(() => {
    if (previewDataToUpload) {
      send({
        data: previewDataToUpload,
        mediaType,
        type: 'MEDIA_DATA_RECEIVED',
      });
    }
  }, [mediaType, previewDataToUpload, send]);

  const [mediaToCrop, setMediaToCrop] = useState<DataUrl | null>(null);

  return (
    <>
      <FileUpload
        disabled={disabled}
        onLoadFile={(media) => {
          onPreviewLoaded(media);
        }}
        trigger={trigger}
      />
      {mediaType && (
        <div>
          <Popover
            hoverDelay={350}
            openOnClick={false}
            openOnHover
            placement="top"
            trigger={
              <div>
                <MediaThumbnail
                  isUploading={isUploading}
                  mediaData={mediaData}
                  mediaType={mediaType}
                  previewData={previewData}
                />
              </div>
            }
          >
            <div className="p-2 space-y-4 flex flex-col items-center justify-center">
              <MediaFullPreview
                mediaData={mediaData}
                mediaType={mediaType}
                previewData={previewData}
              />

              {mediaType === 'image' && mediaData && (
                <Button
                  hierarchy="secondary-gray"
                  icon={<Icon id="crop-01" />}
                  iconPlacement="leading"
                  onClick={() => {
                    setMediaToCrop(mediaData);
                  }}
                  size="sm"
                  type="button"
                >
                  Crop
                </Button>
              )}
            </div>
          </Popover>
        </div>
      )}

      {mediaToCrop?.resource_type === 'image' && (
        <CropModal
          imageFormat={mediaToCrop.format}
          imageToCrop={mediaToCrop.secure_url}
          onCloseModal={() => {
            setMediaToCrop(null);
          }}
          onCrop={(croppedImage) => {
            let croppedImageFormatted = croppedImage;
            if (croppedImage.substring(11, 14)) {
              croppedImageFormatted = spliceSplit(croppedImage, 11, 3, 'jpg');
            }
            onCrop({ data: croppedImageFormatted, type: 'image' });

            setMediaToCrop(null);
          }}
        />
      )}
    </>
  );
};

export default MediaUploadWithCrop;

const MediaThumbnail = ({
  isUploading,
  mediaData,
  mediaType,
  previewData,
}: {
  isUploading: boolean;
  mediaData: DataUrl | null;
  mediaType: 'image' | 'video';
  previewData: string;
}) => {
  let thumbnail: ReactNode = null;

  // We only have a thumbnail for videos if we have uploaded data (not just preview data).
  // We do this because we force the thumbnail for videos to be a jpg since it's small and
  // we don't let the user play the video in the thumbnail. So we prefer a jpg to save bandwidth.
  if (mediaType === 'video' && mediaData) {
    thumbnail = (
      <img
        className="max-w-full max-h-64"
        src={getMediaUrl({ ...mediaData, format: 'jpg' })}
      />
    );
  } else if (mediaType === 'image') {
    const thumbnailUrl = mediaData ? getMediaUrl(mediaData) : previewData;

    thumbnail = <img className="max-w-full max-h-64" src={thumbnailUrl} />;
  }

  return (
    <div className="flex items-center justify-center w-8 h-8 relative">
      {thumbnail}
      {isUploading && (
        <div className="absolute">
          {/* This is colored for videos since we don't have a thumbnail so the background is white. */}
          <CircleLoader isColored={mediaType === 'video'} />
        </div>
      )}
    </div>
  );
};

const MediaFullPreview = ({
  mediaData,
  mediaType,
  previewData,
}: {
  mediaData: DataUrl | null;
  mediaType: 'image' | 'video';
  previewData: string;
}) => {
  const mediaUrl = mediaData ? getMediaUrl(mediaData) : previewData;

  return (
    <div className="flex items-center justify-center w-64 max-h-64">
      {mediaType === 'video' ? (
        <video className="max-w-full max-h-64" controls src={mediaUrl} />
      ) : (
        <img className="max-w-full max-h-64" src={mediaUrl} />
      )}
    </div>
  );
};

/** Borrowed from https://stackoverflow.com/questions/20817618/is-there-a-splice-method-for-strings */
function spliceSplit(str: string, index: number, count: number, add: string) {
  const ar = str.split('');
  ar.splice(index, count, add);
  return ar.join('');
}
