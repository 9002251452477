import { minutesToMilliseconds } from 'date-fns';
import { queryOptions } from '@tanstack/react-query';

import { listSavedCrosstabs } from 'services/backend/savedCrosstabs';

export const savedCrosstabsQueries = {
  all: () => ['saved-crosstabs'],
  forSurvey: (opts: { surveyId: number }) => ['saved-crosstabs', opts.surveyId],
  listForSurvey: (opts: { surveyId: number }) =>
    queryOptions({
      queryFn: () => listSavedCrosstabs({ surveyId: opts.surveyId }),
      queryKey: savedCrosstabsQueries.forSurvey(opts),
      staleTime: minutesToMilliseconds(2),
    }),
};
