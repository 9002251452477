import {
  Comment,
  Question,
  QuestionBlock,
  Survey,
  SurveyVariable,
} from '../../types/domainModels';
import SurveyPageSidebarLayout, {
  MenuName,
} from '../common/SurveyPageSidebarLayout';

const SurveySidebar = ({
  comments,
  demographicQuestions,
  // isLoadingQuestions,
  // isLoadingQuestionBlocks,
  // isLoadingSurvey,
  onBlockCloned,
  onClickNewQuestion,
  onClickNewVariable,
  onClickNewBlock,
  onMenuToggled,
  onQuestionsReordered,
  onTemplateQuestionsAdded,
  openMenus,
  question,
  questions,
  questionBlocks,
  survey,
  variable,
  variables,
}: {
  comments: Comment[];
  demographicQuestions: Question[];
  isLoadingQuestions: boolean;
  isLoadingQuestionBlocks: boolean;
  isLoadingSurvey: boolean;
  onBlockCloned(): void;
  onClickNewQuestion(): void;
  onClickNewVariable(): void;
  onClickNewBlock(): void;
  onMenuToggled(menuName: MenuName): void;
  onQuestionsReordered(questions: Question[]): void;
  onTemplateQuestionsAdded(): void;
  openMenus: Record<MenuName, boolean>;
  question: Question | undefined;
  questions: Question[];
  questionBlocks: QuestionBlock[];
  survey: Survey | undefined;
  variable: SurveyVariable | undefined;
  variables: SurveyVariable[];
}): JSX.Element => {
  return (
    <SurveyPageSidebarLayout
      comments={comments}
      curQuestion={question}
      curVariable={variable}
      demographicQuestions={demographicQuestions}
      onBlockCloned={onBlockCloned}
      onClickNewBlock={onClickNewBlock}
      onClickNewQuestion={onClickNewQuestion}
      onClickNewVariable={onClickNewVariable}
      onMenuToggled={onMenuToggled}
      onQuestionsReordered={onQuestionsReordered}
      onTemplateQuestionsAdded={onTemplateQuestionsAdded}
      openMenus={openMenus}
      questionBlocks={questionBlocks}
      questions={questions}
      survey={survey}
      variables={variables}
    />
  );
};

export default SurveySidebar;
