import { assign, fromPromise, setup } from 'xstate';

import { DataUrl } from 'types/domainModels';
import { uploadMedia } from 'services/cloudinary/media';

export const mediaUploadMachine = setup({
  types: {} as {
    context: { mediaData: string; mediaType: 'image' | 'video' | undefined };
    input: { mediaData: string };
    events: {
      data: string;
      mediaType: 'image' | 'video' | undefined;
      type: 'MEDIA_DATA_RECEIVED';
    };
  },

  actions: {
    logMediaUploadError() {
      throw new Error('Unimplemented');
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    processUploadedMedia(_, params: { mediaData: DataUrl }) {
      throw new Error('Unimplemented');
    },
    storeMediaInfo: assign(
      (
        _,
        params: { data: string; mediaType: 'image' | 'video' | undefined },
      ) => {
        return { mediaData: params.data, mediaType: params.mediaType };
      },
    ),
  },
  actors: {
    uploadMedia: fromPromise(
      async ({
        input,
      }: {
        input: { mediaData: string; uploadType: 'image' | 'video' | undefined };
      }) => {
        return uploadMedia({
          mediaData: input.mediaData,
          uploadType: input.uploadType,
        });
      },
    ),
  },
  guards: {
    hasMediaData: ({ context }) => !!context.mediaData,
    isMediaDataDifferent: ({ context, event }) =>
      context.mediaData !== event.data,
  },
}).createMachine({
  id: 'mediaUpload',
  context: ({ input }) => ({
    mediaData: input.mediaData,
    mediaType: undefined,
  }),
  initial: 'checkForMediaData',
  states: {
    checkForMediaData: {
      always: [
        { guard: 'hasMediaData', target: 'uploading' },
        { target: 'idle' },
      ],
    },
    idle: {
      on: {
        MEDIA_DATA_RECEIVED: {
          actions: [
            {
              params: ({ event }) => {
                return { data: event.data, mediaType: event.mediaType };
              },
              type: 'storeMediaInfo',
            },
          ],
          guard: 'isMediaDataDifferent',
          target: 'uploading',
        },
      },
    },
    uploading: {
      invoke: {
        src: 'uploadMedia',
        input: ({ context }) => ({
          mediaData: context.mediaData,
          uploadType: context.mediaType,
        }),
        onDone: {
          actions: [
            {
              type: 'processUploadedMedia',
              params: ({ event }) => ({ mediaData: event.output }),
            },
          ],
          target: 'idle',
        },
        onError: {
          actions: [{ type: 'logMediaUploadError' }],
          target: 'idle',
        },
      },
    },
  },
});
