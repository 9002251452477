import { ReactNode, useState } from 'react';

import { MediaPreview } from 'util/media';

import CropModal from '../CropModal';
import FileUpload from './FileUpload';

const FileUploadWithCrop = ({
  children,
  disabled,
  onDone,
}: {
  children: ReactNode;
  disabled?: boolean;
  onDone(image: string): void;
}): JSX.Element => {
  const [imageToCrop, setImageToCrop] = useState<MediaPreview | null>(null);

  /** Borrowed from https://stackoverflow.com/questions/20817618/is-there-a-splice-method-for-strings */
  function spliceSplit(str: string, index: number, count: number, add: string) {
    const ar = str.split('');
    ar.splice(index, count, add);
    return ar.join('');
  }

  return (
    <>
      <FileUpload
        disabled={disabled}
        onLoadFile={(file) => {
          if (file.type === 'video') {
            onDone(file.data);
          } else {
            setImageToCrop(file);
          }
        }}
        trigger={children}
      />

      {imageToCrop && (
        <CropModal
          imageFormat={imageToCrop.type}
          imageToCrop={imageToCrop.data}
          onCloseModal={() => {
            setImageToCrop(null);
          }}
          onCrop={(croppedImage) => {
            let croppedImageFormatted = croppedImage;
            if (croppedImage.substring(11, 14)) {
              croppedImageFormatted = spliceSplit(croppedImage, 11, 3, 'jpg');
            }
            onDone(croppedImageFormatted);

            setImageToCrop(null);
          }}
        />
      )}
    </>
  );
};

export default FileUploadWithCrop;
