import { ComponentProps, ReactNode } from 'react';
import { GroupBase, OnChangeValue } from 'react-select';
import { useField } from 'formik';

import FormGroup from './FormGroup';
import Select from './Select';

function FormSearchSelectInput<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  label,
  labelFor,
  options = [],
  tooltip,
  ...rest
}: {
  label?: ReactNode;
  labelFor?: string;
  name: string;
  tooltip?: string;
} & ComponentProps<typeof Select<Option, IsMulti, Group>>): JSX.Element {
  const [field, meta, helpers] = useField<OnChangeValue<Option, IsMulti>>(
    rest.name,
  );
  const hasError = !!(meta.touched && meta.error);

  return (
    <FormGroup
      error={hasError ? meta.error : ''}
      label={label}
      labelFor={labelFor}
      tooltip={tooltip}
    >
      <Select
        {...field}
        {...rest}
        hasError={hasError}
        onBlur={(event) => {
          helpers.setTouched(true);
          rest.onBlur?.(event);
        }}
        onChange={(value, actionMeta) => {
          // @ts-expect-error -- I want to migrate us to Combobox instead of continuing to use this but for now, I want this component to support just the value of an option being set, not the entire option.
          helpers.setValue(value.value);
          rest.onChange?.(value, actionMeta);
        }}
        options={options}
        // @ts-expect-error -- I want to migrate us to Combobox instead of continuing to use this but for now, I want this component to support just the value of an option being set, not the entire option.
        value={options.find((option) => option.value === field.value)}
      />
    </FormGroup>
  );
}

export default FormSearchSelectInput;
