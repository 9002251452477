import { useState } from 'react';
import {
  FieldArray,
  Formik,
  FormikTouched,
  useField,
  useFormikContext,
} from 'formik';

import { errorsToTouched } from '../../util/forms';
import {
  getEmptyQuota,
  hasConfiguredQuotas,
  validateQuotas,
} from '../../util/questions';
import { QUESTION_QUOTA_LOGICAL_MODIFIER_OPTIONS } from '../../util/formOptions';
import { QuestionFormData, QuestionFormOption } from '../../types/forms';
import { useHasRole } from 'hooks/users';

import AddButton from '../common/forms/AddButton';
import Button from '../common/forms/Button';
import FormCheckbox from 'components/common/forms/FormCheckbox';
import FormInput from '../common/forms/FormInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import Modal, { ModalHeader } from '../common/Modal';
import WordSeparator from 'components/common/WordSeparator';
import XButton from '../common/forms/XButton';

type QuotaFeature = QuestionFormData['features']['quotas'];
type QuotaValues = QuotaFeature['values'];

interface QuotasFormData {
  modalQuotas: QuotaValues;
}

const QuotasEditModal = ({
  onCloseModal,
}: {
  onCloseModal(): void;
}): JSX.Element => {
  const valuesFieldName = 'features.quotas.values';
  const [{ value: options }] = useField<QuestionFormOption[]>('options');
  const [{ value: quotas }, quotasMeta, quotasHelpers] =
    useField<QuotaValues>(valuesFieldName);
  const [, , quotasEnabledHelpers] = useField<QuotaFeature['enabled']>(
    'features.quotas.enabled',
  );

  const [validateOnBlur, setValidateOnBlur] = useState(!!quotasMeta.error);

  function closeWithoutApply() {
    if (!hasConfiguredQuotas(quotas)) {
      quotasEnabledHelpers.setValue(false);
    }

    onCloseModal();
  }

  return (
    <Modal
      header={
        <ModalHeader onClickClose={closeWithoutApply}>Quotas</ModalHeader>
      }
      onCloseModal={closeWithoutApply}
      position="top"
      size="auto"
    >
      <Formik<QuotasFormData>
        initialErrors={{
          modalQuotas: quotasMeta.error,
        }}
        initialTouched={
          quotasMeta.error
            ? // The cast to FormikTouched<QuotasFormData> is not ideal but Formik TypeScript
              // support is not great in the current version so this is a workaround until they have
              // better support.
              ({
                modalQuotas: errorsToTouched(quotasMeta.error),
              } as FormikTouched<QuotasFormData>)
            : undefined
        }
        initialValues={{
          modalQuotas: quotas,
        }}
        onSubmit={(formData) => {
          const newQuotas = formData.modalQuotas;

          if (hasConfiguredQuotas(newQuotas)) {
            quotasHelpers.setValue(newQuotas);
          } else {
            quotasHelpers.setValue([]);
            quotasEnabledHelpers.setValue(false);
          }

          onCloseModal();
        }}
        validate={(formData) => {
          const errors = validateQuotas(formData.modalQuotas);
          if (errors) {
            return { modalQuotas: errors };
          }

          return {};
        }}
        // Validation on blur is annoying if you haven't yet attempted to submit the form
        // because it may alert you to something you were already going to address.
        validateOnBlur={validateOnBlur}
        validateOnChange={false}
      >
        <QuotasForm
          onClickClose={closeWithoutApply}
          onSubmit={() => {
            setValidateOnBlur(true);
          }}
          options={options}
        />
      </Formik>
    </Modal>
  );
};

export default QuotasEditModal;

const QuotasForm = ({
  onClickClose,
  onSubmit,
  options,
}: {
  onClickClose(): void;
  onSubmit(): void;
  options: QuestionFormOption[];
}): JSX.Element => {
  const { submitForm } = useFormikContext();
  const [{ value: modalQuotas }] =
    useField<QuotasFormData['modalQuotas']>('modalQuotas');

  const currentlyUsedQuotaOptionIdxs = modalQuotas.flatMap((quota) =>
    quota.options ? quota.options.map((o) => o.value) : [],
  );

  return (
    <div>
      <div className="w-edit-quotas-modal">
        <FieldArray
          name="modalQuotas"
          render={(arrayHelpers) => {
            return (
              <>
                <div>
                  {modalQuotas.length === 0 && (
                    <p className="mb-4 text-sm">
                      No quotas are currently configured.
                    </p>
                  )}
                  {modalQuotas.map((_quota, index) => {
                    return (
                      <div key={index}>
                        <Quota
                          index={index}
                          indexPrefix="modalQuotas"
                          onClickRemove={() => {
                            arrayHelpers.remove(index);
                          }}
                          options={options}
                          usedOptionIndices={currentlyUsedQuotaOptionIdxs}
                        />
                        {index !== modalQuotas.length - 1 && (
                          <WordSeparator word="and" />
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="flex mt-2">
                  <AddButton
                    label="Add Quota"
                    onClick={() => {
                      arrayHelpers.push(getEmptyQuota());
                    }}
                  />
                </div>
              </>
            );
          }}
        />
      </div>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <Button
          hierarchy="primary"
          onClick={() => {
            submitForm();

            onSubmit();
          }}
          size="md"
          type="button"
        >
          Apply
        </Button>
        <Button
          hierarchy="secondary-gray"
          onClick={onClickClose}
          size="md"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export const Quota = ({
  index,
  indexPrefix,
  onClickRemove,
  options,
  usedOptionIndices,
}: {
  index: number;
  indexPrefix?: string;
  onClickRemove?: () => void;
  options: QuestionFormData['options'];
  usedOptionIndices?: number[];
}): JSX.Element => {
  const isAdmin = useHasRole('admin');

  const quotaNamePrefix = `${indexPrefix ? indexPrefix + '.' : ''}${index}`;
  const [{ value: logicalModifier }] = useField<
    QuotaValues[0]['logicalModifier']
  >(`${quotaNamePrefix}.logicalModifier`);

  return (
    <div className="space-y-2">
      <div className="flex p-2 space-x-4">
        <div className="w-28">
          <FormSearchSelectInput
            name={`${quotaNamePrefix}.logicalModifier`}
            options={QUESTION_QUOTA_LOGICAL_MODIFIER_OPTIONS}
          />
        </div>
        {(logicalModifier?.value === 'at_most' ||
          logicalModifier?.value === 'at_least') && (
          <div className="w-24">
            <FormInput
              name={`${quotaNamePrefix}.optionQuota`}
              size="md"
              type="number"
            />
          </div>
        )}
        <span className="w-22 text-sm">
          responses for
          <br />
          either
        </span>
        <div className="flex-grow w-2/6">
          <FormSearchSelectInput
            isMulti={true}
            name={`${quotaNamePrefix}.options`}
            options={options
              .filter(({ image, title }) => !!image || !!title)
              .map((option, i) => {
                return {
                  label: option.title || `${i + 1}) (unlabeled)`,
                  value: i,
                };
              })
              .filter(
                ({ value }) =>
                  !usedOptionIndices || !usedOptionIndices?.includes(value),
              )}
          />
        </div>
        <div className="mt-2">
          {onClickRemove && <XButton onClick={onClickRemove} title="Remove" />}
        </div>
      </div>
      {isAdmin &&
        (logicalModifier?.value === 'all' ||
          logicalModifier?.value === 'none') && (
          <FormCheckbox
            checkboxLabel="Quality Disqualification"
            name={`${quotaNamePrefix}.isQualityDisqualification`}
            tooltip="Classifies any respondent who does not meet the quota criteria as a Quality disqualification instead of a Question Quota disqualification. These respondents will not be factored into survey incidence."
          />
        )}
    </div>
  );
};
