import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { QuestionBlock } from '../../types/domainModels';

export interface CreateQuestionBlocksBody {
  amountViewed: number | null;
  isRandomized: boolean;
  questionBlocks: {
    displayLogic: {
      constraints: (
        | {
            conceptId: number;
          }
        | {
            optionId: number;
          }
        | {
            matrixOptionIds: number[];
            optionId: number;
          }
        | {
            numberRange: { start: number; end: number };
            optionId: number;
          }
        | {
            numberRange: { start: number; end: number };
          }
      )[];
      logicalModifier: 'is' | 'isnt' | 'should';
      questionId: number;
    }[];
    countInX: boolean;
    end: number;
    start: number;
    title: string;
  }[];
  title: string;
}

export const CLONE_QUESTION_BLOCK = ({
  blockId,
  surveyId,
}: {
  blockId: number;
  surveyId: number | string;
}) => ({
  path: `/surveys/${surveyId}/clone-block/${blockId}`,
  version: 'v1' as const,
});

export async function cloneQuestionBlock({
  blockId,
  data,
  surveyId,
}: {
  blockId: number;
  data: { title: string };
  surveyId: number | string;
}) {
  return (
    await axios.post<void>(
      getAPIUrl(CLONE_QUESTION_BLOCK({ blockId, surveyId })),
      data,
    )
  ).data;
}

export const CREATE_QUESTION_BLOCK = ({
  surveyId,
}: {
  surveyId: number | string;
}) => ({
  path: `/surveys/${surveyId}/question-blocks`,
  version: 'v1' as const,
});

export async function createQuestionBlock({
  data,
  surveyId,
}: {
  data: CreateQuestionBlocksBody;
  surveyId: number | string;
}) {
  return (
    await axios.post<void>(getAPIUrl(CREATE_QUESTION_BLOCK({ surveyId })), data)
  ).data;
}

export const DELETE_QUESTION_BLOCK = ({
  questionBlockId,
}: {
  questionBlockId: number;
}) => ({
  path: `/surveys/question-blocks/${questionBlockId}`,
  version: 'v1' as const,
});

export async function deleteQuestionBlock({
  questionBlockId,
}: {
  questionBlockId: number;
}) {
  return (
    await axios.delete<void>(
      getAPIUrl(DELETE_QUESTION_BLOCK({ questionBlockId })),
    )
  ).data;
}

export const FETCH_QUESTION_BLOCKS = ({
  surveyId,
}: {
  surveyId: number | string;
}) => ({
  path: `/surveys/${surveyId}/question-blocks`,
  version: 'v1' as const,
});

export async function fetchQuestionBlocks({
  surveyId,
}: {
  surveyId: number | string;
}) {
  return (
    await axios.get<QuestionBlock[]>(
      getAPIUrl(FETCH_QUESTION_BLOCKS({ surveyId })),
    )
  ).data;
}

export const UPDATE_QUESTION_BLOCK = ({
  blockId,
  surveyId,
}: {
  blockId: number;
  surveyId: number | string;
}) => ({
  path: `/surveys/${surveyId}/question-blocks/${blockId}`,
  version: 'v1' as const,
});

export async function updateQuestionBlock({
  blockId,
  data,
  surveyId,
}: {
  blockId: number;
  data: CreateQuestionBlocksBody;
  surveyId: number | string;
}) {
  return (
    await axios.patch<void>(
      getAPIUrl(UPDATE_QUESTION_BLOCK({ blockId, surveyId })),
      data,
    )
  ).data;
}
