import { orderBy } from 'lodash-es';
import { IncidenceType } from '../types/domainModels';
import { ReactSelectValue } from '../types/forms';

const INCIDENCE_RANGES: IncidenceType['name'][] = [
  '100-75%',
  '74-50%',
  '49-30%',
  '29-20%',
  '19-10%',
  '9-5%',
  '4-3%',
  '2-1%',
];

export function findIncidenceType(incidence: number) {
  return (
    INCIDENCE_RANGES.find((incidenceRange) => {
      const [start, end] = getNumsFromEstimatedRange(incidenceRange);

      return incidence >= start && incidence <= end;
    }) ?? null
  );
}

export function getIncidenceTypeOptions(
  incidenceTypes: IncidenceType[],
): ReactSelectValue<IncidenceType>[] {
  return orderBy(
    incidenceTypes,
    ({ name }) => {
      // An incidence type name looks like "100-75%" so the resulting split here will be
      // an array of ['100', '75%']. This ordering should eventually be moved to the back-end
      // because this is brittle.
      const [, end] = getNumsFromEstimatedRange(name);

      return end;
    },
    ['desc'],
  ).map((incidenceType) => {
    return {
      label: incidenceType.name,
      value: incidenceType,
    };
  });
}

/**
 * For non-admins, we don't show the exact actual incidence because sometimes we're quoting
 * clients at a certain range and don't want to provide refunds if the actual incidence is higher
 * than what was estimated. This is somewhat lying, but it's also since we don't know what the
 * exact incidence will be and other reasons I'm not aware of.
 *
 * @see https://linear.app/glass-tech/issue/GLA-1608/correct-incidence-calculation-for-survey-waves
 */
export function getNonAdminIncidenceDisplay({
  actualIncidence,
  estimatedIncidence,
}: {
  actualIncidence: number;
  estimatedIncidence: string;
}) {
  // Incidence is 100-75% so the first number will be the end of the range.
  const [end, start] = getNumsFromEstimatedRange(estimatedIncidence);

  if (actualIncidence >= start && actualIncidence <= end) {
    return estimatedIncidence;
  } else if (actualIncidence > end) {
    return estimatedIncidence;
  }

  // If the incidence is 0%, we won't find a range because our lowest range doesn't include
  // 0%. So we just show the lowest range, which is 2-1%.
  return findIncidenceType(actualIncidence) ?? '2-1%';
}

/**
 * Accepts an estimated incidence range of the form "100-75%" and
 * returns the two numbers as an array.
 */
export function getNumsFromEstimatedRange(estimated: string) {
  const [end, start] = estimated.split('-');

  return [Number(start.split('%')[0]), Number(end)];
}
