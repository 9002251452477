import { DataUrl } from 'types/domainModels';
import { getEnvVar } from './env';

const CLOUD_NAME = getEnvVar('CLOUDINARY_CLOUD_NAME');

export type MediaPreview = { data: string; type: 'image' | 'video' };

export type MediaWithPreview = {
  data: DataUrl | null;
  preview: MediaPreview | null;
};

export function getMediaUrl({
  format,
  public_id,
  resource_type,
  version,
}: {
  format: string;
  public_id: string;
  resource_type: 'image' | 'video';
  version: number | string;
}): string {
  return `https://res.cloudinary.com/${
    CLOUD_NAME
  }/${resource_type}/upload/f_auto,q_auto/v${version}/${public_id}.${format}`;
}

export function isDataUrl(
  image: DataUrl | Record<string, unknown> | null | undefined,
): image is DataUrl {
  return (
    image?.version !== undefined &&
    image?.public_id !== undefined &&
    image?.format !== undefined
  );
}
