import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { User, UserV2 } from '../../types/domainModels';

export const FETCH_USER = ({ userId }: { userId: number }) => ({
  path: `/users/${userId}`,
  version: 'v2' as const,
});

export async function fetchUser({ userId }: { userId: number }) {
  return (await axios.get<UserV2>(getAPIUrl(FETCH_USER({ userId })))).data;
}

export const LOGIN = () => ({
  path: '/users/login',
  version: 'v1' as const,
});

export function login({ data }: { data: { email: string; password: string } }) {
  return axios.post<User>(getAPIUrl(LOGIN()), data);
}

export const RESET_PASSWORD = () => ({
  path: '/reset-password',
  version: 'v1' as const,
});

export function resetPassword({ email }: { email: string }) {
  return axios.get<{ message: string }>(getAPIUrl(RESET_PASSWORD()), {
    params: { resetPassEmail: email },
  });
}

export const SET_PASSWORD = () => ({
  path: '/set-password',
  version: 'v1' as const,
});

export function setPassword({
  activationCode,
  data,
}: {
  activationCode: string;
  data: { password: string; retypepassword: string };
}) {
  return axios.post<void>(getAPIUrl(SET_PASSWORD()), data, {
    params: { activationCode },
  });
}

export const UPDATE_USER = ({ userID }: { userID: number }) => ({
  path: `/users/${userID}`,
  version: 'v1' as const,
});

export async function updateUser({
  data,
  userID,
}: {
  data: Partial<{
    email: string;
    firstName: string;
    image: User['image'] | { url: string };
    lastName: string;
    mobile: string;
    password: string;
    retypepassword: string;
  }>;
  userID: number;
}) {
  return (await axios.patch<User>(getAPIUrl(UPDATE_USER({ userID })), data))
    .data;
}
