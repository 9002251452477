import { ReactNode, useRef } from 'react';

import { MediaPreview } from 'util/media';

const FileUpload = ({
  disabled = false,
  onLoadFile,
  trigger,
}: {
  disabled?: boolean;
  onLoadFile(opts: MediaPreview): void;
  trigger: ReactNode;
}) => {
  const fileUploadInput = useRef<HTMLInputElement | null>(null);

  return (
    <div
      onClick={() => {
        if (!disabled && fileUploadInput.current) {
          fileUploadInput.current.click();
        }
      }}
    >
      {trigger}
      <input
        ref={fileUploadInput}
        className="hidden"
        onChange={async (event) => {
          const files = event.target.files;
          if (!files) {
            return;
          }

          const fileData = await parseFiles(files);
          onLoadFile(fileData[0]);

          // Once we've read the file, we clear the value so the user could choose
          // to re-upload the same file.
          if (fileUploadInput.current) {
            fileUploadInput.current.value = '';
          }
        }}
        type="file"
      />
    </div>
  );
};

export default FileUpload;

export const FileUploadBulk = ({
  disabled = false,
  onLoadFiles,
  trigger,
}: {
  disabled?: boolean;
  onLoadFiles(opts: MediaPreview[]): void;
  trigger: ReactNode;
}): JSX.Element => {
  const fileUploadInput = useRef<HTMLInputElement | null>(null);

  return (
    <div
      onClick={() => {
        if (!disabled && fileUploadInput.current) {
          fileUploadInput.current.click();
        }
      }}
    >
      {trigger}
      <input
        ref={fileUploadInput}
        className="hidden"
        multiple
        onChange={async (event) => {
          const files = event.target.files;
          if (!files) {
            return;
          }

          const fileData = await parseFiles(files);
          onLoadFiles(fileData);

          // Once we've read the files, we clear the value so the user could choose
          // to re-upload the same files.
          if (fileUploadInput.current) {
            fileUploadInput.current.value = '';
          }
        }}
        type="file"
      />
    </div>
  );
};

function parseFiles(files: FileList) {
  const fileLoadPromises: Promise<MediaPreview>[] = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    fileLoadPromises.push(
      new Promise<MediaPreview>((resolve) => {
        const reader = new FileReader();

        reader.onload = () => {
          // reader.result is a "string" because we used the readAsDataURL method
          // to read the file's contents.
          resolve({
            data: (reader.result as string | null) ?? '',
            type: file.type.substring(0, file.type.indexOf('/')) as
              | 'image'
              | 'video',
          });
        };

        reader.readAsDataURL(file);
      }),
    );
  }

  return Promise.all(fileLoadPromises);
}
