import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { UploadSignature } from 'types/domainModels';

export const CREATE_UPLOAD_SIGNATURE = () => ({
  path: '/media/upload-signature',
  version: 'v2' as const,
});

export async function createUploadSignature() {
  return (
    await axios.post<UploadSignature>(getAPIUrl(CREATE_UPLOAD_SIGNATURE()), {
      timestamp: Date.now(),
    })
  ).data;
}
