import { useField } from 'formik';

import { clampNumberField } from '../../../util/forms';
import { QuestionFormData, QuestionFormOption } from '../../../types/forms';

import FormCheckbox from '../../common/forms/FormCheckbox';
import FormInput from '../../common/forms/FormInput';

const DisplayXOfY = ({
  optionsFieldName = 'options',
}: {
  optionsFieldName?: 'options' | 'statements';
}): JSX.Element => {
  const [{ value: options }] = useField<QuestionFormOption[]>(optionsFieldName);
  const [{ value: displayXOfY }] = useField<boolean>(
    'features.displayXOfY.enabled',
  );
  const [, , displayXOfYValueHelpers] = useField<
    QuestionFormData['features']['displayXOfY']['value']
  >('features.displayXOfY.value');

  let tooltipResource = 'options';
  if (optionsFieldName === 'statements') {
    tooltipResource = 'statements (rows of the matrix)';
  }

  return (
    <div className="flex items-center">
      <FormCheckbox
        checkboxLabel="Display X of Y"
        name="features.displayXOfY.enabled"
        tooltip={`This feature will randomly choose a specified number of the question ${tooltipResource} to display to the respondent.`}
      />
      {displayXOfY && (
        <div className="w-20 ml-2">
          <FormInput
            name="features.displayXOfY.value"
            onChange={(event) => {
              clampNumberField({
                formHelpers: displayXOfYValueHelpers,
                lowerBound: 1,
                upperBound: options.length,
                value: event.target.value,
              });
            }}
            size="md"
            type="number"
          />
        </div>
      )}
    </div>
  );
};

export default DisplayXOfY;
