import { clsx } from 'clsx';
import { useState } from 'react';

import { APIError } from '../../types/internal';
import { Question, Survey, SurveyVariable } from '../../types/domainModels';
import { showErrorMessage } from '../../util/notifications';
import { useModal } from '../../hooks/modals';
import { useToggleQuestionActivation } from 'hooks/backend/questions';

import Icon from 'components/common/Icon';
import IndexCard from '../common/IndexCard';
import SliderToggle from '../common/SliderToggle';
import Tooltip from '../common/Tooltip';
import VariableEditModal from '../surveyEdit/VariableEditModal';

const MenuHeaderRow = ({
  isMenuOpen,
  label,
  setMenuOpen,
}: {
  isMenuOpen: boolean;
  label: string;
  setMenuOpen(val: boolean): void;
}) => {
  return (
    <div className="text-xs leading-6 font-semibold px-4 py-1 text-gray-600 flex flex-row place-content-between items-center">
      {label}
      <div
        className="cursor-pointer flex items-center justify-center w-4 h-4"
        onClick={() => {
          setMenuOpen(!isMenuOpen);
        }}
      >
        {!isMenuOpen && <Icon id="chevron-down" />}
        {isMenuOpen && <Icon id="chevron-up" />}
      </div>
    </div>
  );
};

const SurveyLaunchedSidebar = ({
  curQuestionId,
  curVariableId,
  isLoadingSurvey,
  onClickQuestion,
  onClickSurveySummary,
  onClickVariable,
  onQuestionActivationToggled,
  questions,
  survey,
  variables,
}: {
  curQuestionId: number | null;
  curVariableId: number | null;
  isLoadingSurvey: boolean;
  isLoadingVariables: boolean;
  loadVariablesError: APIError | null;
  onClickQuestion(questionId: number): void;
  onClickSurveySummary(): void;
  onClickVariable(variableId: number): void;
  onQuestionActivationToggled(questionId: number): void;
  questions: Question[];
  survey: Survey;
  variables: SurveyVariable[];
}) => {
  const [isDemosMenuOpen, setDemosMenuOpen] = useState(true);
  const [isQuestionsMenuOpen, setQuestionsMenuOpen] = useState(true);
  const [isVariablesMenuOpen, setVariablesMenuOpen] = useState(true);

  const isViewingSummary = !curQuestionId && !curVariableId;

  const { mutate: toggleQuestionActivation } = useToggleQuestionActivation({
    onError: (err) => {
      showErrorMessage(`Failed to update question. Error: ${err.message}`);
    },
    onSuccess: (question) => {
      onQuestionActivationToggled(question.id);
    },
  });

  function onToggleQuestion(question: { id: number }) {
    toggleQuestionActivation({ questionId: question.id });
  }

  const {
    isOpen: isVariableModalOpen,
    onCloseModal: onVariableModalOpen,
    setIsOpen: setVariableModalOpen,
  } = useModal();

  let demosMenuItems = [
    <MenuHeaderRow
      key={0}
      isMenuOpen={isDemosMenuOpen}
      label="Demographic Questions"
      setMenuOpen={setDemosMenuOpen}
    />,
  ];
  let questionsMenuItems = [
    <MenuHeaderRow
      key={0}
      isMenuOpen={isQuestionsMenuOpen}
      label="Survey Questions"
      setMenuOpen={setQuestionsMenuOpen}
    />,
  ];
  let variablesMenuItems = [
    <MenuHeaderRow
      key={0}
      isMenuOpen={isVariablesMenuOpen}
      label="Hidden Variables"
      setMenuOpen={setVariablesMenuOpen}
    />,
  ];

  if (isDemosMenuOpen) {
    demosMenuItems = [
      ...demosMenuItems,
      // <div style={{ maxHeight: '212px' }} className="overflow-y-auto">
      <div key={1}>
        {questions
          .filter((q) => q.isDemographic)
          .map((question) => {
            const formattedTitle =
              question.title.length > 100
                ? `${question.title.substring(0, 100)}...`
                : question.title;
            return (
              <div
                key={question.id}
                className={clsx('hover:bg-gray-300 cursor-pointer px-4 py-1', {
                  'bg-mint': curQuestionId === question.id,
                })}
                onClick={(event) => {
                  // The default might have been prevented if the user clicked on the toggle
                  // to change the question's active status.
                  if (event.defaultPrevented) {
                    return;
                  }

                  onClickQuestion(question.id);
                }}
              >
                <div
                  className={clsx(
                    'text-button text-gray-900 flex flex-row items-center',
                    {
                      'text-forest': curQuestionId === question.id,
                    },
                  )}
                >
                  <div className="mr-1">
                    <Tooltip
                      trigger={
                        <div>
                          <SliderToggle
                            onChange={() => {
                              onToggleQuestion(question);
                            }}
                            value={question.isActive}
                          />
                        </div>
                      }
                    >
                      {question.isActive ? 'Active' : 'Inactive'}
                    </Tooltip>
                  </div>
                  Question {question.sort}
                </div>
                <div
                  className={clsx('text-button overflow-hidden text-gray-600', {
                    'text-green': curQuestionId === question.id,
                  })}
                >
                  {formattedTitle}
                </div>
              </div>
            );
          })}
      </div>,
    ];
  }

  if (isQuestionsMenuOpen) {
    questionsMenuItems = [
      ...questionsMenuItems,
      <div key={1}>
        {questions
          .filter((q) => !q.isDemographic)
          .map((question) => {
            const formattedTitle =
              question.title.length > 100
                ? `${question.title.substring(0, 100)}...`
                : question.title;
            return (
              <div
                key={question.id}
                className={clsx('hover:bg-gray-300 cursor-pointer px-4 py-1', {
                  'bg-mint': curQuestionId === question.id,
                })}
                onClick={(event) => {
                  // The default might have been prevented if the user clicked on the toggle
                  // to change the question's active status.
                  if (event.defaultPrevented) {
                    return;
                  }

                  onClickQuestion(question.id);
                }}
              >
                <div className="text-button text-gray-900">
                  <div
                    className={clsx(
                      'text-button text-gray-900 flex flex-row items-center space-between',
                      {
                        'text-forest': curQuestionId === question.id,
                      },
                    )}
                  >
                    <div className="mr-1">
                      <Tooltip
                        trigger={
                          <div>
                            <SliderToggle
                              onChange={() => {
                                onToggleQuestion(question);
                              }}
                              value={question.isActive}
                            />
                          </div>
                        }
                      >
                        {question.isActive ? 'Active' : 'Inactive'}
                      </Tooltip>
                    </div>
                    Question {question.sort}
                  </div>
                </div>
                <div
                  className={clsx(
                    'text-button break-word overflow-hidden text-gray-600',
                    { 'text-green': curQuestionId === question.id },
                  )}
                >
                  {formattedTitle}
                </div>
              </div>
            );
          })}
      </div>,
    ];
  }

  if (isVariablesMenuOpen) {
    variablesMenuItems = [
      ...variablesMenuItems,
      <div key={1}>
        {variables.map((variable) => {
          const formattedTitle =
            variable.title.length > 100
              ? `${variable.title.substring(0, 100)}...`
              : variable.title;
          return (
            <div
              key={variable.id}
              className={clsx('hover:bg-gray-300 cursor-pointer px-4 py-1', {
                'bg-mint': curVariableId === variable.id,
              })}
              onClick={(event) => {
                // The default might have been prevented if the user clicked on the toggle
                // to change the question's active status.
                if (event.defaultPrevented) {
                  return;
                }

                onClickVariable(variable.id);
              }}
            >
              <div
                className={clsx(
                  'text-button break-word overflow-hidden text-gray-600',
                  { 'text-green': curVariableId === variable.id },
                )}
              >
                {formattedTitle}
              </div>
            </div>
          );
        })}
      </div>,
      <div
        key="new-variable"
        className="px-4 py-1 text-xs text-gray-600 flex flex-row items-center justify-between"
      >
        <div
          className="hover:bg-gray-300 rounded px-1 cursor-pointer"
          onClick={() => setVariableModalOpen(true)}
        >
          <span>New Variable</span>
        </div>
      </div>,
    ];
  }

  return (
    <>
      {isLoadingSurvey ? (
        <>
          <SkeletonSurveySidebarCard />
          <SkeletonSurveySidebarCard />
        </>
      ) : (
        <div className="space-y-6">
          <IndexCard>
            <div
              className={clsx('p-4 cursor-pointer', {
                'bg-mint': isViewingSummary,
              })}
              onClick={() => {
                onClickSurveySummary();
              }}
            >
              Survey Summary
            </div>
          </IndexCard>
          <IndexCard>{demosMenuItems}</IndexCard>
          <IndexCard>{questionsMenuItems}</IndexCard>
          <IndexCard>{variablesMenuItems}</IndexCard>

          {isVariableModalOpen && (
            <VariableEditModal
              onCloseModal={onVariableModalOpen}
              survey={survey}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SurveyLaunchedSidebar;

const SkeletonSurveySidebarCard = () => {
  return (
    <div className="h-26 p-2 rounded overflow-hidden bg-dark-grey">
      <div className="space-y-2 animate-pulse">
        <div className="w-3/4 h-4 bg-light-grey" />
        <div className="w-full h-2 bg-light-grey" />
      </div>
    </div>
  );
};
