import { Form, Formik, useField, useFormikContext } from 'formik';
import { useQuery } from '@tanstack/react-query';

import {
  getInitialSurveyVariableFormData,
  SurveyVariableFormData,
} from '../../util/surveyVariables';
import {
  Question,
  SurveyVariable,
  SurveyVariableQuota,
} from '../../types/domainModels';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { useCreateVariableQuotas } from 'hooks/backend/surveyVariableQuotas';
import { variableQueries } from 'hooks/backend/surveyVariables';

import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import Modal, { ModalHeader } from '../common/Modal';
import { VariableQuotasForm } from './VariableQuotasModal';

interface QuotasFormData {
  modalQuotas: SurveyVariableFormData['quotas'];
}

const ResultsVariableQuotasModal = ({
  onCloseModal,
  questions,
  variable,
}: {
  onCloseModal(): void;
  questions: Question[];
  variable: SurveyVariable;
}) => {
  const { data: surveyVariables = [] } = useQuery({
    ...variableQueries.list({ surveyId: variable.surveyId }),
    refetchOnWindowFocus: false,
  });

  const currentVariable = surveyVariables.find((v) => v.id === variable.id);

  const { isPending: isCreatingQuotas, mutate: createVariableQuotas } =
    useCreateVariableQuotas({
      onError: () => {
        showErrorMessage(`Failed to update quotas for "${variable.title}"`);
      },
      onSuccess: () => {
        showSuccessMessage(
          `Successfully updated quotas for "${variable.title}"`,
        );
        onCloseModal();
      },
    });

  const initialValues = getInitialSurveyVariableFormData({
    questions,
    variable: currentVariable,
  });

  return (
    <Modal
      header={<ModalHeader onClickClose={onCloseModal}>Quotas</ModalHeader>}
      onCloseModal={onCloseModal}
      position="top"
      size="auto"
    >
      <Formik<QuotasFormData>
        enableReinitialize
        initialValues={{ modalQuotas: initialValues?.quotas }}
        onSubmit={() => onCloseModal()}
        validateOnChange={false}
      >
        <Form>
          <VariableQuotasForm
            generateSubmitButton={false}
            onClickClose={onCloseModal}
            segments={initialValues.segments}
          />
          <GenerateButton
            isCreatingQuotas={isCreatingQuotas}
            onClickClose={onCloseModal}
            onClickSave={(quotas) => {
              if (!currentVariable) {
                throw new Error(
                  'Could not find the variable to add a quota to. Please refresh the page and try again.',
                );
              }

              return createVariableQuotas({
                data: { quotas },
                variableId: currentVariable.id,
              });
            }}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

const GenerateButton = ({
  isCreatingQuotas,
  onClickClose,
  onClickSave,
}: {
  isCreatingQuotas: boolean;
  onClickClose(): void;
  onClickSave(quotas: SurveyVariableQuota[]): void;
}): JSX.Element => {
  const { dirty } = useFormikContext();

  const [{ value: quotas }, ,] = useField<SurveyVariableQuota[]>('modalQuotas');

  return (
    <div className="mt-8 flex gap-3 flex-row-reverse">
      <ButtonLoading
        disabled={!dirty || isCreatingQuotas}
        hierarchy="primary"
        isLoading={isCreatingQuotas}
        onClick={() => onClickSave(quotas)}
        size="md"
        type="button"
      >
        Save Quotas
      </ButtonLoading>
      <Button
        hierarchy="secondary-gray"
        onClick={onClickClose}
        size="md"
        type="button"
      >
        Cancel
      </Button>
    </div>
  );
};

export default ResultsVariableQuotasModal;
