import { minutesToMilliseconds } from 'date-fns';
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  createQuestion,
  createQuestionTemplate,
  deleteQuestion,
  fetchQuestion,
  fetchQuestionTemplates,
  fetchQuestionsForSurvey,
  getQuestionResults,
  reorderQuestions,
  toggleQuestionActivation,
  updateQuestion,
} from 'services/backend/questions';
import { ReactQueryFunctionCallbacks } from 'types/internal';
import { questionBlockQueries } from './questionBlocks';
import { surveyQueries } from './surveys';

export const questionQueries = {
  all: () => ['questions'],
  results: (opts: { questionId: number }) => [
    ...questionQueries.all(),
    opts.questionId,
    'results',
  ],
  resultsV2: (opts: { questionId: number; waveIds: number[] }) => [
    ...questionQueries.all(),
    opts.questionId,
    'results-v2',
    opts.waveIds,
  ],
  forSurvey: (opts: { surveyId: number }) =>
    queryOptions({
      queryFn: () => fetchQuestionsForSurvey({ surveyId: opts.surveyId }),
      queryKey: [...questionQueries.all(), 'surveys', opts.surveyId],
    }),
  get: (opts: { questionId: number }) =>
    queryOptions({
      queryFn: () => fetchQuestion({ questionId: opts.questionId }),
      queryKey: [...questionQueries.all(), opts.questionId],
      staleTime: minutesToMilliseconds(0.5),
    }),
  getResults: (opts: { questionId: number; waveIds: number[] }) =>
    queryOptions({
      queryFn: () =>
        getQuestionResults({
          data: { waveIds: opts.waveIds },
          questionId: opts.questionId,
        }),
      queryKey: [
        ...questionQueries.resultsV2({
          questionId: opts.questionId,
          waveIds: opts.waveIds,
        }),
      ],
      staleTime: minutesToMilliseconds(0.5),
    }),
  listTemplates: queryOptions({
    queryFn: () => fetchQuestionTemplates(),
    queryKey: ['question-templates'],
    staleTime: minutesToMilliseconds(5),
  }),
};

export function useCreateQuestionTemplate(
  opts?: ReactQueryFunctionCallbacks<typeof createQuestionTemplate>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: createQuestionTemplate,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(questionQueries.listTemplates);

      opts?.onSuccess?.(...args);
    },
  });
}

export function useDeleteQuestion(
  opts?: ReactQueryFunctionCallbacks<typeof deleteQuestion>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: async ({
      surveyId,
      ...rest
    }: Parameters<typeof deleteQuestion>[0] & { surveyId: number }) => {
      const deleteQuestionResult = await deleteQuestion(rest);

      await queryClient.invalidateQueries(surveyQueries.survey({ surveyId }), {
        throwOnError: true,
      });
      await queryClient.invalidateQueries(
        questionQueries.forSurvey({ surveyId }),
      );

      return deleteQuestionResult;
    },
  });
}

export function useReorderQuestions(
  opts?: ReactQueryFunctionCallbacks<typeof reorderQuestions>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: reorderQuestions,
    onSuccess: (...args) => {
      const surveyId = args[1].surveyId;

      queryClient.invalidateQueries(questionQueries.forSurvey({ surveyId }));
      queryClient.invalidateQueries(questionBlockQueries.list({ surveyId }));

      opts?.onSuccess?.(...args);
    },
  });
}

export function useSaveQuestion(
  opts?: ReactQueryFunctionCallbacks<
    typeof createQuestion | typeof updateQuestion
  >,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: async (data) => {
      const saveQuestionResult = await ('questionId' in data
        ? updateQuestion(data)
        : createQuestion(data));

      const surveyId = saveQuestionResult.surveyId;

      await queryClient.invalidateQueries(surveyQueries.survey({ surveyId }), {
        throwOnError: true,
      });
      await queryClient.invalidateQueries(
        questionQueries.forSurvey({ surveyId }),
        { throwOnError: true },
      );

      return saveQuestionResult;
    },
  });
}

export function useToggleQuestionActivation(
  opts?: ReactQueryFunctionCallbacks<typeof toggleQuestionActivation>,
) {
  return useMutation({ ...opts, mutationFn: toggleQuestionActivation });
}
