import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip as RechartTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Fragment } from 'react';
import { orderBy } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';

import {
  QuestionCountsOption,
  QuestionCountsStatement,
  QuestionResults,
  QuestionResultsOpenEnded,
} from '../../types/domainModels';
import { questionQueries } from 'hooks/backend/questions';

import ErrorDisplay from '../common/ErrorDisplay';
import HorizontalBarChart, {
  ChartHeader,
  ChartHeaderLabels,
  HorizontalBarPortion,
  HorizontalBars,
} from './HorizontalBarChart';
import IndexCard from '../common/IndexCard';

const QuestionAnalytics = ({
  questionId,
  waveIds,
}: {
  questionId: number;
  waveIds: number[];
}) => {
  const {
    data: results = null,
    isError: hasLoadResultsError,
    isLoading: isLoadingResults,
  } = useQuery(questionQueries.getResults({ questionId, waveIds }));

  if (isLoadingResults) {
    return (
      <div className="w-210">
        <SkeletonQuestionAnalytics />
      </div>
    );
  }

  if (hasLoadResultsError || !results) {
    return <ErrorDisplay message="Failed to load results for question." />;
  }

  return <QuestionResultsDisplay results={results} />;
};

export default QuestionAnalytics;

const SkeletonQuestionAnalytics = () => {
  const tableRows = [1, 2];

  return (
    <div className="space-y-4">
      {/* Mocks out the shape of a bar chart (poorly). */}
      <IndexCard>
        <div className="h-96 animate-pulse p-4">
          <div className="space-y-2">
            <div className="w-20 h-2 bg-light-grey" />
            <div className="w-1/4 h-4 bg-light-grey" />
            <div className="w-1/3 h-2 bg-light-grey" />
          </div>
          <div className="mt-16 space-y-6">
            <div className="w-4/5 h-4 bg-light-grey" />
            <div className="w-3/5 h-4 bg-light-grey" />
            <div className="w-2/5 h-4 bg-light-grey" />
            <div className="w-1/5 h-4 bg-light-grey" />
          </div>
        </div>
      </IndexCard>
      {/* Mocks out the shape of the Quotas Card. */}
      <IndexCard>
        <div className="p-4 space-y-4">
          <div className="w-1/4 h-4 bg-light-grey" />
          <div className="grid grid-cols-3 gap-4 animate-pulse">
            {tableRows.map((num) => {
              return (
                <Fragment key={num}>
                  <div className="w-3/4 h-4 bg-light-grey" />
                  <div className="w-3/4 h-4 bg-light-grey" />
                  <div className="w-3/4 h-4 bg-light-grey" />
                </Fragment>
              );
            })}
          </div>
        </div>
      </IndexCard>
    </div>
  );
};

const QuestionResultsDisplay = ({ results }: { results: QuestionResults }) => {
  if (
    results.questionType === 'number' ||
    results.questionType === 'openEnded'
  ) {
    return (
      <IndexCard>
        <div className="flex justify-between p-4">
          <ChartHeader sort={results.sort} title={results.title} />
          <p className="text-dark-grey text-xs">
            {results.freeTextResponses.length} respondent
            {results.freeTextResponses.length === 1 ? '' : 's'}
          </p>
        </div>

        <OpenEndedAnalysis results={results} />
      </IndexCard>
    );
  }

  if (
    results.questionType === 'ideaPresenter' ||
    results.questionType === 'multipleChoice' ||
    results.questionType === 'unaidedAwareness'
  ) {
    return results.counts.map((count) => {
      return (
        <IndexCard key={count.concept}>
          <div className="flex justify-between p-4">
            <ChartHeader
              sort={results.sort}
              title={getChartTitle({
                concept: count.concept,
                title: results.title,
              })}
            />
            <p className="text-dark-grey text-xs">
              {count.exposures} respondent{count.exposures === 1 ? '' : 's'}
            </p>
          </div>

          <HorizontalBarChart>
            <SingleDimensionHorizontalBarChart options={count.options} />
          </HorizontalBarChart>
        </IndexCard>
      );
    });
  }

  if (
    results.questionType === 'matrix' ||
    results.questionType === 'ranking' ||
    results.questionType === 'scale'
  ) {
    return results.counts.map((count) => {
      return (
        <IndexCard key={count.concept}>
          <div className="flex justify-between p-4">
            <ChartHeader
              sort={results.sort}
              title={getChartTitle({
                concept: count.concept,
                title: results.title,
              })}
            />
            <p className="text-dark-grey text-xs">
              {count.exposures} respondent{count.exposures === 1 ? '' : 's'}
            </p>
          </div>

          <HorizontalBarChart
            labels={
              <ChartHeaderLabels
                labels={count.statements[0].options.map((o) => o.title)}
              />
            }
          >
            <MultiDimensionHorizontalBarChart statements={count.statements} />
          </HorizontalBarChart>
        </IndexCard>
      );
    });
  }

  if (results.questionType === 'gaborGranger') {
    return results.counts.map((count) => {
      return (
        <IndexCard key={count.concept}>
          <div className="flex justify-between p-4">
            <ChartHeader
              sort={results.sort}
              title={getChartTitle({
                concept: count.concept,
                title: results.title,
              })}
            />
            <p className="text-dark-grey text-xs">
              {count.exposures} respondent{count.exposures === 1 ? '' : 's'}
            </p>
          </div>

          <GaborGrangerChartCard options={count.options} />
        </IndexCard>
      );
    });
  }

  return null;
};

const SingleDimensionHorizontalBarChart = ({
  options,
}: {
  options: QuestionCountsOption[];
}) => {
  const orderedOptions = orderBy(options, (o) => o.sort);

  return orderedOptions.map((option, optionIdx) => {
    const count = option.metrics.selections;
    const percentage = option.metrics.exposures
      ? count / option.metrics.exposures
      : 0;

    return (
      <HorizontalBars
        key={option.id}
        lastColumn={`${count}, ${Number(percentage * 100).toFixed(0)}%`}
        title={option.title}
      >
        <HorizontalBarPortion
          colorIndex={0}
          count={count}
          index={optionIdx}
          numBars={optionIdx}
          percentage={percentage}
          title={option.title}
        />
      </HorizontalBars>
    );
  });
};

const MultiDimensionHorizontalBarChart = ({
  statements,
}: {
  statements: QuestionCountsStatement[];
}) => {
  const orderedStatements = orderBy(statements, (s) => s.sort);

  return orderedStatements.map((statement) => {
    return (
      <HorizontalBars key={statement.id} title={statement.title}>
        {statement.options.map((option, optionIdx) => {
          const count = option.metrics.selections;
          const percentage = statement.metrics.exposures
            ? count / statement.metrics.exposures
            : 0;

          return (
            <HorizontalBarPortion
              key={option.id}
              colorIndex={optionIdx}
              count={count}
              index={optionIdx}
              numBars={statement.options.length - 1}
              percentage={percentage}
              title={option.title}
            />
          );
        })}
      </HorizontalBars>
    );
  });
};

const OpenEndedAnalysis = ({
  results,
}: {
  results: QuestionResultsOpenEnded;
}) => {
  return (
    <div className="space-y-2">
      <div className="grid grid-cols-open-end-responses gap-2 items-center h-10 px-4 bg-dark-white text-dark-grey text-xs">
        <div>ID</div>
        <div>Response</div>
      </div>
      <div className="overflow-auto" style={{ maxHeight: '405px' }}>
        {results.freeTextResponses.length === 0 && (
          <p className="p-4 text-sm">No responses</p>
        )}
        {results.freeTextResponses.map(({ respondentId, response }) => {
          return (
            <div
              key={respondentId}
              className="grid grid-cols-open-end-responses gap-2 p-4 text-sm"
            >
              <div>{respondentId}</div>
              <div>{response}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const GaborGrangerChartCard = ({
  options,
}: {
  options: QuestionCountsOption[];
}) => {
  const data = orderBy(options, (option) => option.sort).map(
    ({ metrics, title }) => {
      return {
        name: title,
        value: (metrics.selections / metrics.exposures) * 100,
      };
    },
  );

  return (
    <ResponsiveContainer height={300} width="100%">
      <BarChart data={data}>
        <XAxis dataKey="name" type="category" />
        <YAxis domain={[0, 100]} ticks={[0, 20, 40, 60, 80, 100]} />
        <RechartTooltip cursor={{ fill: '#eee' }} />
        <Bar barSize={20} dataKey="value" fill="#3f93ea" />
      </BarChart>
    </ResponsiveContainer>
  );
};

function getChartTitle({ concept, title }: { concept: string; title: string }) {
  if (concept) {
    return `${title}, ${concept}`;
  }

  return title;
}
