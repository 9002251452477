import { Form, Formik, FormikErrors, useField, useFormikContext } from 'formik';
import { isEqual } from 'lodash-es';
import { ReactNode, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { CreateSurveyBody } from '../../services/backend/surveys';
import { createTeamMemberOption } from '../../util/team';
import { getIncidenceTypeOptions } from '../../util/incidence';
import { getNestedErrorMessages } from 'util/forms';
import { getOrgStatus } from '../../util/users';
import {
  IncidenceType,
  Question,
  Survey,
  Tag,
  TeamMember,
} from '../../types/domainModels';
import { incidenceTypeQueries } from 'hooks/backend/incidenceTypes';
import { ReactSelectValue } from '../../types/forms';
import { showErrorMessage } from '../../util/notifications';
import { SurveyFlowStep } from '../../types/internal';
import { useCreateTag, useTags } from 'hooks/backend/tags';
import { useHasRole } from '../../hooks/users';
import { useOrderedOrganizations } from '../../hooks/backend/organizations';
import { useOrderedTeamMembers } from '../../hooks/backend/team';
import { useSaveSurvey } from 'hooks/backend/surveys';
import { useSubmitValidation } from 'hooks/forms';

import ButtonLoading from '../common/forms/ButtonLoading';
import FixedHeaderAndCollapsedSidebar from '../layout/FixedHeaderAndCollapsedSidebar';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormInput from '../common/forms/FormInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import IndexCard from '../common/IndexCard';
import { Sidebar } from '../layout/DefaultLayout';
import SkeletonSurveyCard from './SkeletonSurveyCard';
import SurveyEditHeader from './SurveyEditHeader';
import SurveyEditRow from './SurveyEditRow';
import { SurveyWaveTitleEditPage } from './SurveyWaveTitle';
import SurveyWithSidebar from '../layout/SurveyWithSidebar';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';

function apiDataToFormData({
  incidenceTypes,
  initialContact,
  initialSurveyOrg,
  initialTag,
  organizations,
  survey,
}: {
  incidenceTypes: ReactSelectValue<IncidenceType>[];
  initialContact: TeamMember | undefined;
  initialSurveyOrg: ReactSelectValue<number> | undefined;
  initialTag: Tag | undefined;
  organizations: ReactSelectValue<number>[];
  survey: Survey | undefined;
}): SurveyOverviewFormData {
  // We could have an initial organization if the user is modifying the details of an existing survey
  // or if this is an admin that has changed organizations and is creating a new survey for that
  // organization.
  let organization = initialSurveyOrg;
  if (survey?.organizationId) {
    organization = organizations.find(({ value }) => {
      return value === survey.organizationId;
    });
  }

  return {
    contact: initialContact ? createTeamMemberOption(initialContact) : null,
    incidenceType:
      incidenceTypes.find(({ value }) => {
        return value.id === survey?.incidenceTypeId;
      }) ?? null,
    organizationId: organization ?? null,
    participants: survey?.participants ?? '',
    tag: initialTag ? createTagOption(initialTag) : null,
    title: survey?.title ?? '',
  };
}

function createTagOption(tag: Tag): ReactSelectValue<Tag> {
  return {
    label: tag.title,
    value: tag,
  };
}

function formDataToApiData({
  formData,
}: {
  formData: SurveyOverviewFormDataValidated;
}): CreateSurveyBody {
  const { contact, incidenceType, organizationId, participants, tag, title } =
    formData;

  return {
    // TODO: Not sure what campaignTypeAbbv means...
    campaignTypeAbbv: 'ci' as const,
    incidenceTypeId: incidenceType.value.id,
    // TODO: Locations is required or else BE error...
    locations: [],
    organizationId: organizationId?.value,
    ownerId: contact?.value.id,
    participants,
    projectId: tag?.value.id ?? null,
    // TODO: statusId = 6 means "draft"...
    statusId: 6,
    title,
    // TODO: Not sure what whiteLabeled means...
    whiteLabeled: false,
  };
}

function validateOverviewData(
  formData: SurveyOverviewFormData,
  { isAdmin = false } = {},
): FormikErrors<SurveyOverviewFormData> {
  const errors: FormikErrors<SurveyOverviewFormData> = {};

  if (!formData.incidenceType) {
    errors.incidenceType = 'Please select an incidence type.';
  }

  if (!formData.organizationId) {
    errors.organizationId = 'Please select an organization.';
  }

  if (!formData.contact && isAdmin) {
    errors.contact = 'Please select a survey contact.';
  }

  if (!formData.participants) {
    errors.participants = 'Please enter the number of participants.';
  }

  if (!formData.title) {
    errors.title = 'Please enter a title.';
  }

  return errors;
}

interface SurveyOverviewFormData {
  contact: ReactSelectValue<TeamMember> | null;
  incidenceType: ReactSelectValue<IncidenceType> | null;
  organizationId: ReactSelectValue<number> | null;
  participants: number | '';
  tag: ReactSelectValue<Tag> | null;
  title: string;
}

interface SurveyOverviewFormDataValidated {
  contact: ReactSelectValue<TeamMember> | null;
  incidenceType: ReactSelectValue<IncidenceType>;
  organizationId: ReactSelectValue<number>;
  participants: number;
  tag: ReactSelectValue<Tag> | null;
  title: string;
}

const OverviewStep = ({
  isLoadingSurvey,
  isShowingUnsavedChanges,
  onClickStep,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onOverviewDirtyChanged,
  onOverviewSaved,
  onStepCompleted,
  questions,
  sidebar,
  survey,
}: {
  isLoadingSurvey: boolean;
  isShowingUnsavedChanges: boolean;
  onClickStep?(step: SurveyFlowStep): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onOverviewDirtyChanged(isDirty: boolean): void;
  onOverviewSaved(): void;
  onStepCompleted(surveyId: number): void;
  questions: Question[];
  sidebar?: ReactNode;
  survey: Survey | undefined;
}): JSX.Element => {
  const isAdmin = useHasRole('admin');

  const { currentOrganizationId } = getOrgStatus();

  const { data: incidenceTypes = [], isLoading: isLoadingIncidenceTypes } =
    useQuery(incidenceTypeQueries.list);

  const { isLoadingTags, tags } = useTags({
    organizationId: survey?.organizationId,
  });
  const initialTag = tags.find(({ id }) => id === survey?.projectId);

  const { isLoadingOrgs, organizations } = useOrderedOrganizations();
  const initialSurveyOrg = organizations.find(({ value }) => {
    return value === currentOrganizationId;
  });

  const { isLoadingTeamMembers, orderedTeamMembers } = useOrderedTeamMembers({
    enabled: isAdmin,
    organizationId: survey?.organizationId,
  });
  const initialContact = orderedTeamMembers.find(
    ({ id }) => id === survey?.userId,
  );

  const isLoading =
    isLoadingSurvey ||
    isLoadingIncidenceTypes ||
    isLoadingTags ||
    isLoadingTeamMembers ||
    isLoadingOrgs;

  return isLoading ? (
    <FixedHeaderAndCollapsedSidebar
      header={
        survey ? (
          <SurveyEditHeader onClickStep={onClickStep} survey={survey} />
        ) : null
      }
      sidebar={<Sidebar isCollapsed />}
    >
      <SurveyWithSidebar sidebar={sidebar}>
        <SkeletonSurveyCard />
      </SurveyWithSidebar>
    </FixedHeaderAndCollapsedSidebar>
  ) : (
    <OverviewStepLoaded
      incidenceTypes={getIncidenceTypeOptions(incidenceTypes)}
      initialContact={initialContact}
      initialSurveyOrg={initialSurveyOrg}
      initialTag={initialTag}
      isAdmin={isAdmin}
      isShowingUnsavedChanges={isShowingUnsavedChanges}
      onClickStep={onClickStep}
      onDiscardChanges={onDiscardChanges}
      onDismissUnsavedChanges={onDismissUnsavedChanges}
      onHasError={onHasError}
      onOverviewDirtyChanged={onOverviewDirtyChanged}
      onOverviewSaved={onOverviewSaved}
      onStepCompleted={onStepCompleted}
      organizations={organizations}
      questions={questions}
      sidebar={sidebar}
      survey={survey}
    />
  );
};

export default OverviewStep;

const OverviewStepLoaded = ({
  incidenceTypes,
  initialContact,
  initialSurveyOrg,
  initialTag,
  isAdmin,
  isShowingUnsavedChanges,
  onClickStep,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onOverviewDirtyChanged,
  onOverviewSaved,
  onStepCompleted,
  organizations,
  questions,
  sidebar,
  survey,
}: {
  incidenceTypes: ReactSelectValue<IncidenceType>[];
  initialContact: TeamMember | undefined;
  initialSurveyOrg: ReactSelectValue<number> | undefined;
  initialTag: Tag | undefined;
  isAdmin: boolean;
  isShowingUnsavedChanges: boolean;
  onClickStep?(step: SurveyFlowStep): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onOverviewDirtyChanged(isDirty: boolean): void;
  onOverviewSaved(): void;
  onStepCompleted(surveyId: number): void;
  organizations: ReactSelectValue<number>[];
  questions: Question[];
  sidebar?: ReactNode;
  survey: Survey | undefined;
}): JSX.Element => {
  const { isPending: isSavingSurvey, mutateAsync: saveSurvey } = useSaveSurvey({
    onError: (err) => {
      onHasError();
      showErrorMessage(
        `There was an error updating the survey. Error: ${err.message}`,
      );
    },
    onSuccess: (data) => {
      onOverviewSaved();

      // If this is a new survey, then we want to complete the step when we're done
      // saving so the user is taken to the next step without having to click an
      // additional button.
      if (!survey) {
        onStepCompleted(data.id);
      }
    },
  });

  const initialValues = apiDataToFormData({
    incidenceTypes,
    initialContact,
    initialSurveyOrg,
    initialTag,
    organizations,
    survey,
  });

  return (
    <>
      <Formik<SurveyOverviewFormData>
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(formData) => {
          if (survey && isEqual(formData, initialValues)) {
            onStepCompleted(survey.id);
          } else {
            const data = formDataToApiData({
              formData: formData as SurveyOverviewFormDataValidated,
            });

            return survey
              ? saveSurvey({ data, surveyId: survey.id })
              : saveSurvey({ data });
          }
        }}
        validate={(formData) => {
          return validateOverviewData(formData, { isAdmin });
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <Form className="h-full">
          <OverviewForm
            incidenceTypes={incidenceTypes}
            isAdmin={isAdmin}
            isSavingSurvey={isSavingSurvey}
            isShowingUnsavedChanges={isShowingUnsavedChanges}
            onClickStep={onClickStep}
            onDiscardChanges={onDiscardChanges}
            onDismissUnsavedChanges={onDismissUnsavedChanges}
            onHasError={onHasError}
            onOverviewDirtyChanged={onOverviewDirtyChanged}
            organizations={organizations}
            questions={questions}
            sidebar={sidebar}
            survey={survey}
          />
        </Form>
      </Formik>
    </>
  );
};

const OverviewForm = ({
  incidenceTypes,
  isAdmin,
  isSavingSurvey,
  isShowingUnsavedChanges,
  onClickStep,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onOverviewDirtyChanged,
  organizations,
  sidebar,
  survey,
}: {
  incidenceTypes: ReactSelectValue<IncidenceType>[];
  isAdmin: boolean;
  isSavingSurvey: boolean;
  isShowingUnsavedChanges: boolean;
  onClickStep?(step: SurveyFlowStep): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onOverviewDirtyChanged(isDirty: boolean): void;
  organizations: ReactSelectValue<number>[];
  questions: Question[];
  sidebar?: ReactNode;
  survey: Survey | undefined;
}): JSX.Element => {
  const { dirty } = useFormikContext();

  const [{ value: organizationIdSelect }] =
    useField<SurveyOverviewFormData['organizationId']>('organizationId');
  const [, , setContactHelpers] =
    useField<SurveyOverviewFormData['contact']>('contact');
  const [, , setTagHelpers] = useField<SurveyOverviewFormData['tag']>('tag');

  const organizationId = organizationIdSelect?.value;

  const { isLoadingTeamMembers, orderedTeamMembers } = useOrderedTeamMembers({
    enabled: isAdmin,
    organizationId,
  });

  // We only want to display survey contact options to the admin users if they have already selected the
  // organization to which the survey will belong.
  const teamMembers =
    isAdmin && !!organizationId
      ? orderedTeamMembers.map((teamMember) => {
          return createTeamMemberOption(teamMember);
        })
      : [];

  const { errors, onClickSubmit, validateAndSubmit } = useSubmitValidation({
    isSaving: isSavingSurvey,
    onHasError,
  });

  useEffect(() => {
    onOverviewDirtyChanged(dirty);
  }, [dirty, onOverviewDirtyChanged]);

  return (
    <FixedHeaderAndCollapsedSidebar
      header={
        survey ? (
          <SurveyEditHeader
            actionButton={
              <ButtonLoading
                hierarchy="primary"
                isLoading={isSavingSurvey}
                onClick={onClickSubmit}
                size="sm"
                // This can't currently be a submit button since we handle the form submission
                // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
                type="button"
              >
                {survey && dirty ? 'Save Changes' : 'Next Step'}
              </ButtonLoading>
            }
            onClickStep={onClickStep}
            survey={survey}
          />
        ) : (
          <header className="flex h-full items-center pl-6 bg-white">
            <h1 className="w-survey-page-sidebar text-forest font-medium truncate flex-shrink-0 mr-6">
              New Survey
            </h1>
            <div className="flex-grow h-full max-w-survey-summary-card flex-shrink-0 pr-6"></div>
            <div className="pr-6">
              <ButtonLoading
                hierarchy="primary"
                isLoading={isSavingSurvey}
                size="sm"
                type="submit"
              >
                Next Step
              </ButtonLoading>
            </div>
          </header>
        )
      }
      sidebar={<Sidebar isCollapsed />}
    >
      <SurveyWithSidebar sidebar={sidebar}>
        {errors && (
          <div className="mb-8">
            <FormErrorsAlert errors={getNestedErrorMessages(errors)} />
          </div>
        )}

        <SurveyWaveTitleEditPage survey={survey} />

        <IndexCard>
          {isShowingUnsavedChanges && (
            <UnsavedChangesModal
              isSaving={isSavingSurvey}
              onClickDiscardChanges={onDiscardChanges}
              onClickSaveChanges={validateAndSubmit}
              onCloseModal={onDismissUnsavedChanges}
            />
          )}

          <div>
            <div>
              <SurveyEditRow title="Survey Title">
                <FormInput
                  id="title"
                  labelFor="title"
                  name="title"
                  placeholder=" ex: Package testing study"
                  size="md"
                  type="text"
                />
              </SurveyEditRow>
              <SurveyEditRow title="Number of Participants">
                <FormInput
                  id="participants"
                  labelFor="participants"
                  name="participants"
                  placeholder="1000"
                  size="md"
                  type="number"
                />
              </SurveyEditRow>
              <SurveyEditRow title="Estimated Incidence">
                <FormSearchSelectInput
                  borderColor="#D9D9D9"
                  inputId="incidenceType"
                  labelFor="incidenceType"
                  name="incidenceType"
                  options={incidenceTypes}
                />
              </SurveyEditRow>
              <SurveyEditRow
                isLastRow={!isAdmin}
                subtitle="(optional)"
                title="Tags"
              >
                <TagSelect />
              </SurveyEditRow>
            </div>
            {organizations.length > 1 && (
              <SurveyEditRow title="Organization">
                <FormSearchSelectInput
                  borderColor="#D9D9D9"
                  inputId="organization"
                  labelFor="organization"
                  name="organizationId"
                  onChange={() => {
                    // If an organization is changed, we load new options for the survey contact and tag so we want to clear out
                    // the existing ones and make the user select an updated value.
                    setContactHelpers.setValue(null);
                    setTagHelpers.setValue(null);
                  }}
                  options={organizations}
                />
              </SurveyEditRow>
            )}
            {isAdmin && (
              <SurveyEditRow subtitle="ADMIN ONLY" title="Survey Contact">
                <FormSearchSelectInput
                  borderColor="#D9D9D9"
                  inputId="contact"
                  isDisabled={isLoadingTeamMembers}
                  isLoading={isLoadingTeamMembers}
                  labelFor="contact"
                  name="contact"
                  options={teamMembers}
                />
              </SurveyEditRow>
            )}
          </div>
        </IndexCard>
      </SurveyWithSidebar>
    </FixedHeaderAndCollapsedSidebar>
  );
};

const TagSelect = () => {
  // The organization might be a form field if this is an admin filling out a survey for a different organization -
  // otherwise it will be the organization for the user.
  const [{ value: organizationIdSelect }] =
    useField<SurveyOverviewFormData['organizationId']>('organizationId');
  const organizationId = organizationIdSelect?.value;

  const [, , tagHelpers] = useField<SurveyOverviewFormData['tag']>('tag');

  const { isLoadingTags, onTagCreated, tags } = useTags({ organizationId });
  const tagOptions = tags.map((tag) => {
    return createTagOption(tag);
  });

  const { isPending: isCreatingTag, mutate: createTag } = useCreateTag({
    onError: (err) => {
      showErrorMessage(`Failed to create new tag. Error: ${err.message}`);
    },
    onSuccess: async (data) => {
      await onTagCreated();
      tagHelpers.setValue(createTagOption(data));
    },
  });

  return (
    <FormSearchSelectInput
      borderColor="#D9D9D9"
      inputId="tag"
      isCreatable={true}
      isDisabled={isLoadingTags || isCreatingTag}
      isLoading={isLoadingTags || isCreatingTag}
      labelFor="tag"
      name="tag"
      onCreateOption={(newTagName: string) => {
        if (!organizationId) {
          throw new Error(
            'Could not determine your organization. Please log out and log back in.',
          );
        }

        return createTag({ data: { organizationId, title: newTagName } });
      }}
      options={tagOptions}
    />
  );
};
