import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  createVariableQuotas,
  deleteVariableQuotas,
  fetchVariableQuotaResults,
} from 'services/backend/surveyVariableQuotas';
import { ReactQueryFunctionCallbacks } from 'types/internal';

export const variableQuotaQueries = {
  all: (opts: { surveyId: number }) => [
    'surveys',
    opts.surveyId,
    'variable-quotas',
  ],
  forSurvey: (opts: { surveyId: number; waveIds: number[] }) =>
    queryOptions({
      queryFn: () =>
        fetchVariableQuotaResults({
          surveyId: opts.surveyId,
          waveIds: opts.waveIds,
        }),
      queryKey: [
        ...variableQuotaQueries.all({ surveyId: opts.surveyId }),
        opts.waveIds,
      ],
    }),
};

export function useCreateVariableQuotas(
  opts: ReactQueryFunctionCallbacks<typeof createVariableQuotas>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: createVariableQuotas,
    onSuccess: (...args) => {
      queryClient.invalidateQueries();

      opts?.onSuccess?.(...args);
    },
  });
}

export function useRemoveVariableQuotas(
  opts?: ReactQueryFunctionCallbacks<typeof deleteVariableQuotas>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: deleteVariableQuotas,
    onSuccess: (...args) => {
      queryClient.invalidateQueries();

      opts?.onSuccess?.(...args);
    },
  });
}
