import { castArray, compact, isEmpty, groupBy, map } from 'lodash-es';
import { FormikErrors } from 'formik';

import {
  Audience as CreateQuestionAudience,
  AudienceSliceCategoryAttribute,
} from '../services/backend/questions';
import {
  Audience,
  DisplayLogicConstraint as DisplayLogicConstraintAPI,
  DisplayLogicLogicalModifier,
  Question,
  QuestionAudienceSliceCategoryAttribute,
  QUESTION_TYPE,
  SurveyVariableSegment,
} from '../types/domainModels';
import {
  ConstraintConcepts,
  ConstraintOptions,
  ConstraintWithNumber,
  ConstraintWithRange,
  ConstraintWithStatement,
  DisplayLogicAndGroup,
  DisplayLogicConstraint,
  DisplayLogicConstraintValidated,
  DisplayLogicOrGroups,
  DisplayLogicOrGroupsValidated,
  QuestionFormDataValidated,
  ReactSelectValue,
} from '../types/forms';
import { CreateDisplayLogicBody } from '../types/requestBodies';
import {
  DISPLAY_LOGIC_MODIFIER_OPTIONS,
  getConceptOption,
  getConceptOptions,
  getOptionOption,
  getOptionOptions,
  getQuestionOption,
} from './formOptions';
import { getOptionTitleIndexFromSort } from './options';
import { isIdeaPresenterQuestion } from './questions';

export const MODIFIER_OPTION_EITHER: ReactSelectValue<DisplayLogicLogicalModifier> =
  {
    label: 'either',
    value: 'should',
  };
export const MODIFIER_OPTION_EQUAL_TO: ReactSelectValue<DisplayLogicLogicalModifier> =
  {
    label: 'equal to',
    value: 'is',
  };
export const MODIFIER_OPTION_WITHIN: ReactSelectValue<DisplayLogicLogicalModifier> =
  {
    label: 'within',
    value: 'should',
  };
export const MODIFIER_OPTIONS_DEFAULT: ReactSelectValue<DisplayLogicLogicalModifier>[] =
  [
    MODIFIER_OPTION_EQUAL_TO,
    MODIFIER_OPTION_EITHER,
    { label: 'not', value: 'isnt' },
  ];

/**
 * Transforms display logic entered into a form into a format that the API accepts.
 */
export function getApiDataForDisplayLogic({
  displayLogic,
}: {
  displayLogic: QuestionFormDataValidated['features']['displayLogic']['values'];
}): CreateQuestionAudience[] {
  return displayLogic.map((orGroup, i) => {
    return {
      categories: {
        audienceSlices: [
          {
            audienceSliceCategories: orGroup.map((andGroup) => {
              return {
                audienceSliceCategoryAttributes:
                  getApiConstraintsForDisplayLogic({
                    constraints: andGroup.constraints,
                  }),
                conceptId: andGroup.concept?.value?.id ?? null,
                logicalModifier: andGroup.modifier.value,
                questionId: andGroup.question.value.id,
              };
            }),
            percentage: 1,
          },
        ],
        // TODO is dependentSlices always true?
        dependentSlices: true,
        // TODO is isPublic always false?
        isPublic: false,
      },
      sort: i + 1,
      unionModifier: i === 0 ? 'and' : 'or',
    };
  });
}

export function getApiDataForDisplayLogicV2({
  displayLogic,
}: {
  displayLogic: DisplayLogicOrGroupsValidated;
}): CreateDisplayLogicBody {
  return displayLogic.flatMap((orGroup, orGroupIdx) => {
    return orGroup.map((andGroup) => {
      return {
        andGrouping: orGroupIdx,
        constraints: getApiConstraintsForDisplayLogicV2({
          constraints: andGroup.constraints,
        }),
        logicalModifier: andGroup.modifier.value,
        questionId: andGroup.question.value.id,
      };
    });
  });
}

/**
 * Transforms a display logic form constraint into a format that the API accepts.
 */
export function getApiConstraintsForDisplayLogic({
  constraints,
}: {
  constraints: DisplayLogicConstraintValidated[];
}): AudienceSliceCategoryAttribute[] {
  return constraints.flatMap<AudienceSliceCategoryAttribute>((constraint) => {
    if (isConstraintWithStatement(constraint)) {
      const { statement, options } = constraint;

      return castArray(options).map((option) => {
        return {
          enumValue: statement.value.id,
          id: statement.value.id,
          matrixOptionId: option.value.id,
        };
      });
    }

    if (isConstraintWithRanges(constraint)) {
      const { option, range } = constraint;

      return {
        end: range.end,
        enumValue: option.value.id,
        start: range.start,
      };
    }

    if (isConstraintWithNumber(constraint)) {
      const { range } = constraint;

      return {
        end: range.end,
        start: range.start,
      };
    }

    if (isConstraintWithConcepts(constraint)) {
      return castArray(constraint.concepts).map(({ value }) => ({
        id: value.id,
      }));
    }

    return castArray(constraint.options).map(({ value }) => value.id);
  });
}

export function getApiConstraintsForDisplayLogicV2({
  constraints,
}: {
  constraints: DisplayLogicConstraintValidated[];
}): CreateDisplayLogicBody[number]['constraints'] {
  return constraints.flatMap<
    CreateDisplayLogicBody[number]['constraints'][number]
  >((constraint) => {
    if (isConstraintWithStatement(constraint)) {
      const { statement, options } = constraint;

      return {
        optionId: statement.value.id,
        matrixOptionIds: castArray(options).map((option) => {
          return option.value.id;
        }),
      };
    }

    if (isConstraintWithRanges(constraint)) {
      const { option, range } = constraint;

      return {
        numberRange: { end: range.end, start: range.start },
        optionId: option.value.id,
      };
    }

    if (isConstraintWithNumber(constraint)) {
      return {
        numberRange: {
          end: constraint.range.end,
          start: constraint.range.start,
        },
      };
    }

    if (isConstraintWithConcepts(constraint)) {
      return castArray(constraint.concepts).map(({ value }) => {
        return { conceptId: value.id };
      });
    }

    return castArray(constraint.options).map(({ value }) => {
      return { optionId: value.id };
    });
  });
}

export function getEligibleQuestions({
  questions,
}: {
  questions: Question[];
}): Question[] {
  return questions.filter((question) => {
    return ![QUESTION_TYPE.GABOR_GRANGER, QUESTION_TYPE.OPEN_ENDED].includes(
      question.questionTypeId,
    );
  });
}

export function getEmptyAndGroup(): DisplayLogicAndGroup {
  return {
    concept: null,
    constraints: [],
    modifier: MODIFIER_OPTION_EQUAL_TO,
    question: null,
  };
}

export function getEmptyConstraintWithConcepts(): ConstraintConcepts {
  return { concepts: [] };
}

export function getEmptyConstraintWithNumber(): ConstraintWithNumber {
  return {
    range: { end: '', start: '' },
  };
}

export function getEmptyConstraintWithOptions(): ConstraintOptions {
  return { options: [] };
}

export function getEmptyConstraintWithRange(): ConstraintWithRange {
  return {
    option: null,
    range: { end: '', start: '' },
  };
}

export function getEmptyConstraintWithStatement(): ConstraintWithStatement {
  return {
    statement: null,
    options: [],
  };
}

/**
 * Transforms audiences from the API into a format usable for display logic in a form.
 */
export function getFormDisplayLogic({
  audiences,
  questions,
}: {
  audiences: Audience[];
  questions: Question[];
}): DisplayLogicOrGroups {
  const monadicConcepts = questions
    .filter((q) => !!q.monadicId)
    .flatMap((q) => q.concepts ?? []);

  const apiDisplayLogic =
    audiences
      .filter((audience) => !!audience)
      .map((audience) => {
        const andGroupData = audience.audienceSlices[0].audienceSliceCategories;

        return andGroupData.map(
          ({
            audienceSliceCategoryAttributes,
            conceptId,
            logicalModifier,
            question,
            questionId,
          }) => {
            const modifier =
              DISPLAY_LOGIC_MODIFIER_OPTIONS.find(
                ({ value }) => value === logicalModifier,
              ) || null;

            // We could potentially revisit looking at the questions array first instead of always just
            // using the "question" variable. Not doing that now because I'm not confident the "question"
            // here has the exact same structure as the questions in the "questions" array.
            const questionToUse =
              questions.find(({ id }) => {
                return id === questionId;
              }) || question;
            const conceptToUse = monadicConcepts.find(
              (c) => c.id === conceptId,
            );

            return {
              concept: questionToUse.monadicId
                ? conceptToUse
                  ? getConceptOption({ concept: conceptToUse })
                  : { label: 'Any concept', value: null }
                : null,
              constraints: getFormDisplayLogicConstraints({
                audienceSliceCategoryAttributes,
                question: questionToUse,
              }),
              modifier,
              question: questionToUse
                ? getQuestionOption({ question: questionToUse })
                : null,
            };
          },
        );
      }) ?? [];

  return apiDisplayLogic.length > 0 ? apiDisplayLogic : [[getEmptyAndGroup()]];
}

/**
 * Transforms audience constraints from the API into a format usable for display logic
 * constraints in a form.
 */
function getFormDisplayLogicConstraints({
  audienceSliceCategoryAttributes,
  question,
}: {
  audienceSliceCategoryAttributes: QuestionAudienceSliceCategoryAttribute[];
  question:
    | {
        concepts?: { description: string; id: number }[];
        matrixOptions: { id: number; sort: number; title: string }[];
        options: {
          description: string | null;
          id: number;
          isActive: boolean;
          sort: number;
          title: string;
        }[];
        questionTypeId: number;
      }
    | undefined;
}): DisplayLogicConstraint[] {
  if (!question) {
    return [];
  }

  if (isIdeaPresenterQuestion(question)) {
    const conceptIds = audienceSliceCategoryAttributes.map(
      ({ audienceAttribute }) => {
        return audienceAttribute.enumNumberRange?.start;
      },
    );

    return [
      {
        concepts: getConceptOptions({
          concepts: question.concepts ?? [],
          filterFn: ({ id }) => {
            return conceptIds.includes(id);
          },
        }),
      },
    ];
  } else if (question.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
    const optionIds = audienceSliceCategoryAttributes.map(
      ({ audienceAttribute }) => {
        return audienceAttribute.enumValueId;
      },
    );

    return [
      {
        options: getOptionOptions({
          filterFn: ({ id }) => {
            return optionIds.includes(id);
          },
          options: question.options,
        }),
      },
    ];
  } else if (
    question.questionTypeId === QUESTION_TYPE.RANKING ||
    question.questionTypeId === QUESTION_TYPE.SCALE
  ) {
    return audienceSliceCategoryAttributes.map(({ audienceAttribute }) => {
      const option = question.options.find(({ id }) => {
        return id === audienceAttribute.enumValueId;
      });

      return {
        option: option
          ? getOptionOption({
              index: getOptionTitleIndexFromSort(option.sort),
              option,
            })
          : null,
        range: {
          end: audienceAttribute.enumNumberRange?.end ?? '',
          start: audienceAttribute.enumNumberRange?.start ?? '',
        },
      };
    });
  } else if (question.questionTypeId === QUESTION_TYPE.MATRIX) {
    const groupedByStatement = groupBy(
      audienceSliceCategoryAttributes,
      (asca) => asca.audienceAttribute.enumValueId,
    );

    return map(groupedByStatement, (group, statementId) => {
      const statement = question.options.find(({ id }) => {
        return `${id}` === statementId;
      });
      const options = question.matrixOptions
        .filter((option) => {
          return group.find(({ audienceAttribute }) => {
            return audienceAttribute.matrixOptionId === option.id;
          });
        })
        .map((option) => {
          return {
            label: option.title,
            value: option,
          };
        });

      return {
        statement: statement
          ? getOptionOption({
              index: getOptionTitleIndexFromSort(statement.sort),
              option: statement,
            })
          : null,
        options,
      };
    });
  }

  return [];
}

export function getFormDisplayLogicConstraintsV2({
  apiConstraints,
  fullQuestion,
}: {
  apiConstraints: DisplayLogicConstraintAPI[];
  fullQuestion: Question;
}): DisplayLogicConstraint[] {
  if (isIdeaPresenterQuestion(fullQuestion)) {
    return [
      {
        concepts: compact(
          apiConstraints.map((constraint) => {
            const concept = fullQuestion?.concepts?.find(
              ({ id }) => id === constraint.conceptId,
            );

            return concept ? getConceptOption({ concept }) : undefined;
          }),
        ),
      },
    ];
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE) {
    return [
      {
        options: compact(
          apiConstraints.map((constraint) => {
            const option = fullQuestion.options.find(
              ({ id }) => id === constraint.optionId,
            );

            return option ? getOptionOption({ option }) : undefined;
          }),
        ),
      },
    ];
  } else if (
    fullQuestion.questionTypeId === QUESTION_TYPE.RANKING ||
    fullQuestion.questionTypeId === QUESTION_TYPE.SCALE
  ) {
    return compact(
      apiConstraints.map((constraint) => {
        const option = fullQuestion.options.find(
          ({ id }) => id === constraint.optionId,
        );

        return option && constraint.numberRange
          ? {
              option: getOptionOption({ option }),
              range: {
                end: constraint.numberRange.end,
                start: constraint.numberRange.start,
              },
            }
          : undefined;
      }),
    );
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.MATRIX) {
    return compact(
      apiConstraints.map((constraint) => {
        const statement = fullQuestion.options.find(
          ({ id }) => id === constraint.optionId,
        );
        const options = fullQuestion.matrixOptions
          .filter((option) => {
            return constraint.matrixOptionIds
              ? constraint.matrixOptionIds.includes(option.id)
              : false;
          })
          .map((option) => {
            return {
              label: option.title,
              value: option,
            };
          });

        if (!statement || options.length === 0) {
          return;
        }

        return {
          statement: getOptionOption({ option: statement }),
          options,
        };
      }),
    );
  } else if (fullQuestion.questionTypeId === QUESTION_TYPE.NUMBER) {
    return compact(
      apiConstraints.map((constraint) => {
        if (!constraint.numberRange) {
          return;
        }

        return {
          range: {
            end: constraint.numberRange.end,
            start: constraint.numberRange.start,
          },
        };
      }),
    );
  }

  return [];
}

export function hasConfiguredDisplayLogic(
  displayLogic: DisplayLogicOrGroups,
): boolean {
  return (
    displayLogic.filter((group) => {
      return group.some((andGroup) => {
        return isFullyConfiguredGroup(andGroup);
      });
    }).length > 0
  );
}

export function hasSurveyVariableReferencesForResource({
  resource,
  segment,
}: {
  resource: {
    id: number;
    type: 'concept' | 'matrixOption' | 'option' | 'question';
  };
  segment: SurveyVariableSegment;
}): boolean {
  if (
    resource.type === 'concept' ||
    resource.type === 'matrixOption' ||
    resource.type === 'option'
  ) {
    return segment.questions.some((question) => {
      return question.constraints.some((constraint) => {
        if (resource.type === 'concept') {
          return constraint.conceptId === resource.id;
        } else if (resource.type === 'matrixOption') {
          return constraint.matrixOptionIds
            ? constraint.matrixOptionIds.includes(resource.id)
            : false;
        } else if (resource.type === 'option') {
          return constraint.optionId === resource.id;
        }

        return false;
      });
    });
  } else if (resource.type === 'question') {
    return segment.questions.some(
      (question) => question.questionId === resource.id,
    );
  }

  return false;
}

export function hasDisplayLogicReferencesForResource({
  displayLogic,
  resource,
}: {
  displayLogic: DisplayLogicOrGroups;
  resource: {
    id: number;
    type: 'concept' | 'matrixOption' | 'option' | 'question';
  };
}) {
  return displayLogic.some((orGroup) => {
    return orGroup.some((andGroup) => {
      if (resource.type === 'question') {
        return andGroup.question?.value.id === resource.id;
      }

      return andGroup.constraints.some((constraint) => {
        if (
          isConstraintWithConcepts(constraint) &&
          resource.type === 'concept'
        ) {
          return castArray(constraint.concepts).some(
            (concept) => concept.value.id === resource.id,
          );
        } else if (isConstraintWithStatement(constraint)) {
          if (resource.type === 'matrixOption') {
            return castArray(constraint.options).some((option) => {
              return option.value.id === resource.id;
            });
          } else if (resource.type === 'option') {
            return constraint.statement?.value.id === resource.id;
          }
        } else if (
          isConstraintWithRanges(constraint) &&
          resource.type === 'option'
        ) {
          return constraint.option?.value.id === resource.id;
        } else if (
          isConstraintWithOptions(constraint) &&
          resource.type === 'option'
        ) {
          return castArray(constraint.options).some(
            (option) => option.value.id === resource.id,
          );
        }

        return false;
      });
    });
  });
}

export function isConstraintWithConcepts(
  constraint: DisplayLogicConstraint,
): constraint is ConstraintConcepts {
  return (constraint as ConstraintConcepts).concepts !== undefined;
}

export function isConstraintWithNumber(
  constraint: DisplayLogicConstraint,
): constraint is ConstraintWithNumber {
  return (
    (constraint as ConstraintWithRange).option === undefined &&
    (constraint as ConstraintWithNumber).range !== undefined
  );
}

export function isConstraintWithOptions(
  constraint: DisplayLogicConstraint,
): constraint is ConstraintOptions {
  return (constraint as ConstraintOptions).options !== undefined;
}

export function isConstraintWithRanges(
  constraint: DisplayLogicConstraint,
): constraint is ConstraintWithRange {
  // We need to check both "option" and "range" because number constraints have "range" but not "option".
  return (
    (constraint as ConstraintWithRange).option !== undefined &&
    (constraint as ConstraintWithRange).range !== undefined
  );
}

export function isConstraintWithStatement(
  constraint: DisplayLogicConstraint,
): constraint is ConstraintWithStatement {
  return (constraint as ConstraintWithStatement).statement !== undefined;
}

export function isFullyConfiguredGroup(group: DisplayLogicAndGroup): boolean {
  const { constraints, modifier, question } = group;

  let hasMisconfiguredConstraint = false;
  for (let i = 0; i < constraints.length; i++) {
    const constraint = constraints[i];
    if (isConstraintWithStatement(constraint)) {
      if (!constraint.statement || castArray(constraint.options).length === 0) {
        hasMisconfiguredConstraint = true;
        break;
      }
    } else if (isConstraintWithRanges(constraint)) {
      if (
        !constraint.option ||
        constraint.range.start === '' ||
        constraint.range.end === ''
      ) {
        hasMisconfiguredConstraint = true;
        break;
      }
    } else if (isConstraintWithNumber(constraint)) {
      if (constraint.range.start === '' || constraint.range.end === '') {
        hasMisconfiguredConstraint = true;
        break;
      }
    } else if (isConstraintWithConcepts(constraint)) {
      if (compact(castArray(constraint.concepts)).length === 0) {
        hasMisconfiguredConstraint = true;
        break;
      }
    } else {
      if (compact(castArray(constraint.options)).length === 0) {
        hasMisconfiguredConstraint = true;
        break;
      }
    }
  }

  return !!(question?.value && modifier?.value && !hasMisconfiguredConstraint);
}

export function validateDisplayLogicConstraints(
  constraints: DisplayLogicConstraint[],
): FormikErrors<DisplayLogicConstraint>[] | undefined {
  const constraintErrors: FormikErrors<DisplayLogicConstraint>[] = [];

  constraints.forEach((constraint) => {
    if (isConstraintWithStatement(constraint)) {
      const errors: FormikErrors<ConstraintWithStatement> = {};

      if (!constraint.statement) {
        errors.statement = 'Please select a statement.';
      }

      if (castArray(constraint.options).length === 0) {
        // Formik doesn't like this specification for the error but it's perfectly acceptable.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors.options = 'Please choose options.' as any;
      }

      constraintErrors.push(errors);
    } else if (isConstraintWithRanges(constraint)) {
      const errors: FormikErrors<ConstraintWithRange> = {};

      if (!constraint.option) {
        errors.option = 'Please select an option.';
      }

      if (constraint.range.end === '' || constraint.range.start === '') {
        const rangeErrors: FormikErrors<ConstraintWithRange['range']> = {};

        if (constraint.range.end === '') {
          rangeErrors.end = 'Required';
        }

        if (constraint.range.start === '') {
          rangeErrors.start = 'Required';
        }

        errors.range = rangeErrors;
      }

      constraintErrors.push(errors);
    } else if (isConstraintWithNumber(constraint)) {
      const errors: FormikErrors<ConstraintWithNumber> = {};

      if (constraint.range.end === '' || constraint.range.start === '') {
        const rangeErrors: FormikErrors<ConstraintWithRange['range']> = {};

        if (constraint.range.end === '') {
          rangeErrors.end = 'Required';
        }

        if (constraint.range.start === '') {
          rangeErrors.start = 'Required';
        }

        errors.range = rangeErrors;
      }

      constraintErrors.push(errors);
    } else if (isConstraintWithConcepts(constraint)) {
      const errors: FormikErrors<ConstraintConcepts> = {};

      if (castArray(constraint.concepts).length === 0) {
        // Formik doesn't like this specification for the error but it's perfectly acceptable.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors.concepts = 'Required' as any;
      }

      constraintErrors.push(errors);
    } else {
      const errors: FormikErrors<ConstraintOptions> = {};

      if (castArray(constraint.options).length === 0) {
        // Formik doesn't like this specification for the error but it's perfectly acceptable.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors.options = 'Required' as any;
      }

      constraintErrors.push(errors);
    }
  });

  const hasErrors = constraintErrors.some((errors) => !isEmpty(errors));

  return hasErrors ? constraintErrors : undefined;
}

export function validateDisplayLogic(
  displayLogic: DisplayLogicOrGroups,
): FormikErrors<DisplayLogicAndGroup>[][] | undefined {
  const displayLogicErrors: FormikErrors<DisplayLogicAndGroup>[][] = [];

  displayLogic.forEach((orGroup) => {
    const orGroupErrors: FormikErrors<DisplayLogicAndGroup>[] = [];

    orGroup.forEach((andGroup) => {
      const andGroupErrors: FormikErrors<DisplayLogicAndGroup> = {};

      const constraintErrors = validateDisplayLogicConstraints(
        andGroup.constraints,
      );
      if (constraintErrors) {
        andGroupErrors.constraints = constraintErrors;
      }

      if (!andGroup.modifier) {
        andGroupErrors.modifier = 'Please select a modifier.';
      }

      if (!andGroup.question) {
        andGroupErrors.question = 'Please select a question.';
      } else if (
        !isIdeaPresenterQuestion(andGroup.question.value) &&
        !!andGroup.question.value.monadicId &&
        !andGroup.concept
      ) {
        andGroupErrors.concept = 'Please select a concept.';
      }

      orGroupErrors.push(andGroupErrors);
    });

    displayLogicErrors.push(orGroupErrors);
  });

  const hasErrors = displayLogicErrors.some((orGroupErrors) => {
    return orGroupErrors.some((andGroupErrors) => {
      return !isEmpty(andGroupErrors);
    });
  });

  return hasErrors ? displayLogicErrors : undefined;
}
