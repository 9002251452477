import axios from 'axios';

import { Banner } from '../../types/domainModels';
import { getAPIUrl } from './baseAPI';

export const LIST_SAVED_CROSSTABS = ({ surveyId }: { surveyId: number }) => ({
  path: `/surveys/${surveyId}/saved-crosstabs`,
  version: 'v2' as const,
});

export async function listSavedCrosstabs({ surveyId }: { surveyId: number }) {
  return (
    await axios.get<Banner[]>(getAPIUrl(LIST_SAVED_CROSSTABS({ surveyId })))
  ).data;
}
