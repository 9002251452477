import { createUploadSignature } from 'services/backend/media';
import { DataUrl } from 'types/domainModels';
import { getEnvVar } from 'util/env';

const CLOUD_NAME = getEnvVar('CLOUDINARY_CLOUD_NAME');

export async function uploadMedia({
  mediaData,
  uploadType = 'auto',
}: {
  mediaData: string;
  uploadType: 'auto' | 'image' | 'video' | undefined;
}) {
  const { apiKey, signature, timestamp } = await createUploadSignature();

  const formData = new FormData();
  formData.append('api_key', apiKey);
  formData.append('file', mediaData);
  formData.append('signature', signature);
  formData.append('timestamp', `${timestamp}`);

  // Using axios leads to CORS errors since our axios instance has default headers that aren't
  // allowed by Cloudinary.
  const response = await fetch(
    `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/${uploadType}/upload`,
    { method: 'POST', body: formData },
  );
  const json = await response.json();

  return json as DataUrl;
}
