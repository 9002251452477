import { hasReferencesForResource } from 'util/references';
import { Question, QuestionBlock, SurveyVariable } from 'types/domainModels';
import { useModal } from '../../hooks/modals';

import Button from '../common/forms/Button';
import ButtonLoading from '../common/forms/ButtonLoading';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import Modal, { ModalHeader } from '../common/Modal';
import PreventDeleteResourceModal from './PreventDeleteResourceModal';

const DeleteQuestion = ({
  isDeleting,
  onConfirmDelete,
  questionBlocks,
  questionId,
  questions,
  surveyVariables,
}: {
  isDeleting: boolean;
  onConfirmDelete(questionId: number): void;
  questionBlocks: QuestionBlock[];
  questionId: number;
  questions: Question[];
  surveyVariables: SurveyVariable[];
}): JSX.Element => {
  const {
    isOpen: isConfirmDelete,
    onCloseModal,
    setIsOpen: setIsConfirmDelete,
  } = useModal();

  return (
    <>
      <IconBackground
        onClick={() => {
          setIsConfirmDelete(true);
        }}
        title="Delete"
      >
        <div className="w-4 h-4">
          <Icon id="trash" />
        </div>
      </IconBackground>
      {isConfirmDelete && (
        <DeleteQuestionModal
          isDeleting={isDeleting}
          onCloseModal={onCloseModal}
          onConfirmDelete={() => {
            onConfirmDelete(questionId);
          }}
          questionBlocks={questionBlocks}
          questionId={questionId}
          questions={questions}
          surveyVariables={surveyVariables}
        />
      )}
    </>
  );
};

export default DeleteQuestion;

const DeleteQuestionModal = ({
  isDeleting,
  onCloseModal,
  onConfirmDelete,
  questionBlocks,
  questionId,
  questions,
  surveyVariables,
}: {
  isDeleting: boolean;
  onCloseModal(): void;
  onConfirmDelete?(): void;
  questionBlocks: QuestionBlock[];
  questionId: number;
  questions: Question[];
  surveyVariables: SurveyVariable[];
}) => {
  const resource = { id: questionId, type: 'question' as const };
  const hasOtherReferences = hasReferencesForResource({
    questionBlocks,
    questions,
    resource,
    variables: surveyVariables,
  });

  if (hasOtherReferences) {
    return (
      <PreventDeleteResourceModal
        onCloseModal={onCloseModal}
        questionBlocks={questionBlocks}
        questions={questions}
        resource={resource}
        resourceTitle="Question"
        variables={surveyVariables}
      />
    );
  }

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>Remove Question</ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      <p>Are you sure you want to delete this question?</p>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <ButtonLoading
          grow
          hierarchy="destructive"
          isLoading={isDeleting}
          onClick={onConfirmDelete}
          size="lg"
          type="button"
        >
          Delete
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onCloseModal}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
