import { QuestionQuota } from 'types/forms';
import {
  QUESTION_TYPE,
  Question,
  QuestionQuotaElement,
  QuestionQuotaLogicalModifiers,
  QuestionQuotaResult,
} from 'types/domainModels';
import { orderBy } from 'lodash-es';

export function formQuotaToApiQuotaElement({
  quota,
}: {
  quota: QuestionQuota;
}): QuestionQuotaElement {
  return {
    disqualificationType: quota.isQualityDisqualification
      ? 'QUALITY'
      : 'NON_QUALITY',
    logicalModifier: quota.logicalModifier
      ?.value as QuestionQuotaLogicalModifiers,
    numberNeeded: quota.optionQuota,
    optionIndices: quota.options.map((option) => option.value),
  };
}

export function orderQuotasByOptions(quotas: QuestionQuotaResult[]) {
  return orderBy(quotas, (quota) => {
    const orderedOptions = orderBy(quota.options, (o) => o.sort);

    return orderedOptions[0]?.sort;
  });
}

export function questionSupportsQuotas(question: Question) {
  return (
    question.questionTypeId === QUESTION_TYPE.MULTIPLE_CHOICE ||
    question.questionTypeId === QUESTION_TYPE.RANKING
  );
}
