import { Form, Formik, useFormikContext } from 'formik';
import { ReactNode, useEffect } from 'react';

import { generateQuestionsSection } from '../../util/questions';
import {
  getInitialSurveyVariableFormData,
  SurveyVariableFormData,
  SurveyVariableFormDataValidated,
  validateSurveyVariableData,
} from '../../util/surveyVariables';
import { getNestedErrorMessages } from 'util/forms';
import { getQuestionOption } from '../../util/formOptions';
import {
  Question,
  QUESTION_TYPE,
  Survey,
  SurveyVariable,
} from '../../types/domainModels';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';
import { SurveyFlowStep } from '../../types/internal';
import {
  useDeleteVariable,
  useSaveSurveyVariables,
} from 'hooks/backend/surveyVariables';
import { useSubmitValidation } from '../../hooks/forms';

import { AddUserIds, Quotas, Segments } from './SurveyVariables';
import Button from '../common/forms/Button';
import ButtonLoading from 'components/common/forms/ButtonLoading';
import Card from '../common/Card';
import DeleteVariable from './DeleteVariable';
import ErrorDisplay from '../common/ErrorDisplay';
import FixedHeaderAndCollapsedSidebar from '../layout/FixedHeaderAndCollapsedSidebar';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormInput from '../common/forms/FormInput';
import IndexCard from '../common/IndexCard';
import { Sidebar } from '../layout/DefaultLayout';
import SkeletonSurveyCard from './SkeletonSurveyCard';
import SurveyEditHeader from './SurveyEditHeader';
import { SurveyWaveTitleEditPage } from './SurveyWaveTitle';
import SurveyWithSidebar from '../layout/SurveyWithSidebar';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';

interface SurveyVariablesLoadedProps {
  demographicQuestions: Question[];
  isShowingUnsavedChanges: boolean;
  onClickStep?(step: SurveyFlowStep): void;
  onDirtyChanged(isDirty: boolean): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onStepCompleted(): void;
  onVariableDeleted(): void;
  onVariableSaved(variable: SurveyVariable): void;
  questions: Question[];
  sidebar?: ReactNode;
  survey: Survey;
  surveyId: number;
  variable?: SurveyVariable;
}

const SurveyVariablesPage = ({
  isLoadingDemographicQuestions,
  isLoadingQuestions,
  isLoadingSurvey,
  isLoadingVariables,
  loadVariablesError,
  onClickStep,
  sidebar,
  survey,
  ...rest
}: Omit<SurveyVariablesLoadedProps, 'survey'> & {
  isLoadingDemographicQuestions: boolean;
  isLoadingQuestions: boolean;
  isLoadingSurvey: boolean;
  isLoadingVariables: boolean;
  loadVariablesError: Error | null;
  onClickStep(step: SurveyFlowStep): void;
  sidebar: ReactNode;
  survey: Survey | undefined;
}): JSX.Element => {
  if (
    isLoadingSurvey ||
    isLoadingDemographicQuestions ||
    isLoadingQuestions ||
    isLoadingVariables
  ) {
    return (
      <FixedHeaderAndCollapsedSidebar
        header={
          survey ? (
            <SurveyEditHeader onClickStep={onClickStep} survey={survey} />
          ) : null
        }
        sidebar={<Sidebar isCollapsed />}
      >
        <SurveyWithSidebar sidebar={sidebar}>
          <SurveyWaveTitleEditPage survey={survey} />

          <Card>
            <SkeletonSurveyCard />
          </Card>
        </SurveyWithSidebar>
      </FixedHeaderAndCollapsedSidebar>
    );
  }

  return survey && !loadVariablesError ? (
    <SurveyVariablesLoaded
      onClickStep={onClickStep}
      sidebar={sidebar}
      survey={survey}
      {...rest}
    />
  ) : (
    <ErrorDisplay
      message={`Failed to load the variable. ${
        loadVariablesError && ` Error: ${loadVariablesError.message}`
      }`}
    />
  );
};

export default SurveyVariablesPage;

const SurveyVariablesLoaded = ({
  demographicQuestions,
  isShowingUnsavedChanges,
  onClickStep,
  onDirtyChanged,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onStepCompleted,
  onVariableDeleted,
  onVariableSaved,
  questions,
  sidebar,
  survey,
  surveyId,
  variable,
}: SurveyVariablesLoadedProps): JSX.Element => {
  const { isPending: isSavingVariable, mutateAsync: saveVariable } =
    useSaveSurveyVariables({
      onError: (err: Error) => {
        onHasError();
        showErrorMessage(
          `There was an error saving the variable. Error: ${err.message}`,
        );
      },
      onSuccess: (data) => {
        showSuccessMessage('The variable was saved successfully.');
        onVariableSaved(data);
      },
      survey,
      variable,
    });

  const { isPending: isDeletingVariable, mutate: deleteVariable } =
    useDeleteVariable({
      onError: (err) => {
        showErrorMessage(
          `There was an error deleting the variable. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        onVariableDeleted();
      },
    });

  const initialValues = getInitialSurveyVariableFormData({
    questions: [
      ...demographicQuestions,
      ...questions,
      {
        title: 'Use Completion Date',
        id: -1,
        questionTypeId: QUESTION_TYPE.DATE,
      } as Question,
    ],
    variable,
  });

  return (
    <Formik<SurveyVariableFormData>
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(formData) => {
        return saveVariable(formData as SurveyVariableFormDataValidated);
      }}
      validate={(formData) => {
        return validateSurveyVariableData(formData);
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form className="h-full">
        <SurveyVariableForm
          isDeletingVariable={isDeletingVariable}
          isSavingVariable={isSavingVariable}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          onClickStep={onClickStep}
          onConfirmDelete={(variableId) => {
            deleteVariable({ surveyId, variableId });
          }}
          onDirtyChanged={onDirtyChanged}
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onHasError={onHasError}
          onStepCompleted={onStepCompleted}
          questions={questions}
          sidebar={sidebar}
          survey={survey}
          variable={variable}
        />
      </Form>
    </Formik>
  );
};

const SurveyVariableForm = ({
  isDeletingVariable,
  isSavingVariable,
  isShowingUnsavedChanges,
  onClickStep,
  onConfirmDelete,
  onDirtyChanged,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onStepCompleted,
  questions,
  sidebar,
  survey,
  variable,
}: {
  isDeletingVariable: boolean;
  isSavingVariable: boolean;
  isShowingUnsavedChanges: boolean;
  onClickStep?(step: SurveyFlowStep): void;
  onConfirmDelete(variableId: number): void;
  onDirtyChanged(isDirty: boolean): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onStepCompleted(): void;
  questions: Question[];
  sidebar: ReactNode;
  survey: Survey;
  variable: SurveyVariable | undefined;
}): JSX.Element => {
  const questionGroups = [
    generateQuestionsSection({
      questions: questions
        .filter((question) => question.isDemographic)
        .map((question) => getQuestionOption({ question })),
      title: 'Demographic Questions',
    }),
    generateQuestionsSection({
      questions: questions
        .filter(
          (question) =>
            ![QUESTION_TYPE.OPEN_ENDED, QUESTION_TYPE.GABOR_GRANGER].includes(
              question.questionTypeId,
            ) && !question.isDemographic,
        )
        .map((question) => {
          return getQuestionOption({ question });
        }),
      title: 'Survey Questions',
    }),
    {
      label: 'DATE RANGE',
      options: [
        {
          label: 'Use Completion Date',
          value: { id: -1, questionTypeId: QUESTION_TYPE.DATE } as Question,
        },
      ],
    },
  ];

  const { dirty } = useFormikContext<SurveyVariableFormData>();
  const needsSaving = !variable || dirty;

  const { errors, onClickSubmit, validateAndSubmit } = useSubmitValidation({
    isSaving: isSavingVariable,
    onHasError,
  });

  useEffect(() => {
    onDirtyChanged(dirty);
  }, [dirty, onDirtyChanged]);

  return (
    <FixedHeaderAndCollapsedSidebar
      header={
        survey ? (
          <SurveyEditHeader
            actionButton={
              <div>
                {needsSaving ? (
                  <ButtonLoading
                    hierarchy="primary"
                    isLoading={isSavingVariable}
                    onClick={onClickSubmit}
                    size="sm"
                    // This can't currently be a submit button since we handle the form submission
                    // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
                    type="button"
                  >
                    Save Variable
                  </ButtonLoading>
                ) : (
                  <Button
                    hierarchy="primary"
                    onClick={onStepCompleted}
                    size="sm"
                    type="button"
                  >
                    Next: Review
                  </Button>
                )}
              </div>
            }
            onClickStep={onClickStep}
            survey={survey}
          />
        ) : null
      }
      sidebar={<Sidebar isCollapsed />}
    >
      <SurveyWithSidebar sidebar={sidebar}>
        {errors ? (
          <div className="mb-8">
            <FormErrorsAlert errors={getNestedErrorMessages(errors)} />
          </div>
        ) : null}

        <SurveyWaveTitleEditPage survey={survey} />

        {isShowingUnsavedChanges && (
          <UnsavedChangesModal
            isSaving={isSavingVariable}
            onClickDiscardChanges={onDiscardChanges}
            onClickSaveChanges={validateAndSubmit}
            onCloseModal={onDismissUnsavedChanges}
          />
        )}

        <IndexCard>
          <div className="space-y-4">
            <div className="flex justify-between mb-2 px-6 p-6">
              <div className="w-1/2">
                <FormInput label="Title" name="title" size="md" />
              </div>
              {variable && (
                <DeleteVariable
                  isDeleting={isDeletingVariable}
                  onConfirmDelete={onConfirmDelete}
                  variableId={variable?.id}
                />
              )}
            </div>

            <Segments questions={questionGroups} />
            <hr className="text-light-grey" />
            <Quotas />
            <AddUserIds />
          </div>
        </IndexCard>
      </SurveyWithSidebar>
    </FixedHeaderAndCollapsedSidebar>
  );
};
