import { clsx } from 'clsx';
import { orderBy } from 'lodash-es';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  apiDataToFormData,
  getFormDataToDuplicate,
  formDataToApiData,
  getConceptTitle,
  isIdeaPresenterQuestion,
  QUESTION_TYPE_DISPLAY_NAMES,
} from '../../util/questions';
import {
  createQuestion,
  fetchQuestion,
} from '../../services/backend/questions';
import { DATE_FORMATS, formatDate } from '../../util/dates';
import { getMediaUrl } from '../../util/media';
import { getOptionTitle } from '../../util/options';
import {
  Question,
  QuestionTemplate,
  QUESTION_TYPE,
  Survey,
} from '../../types/domainModels';
import { QuestionFormDataValidated } from '../../types/forms';
import { questionQueries } from 'hooks/backend/questions';
import { showErrorMessage, showSuccessMessage } from '../../util/notifications';

import { useAuth } from '../../contexts/auth';
import { useModal } from '../../hooks/modals';
import ButtonLoading from '../common/forms/ButtonLoading';
import Checkbox from '../common/forms/Checkbox';
import DocumentAddIcon from '../common/icons/DocumentAddIcon';
import ErrorDisplay from '../common/ErrorDisplay';
import FormGroup from '../common/forms/FormGroup';
import Input from '../common/forms/Input';
import Modal, { ModalHeader } from '../common/Modal';
import Tab from '../common/Tab';
import TemplateLibIllustration from '../common/illustrations/TemplateLibIllustration';
import Tooltip from '../common/Tooltip';
import UserBubble from '../common/UserBubble';

const AddQuestionFromTemplate = ({
  onTemplateQuestionsAdded,
  questions,
  survey,
}: {
  onTemplateQuestionsAdded(): void;
  questions: Question[];
  survey: Survey;
}): JSX.Element => {
  const {
    isOpen: isQuestionTemplateModalOpen,
    onCloseModal: onCloseQuestionTemplateModal,
    setIsOpen: setIsQuestionTemplateModalOpen,
  } = useModal();

  return (
    <>
      <div
        className="hover:bg-gray-300 rounded px-1 cursor-pointer"
        onClick={() => setIsQuestionTemplateModalOpen(true)}
      >
        <span>+ From Template</span>
      </div>

      {isQuestionTemplateModalOpen && (
        <QuestionTemplateModal
          onCloseModal={onCloseQuestionTemplateModal}
          onTemplateQuestionsAdded={() => {
            onCloseQuestionTemplateModal();
            onTemplateQuestionsAdded();
          }}
          questions={questions}
          survey={survey}
        />
      )}
    </>
  );
};

export default AddQuestionFromTemplate;

const QuestionTemplateModal = ({
  onCloseModal,
  onTemplateQuestionsAdded,
  questions,
  survey,
}: {
  onCloseModal(): void;
  onTemplateQuestionsAdded(): void;
  questions: Question[];
  survey: Survey;
}): JSX.Element => {
  const { user } = useAuth();

  const [focusedTemplate, setFocusedTemplate] =
    useState<QuestionTemplate | null>(null);
  const [selectedTab, _setSelectedTab] = useState<'mine' | 'all'>('mine');
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<Set<number>>(
    new Set(),
  );
  const [titleFilter, setTitleFilter] = useState('');

  const {
    data: templates = [],
    error: loadTemplatesError,
    isError: hasLoadTemplatesError,
    isLoading: isLoadingTemplates,
  } = useQuery(questionQueries.listTemplates);
  const filteredTemplates = titleFilter
    ? templates.filter((template) => {
        const lowercaseFilter = titleFilter.toLowerCase();

        return (
          template.templateTitle?.toLowerCase().indexOf(lowercaseFilter) !==
            -1 || template.title.toLowerCase().indexOf(lowercaseFilter) !== -1
        );
      })
    : templates;
  const myTemplates = filteredTemplates.filter((template) => {
    return template.user.id === user?.id;
  });

  const selectedTemplates: QuestionTemplate[] = [];
  selectedTemplateIds.forEach((templateId) => {
    const template = templates.find((t) => t.id === templateId);
    if (template) {
      selectedTemplates.push(template);
    }
  });
  templates.filter((template) => {
    return selectedTemplateIds.has(template.id);
  });

  const onClickTemplate = useCallback((template: QuestionTemplate) => {
    setSelectedTemplateIds((selectedTemplateIds) => {
      const newSelectedTemplateIds = new Set(selectedTemplateIds);

      if (newSelectedTemplateIds.has(template.id)) {
        newSelectedTemplateIds.delete(template.id);

        // If the user is deselecting the template, we clear it from the focused, details section.
        setFocusedTemplate(null);
      } else {
        newSelectedTemplateIds.add(template.id);

        setFocusedTemplate(template);
      }

      return newSelectedTemplateIds;
    });
  }, []);

  function onChangeTab(newTab: 'all' | 'mine') {
    _setSelectedTab(newTab);

    // We clear the focused template because the one that was previously focused
    // may no longer be an option in the new list of templates for the new tab.
    setFocusedTemplate(null);
  }

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>
          Question Templates
        </ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
      size="auto"
    >
      <div className="w-template-modal">
        <p className="text-dark-grey text-sm">
          Select questions from your template library to add to the survey.
        </p>
        <div className="flex justify-between mt-4">
          <div className="w-3/5 mr-8">
            {hasLoadTemplatesError && (
              <ErrorDisplay
                message={`Failed to load template library. (${loadTemplatesError.message})`}
              />
            )}
            {isLoadingTemplates ? (
              <QuestionTemplatePlaceholderRows />
            ) : (
              <div>
                <div className="flex justify-between mb-2 border-b border-light-grey">
                  <div className="flex items-end space-x-4 text-sm">
                    <Tab
                      isActive={selectedTab === 'mine'}
                      onClick={() => {
                        onChangeTab('mine');
                      }}
                    >
                      My Templates ({myTemplates.length})
                    </Tab>
                    <Tab
                      isActive={selectedTab === 'all'}
                      onClick={() => {
                        onChangeTab('all');
                      }}
                    >
                      All Templates ({filteredTemplates.length})
                    </Tab>
                  </div>
                  <div className="w-56 -mt-2 mb-2">
                    <Input
                      onChange={(event) => {
                        setTitleFilter(event.target.value);
                      }}
                      placeholder="Filter templates..."
                      size="lg"
                      value={titleFilter}
                    />
                  </div>
                </div>
                <QuestionTemplatesLoaded
                  key={selectedTab}
                  focusedTemplate={focusedTemplate}
                  onClickTemplate={onClickTemplate}
                  selectedTemplateIds={selectedTemplateIds}
                  templates={
                    selectedTab === 'mine' ? myTemplates : filteredTemplates
                  }
                />
              </div>
            )}
          </div>
          <div className="w-2/5">
            <SelectedTemplatesDetailsPane
              focusedTemplate={focusedTemplate}
              numQuestions={selectedTemplateIds.size}
              onTemplateQuestionsAdded={onTemplateQuestionsAdded}
              questions={questions}
              selectedTemplates={selectedTemplates}
              survey={survey}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const QuestionTemplatePlaceholderRows = (): JSX.Element => {
  const rows = [1, 2, 3, 4];

  return (
    <>
      {rows.map((rowNum) => {
        return (
          <div key={rowNum} className="py-4 px-2 border-b border-light-grey">
            <div className="flex space-x-2 animate-pulse">
              <div className="w-5 h-5 mt-1 border border-light-grey rounded-full bg-light-grey" />
              <div className="w-full space-y-2">
                <div className="w-3/4 h-4 bg-light-grey" />
                <div className="w-1/2 h-4 bg-light-grey" />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const QuestionTemplatesLoaded = ({
  focusedTemplate,
  onClickTemplate,
  selectedTemplateIds,
  templates,
}: {
  focusedTemplate: QuestionTemplate | null;
  onClickTemplate(template: QuestionTemplate): void;
  selectedTemplateIds: Set<number>;
  templates: QuestionTemplate[];
}): JSX.Element => {
  return (
    <div className="h-96 overflow-auto">
      {templates.length === 0 && (
        <div className="mt-4 text-sm">
          <span>You have no templates. Use the</span>{' '}
          <div className="inline-block w-4 h-4">
            <DocumentAddIcon />
          </div>{' '}
          <span>icon in the header of an existing question to create one.</span>
        </div>
      )}
      {templates.map((template, i) => {
        const isFocused = focusedTemplate?.id === template.id;
        const isSelected = selectedTemplateIds.has(template.id);

        return (
          <ExistingQuestionTemplate
            key={template.id}
            isFocused={isFocused}
            isLastRow={i === templates.length - 1}
            isSelected={isSelected}
            onClickTemplate={() => onClickTemplate(template)}
            template={template}
          />
        );
      })}
    </div>
  );
};

const ExistingQuestionTemplate = ({
  isLastRow,
  isFocused,
  isSelected,
  onClickTemplate,
  template,
}: {
  isLastRow: boolean;
  isFocused: boolean;
  isSelected: boolean;
  onClickTemplate(): void;
  template: QuestionTemplate;
}): JSX.Element => {
  const { createdAt, id, templateDescription, templateTitle, title, user } =
    template;

  return (
    <div
      className={clsx(
        'flex items-start w-full py-4 px-2 space-x-2 cursor-pointer',
        {
          'bg-darker-white': isFocused,
          'hover:bg-dark-white': !isFocused,
          'border-b border-light-grey': !isLastRow,
        },
      )}
      onClick={onClickTemplate}
    >
      <div className="mt-1">
        <Checkbox
          checked={isSelected}
          name={`template-select-${id}`}
          // Without the onChange, the event is not propagating to the onClick of the top level div
          // which means the user isn't able to toggle the checkbox by clicking on the checkbox which
          // would be a weird experience.
          onChange={onClickTemplate}
          readOnly={true}
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between mb-1">
          <div className="pr-2">
            <p>{templateTitle}</p>
            <p className="text-dark-grey text-xs">
              Created:{' '}
              {formatDate(createdAt, {
                format: DATE_FORMATS.DEFAULT_DATE_FORMAT,
              })}
            </p>
          </div>
          <div className="flex-shrink-0">
            <UserBubble user={user} />
          </div>
        </div>
        <p className="text-dark-grey text-sm">{templateDescription}</p>
        <p className="mt-2 text-dark-grey text-sm italic">{title}</p>
      </div>
    </div>
  );
};

const SelectedTemplatesDetailsPane = ({
  focusedTemplate,
  numQuestions,
  onTemplateQuestionsAdded,
  questions,
  selectedTemplates,
  survey,
}: {
  focusedTemplate: QuestionTemplate | null;
  numQuestions: number;
  onTemplateQuestionsAdded(): void;
  questions: Question[];
  selectedTemplates: QuestionTemplate[];
  survey: Survey;
}): JSX.Element => {
  const { isPending: isAddingQuestions, mutateAsync: addQuestions } =
    useMutation({
      mutationFn: async () => {
        if (selectedTemplates.length === 0) {
          throw new Error(
            'You must select templates to add before proceeding.',
          );
        }

        const failedTemplates: { error: string; template: QuestionTemplate }[] =
          [];

        for (let i = 0; i < selectedTemplates.length; i++) {
          try {
            const questionData = await fetchQuestion({
              questionId: selectedTemplates[i].id,
            });

            await createQuestion({
              data: formDataToApiData({
                formData: getFormDataToDuplicate({
                  formData: apiDataToFormData({
                    question: questionData,
                    questions,
                  }),
                  questions,
                }) as QuestionFormDataValidated,
                survey,
              }),
            });
          } catch (err) {
            failedTemplates.push({
              error: err instanceof Error ? err.message : 'Unknown Error',
              template: selectedTemplates[i],
            });
            throw err;
          }
        }

        if (failedTemplates.length > 0) {
          const failedTemplatesString = failedTemplates
            .map(({ error, template }) => {
              return `${template.templateTitle} (${error})`;
            })
            .join('; ');

          showErrorMessage(
            `Failed to add questions to survey: ${failedTemplatesString}`,
          );
        } else {
          showSuccessMessage('Successfully added questions to survey.');
        }

        if (failedTemplates.length < selectedTemplates.length) {
          onTemplateQuestionsAdded();
        }

        return Promise.resolve();
      },
    });

  const illustration = (
    <div className="flex items-center justify-center w-full h-full">
      <div className="relative w-3/4 h-3/4 rounded-full bg-light-purple text-dark-grey text-sm">
        <div className="absolute bottom-0 left-1/2 w-1/2 transform -translate-x-1/2">
          <TemplateLibIllustration />
        </div>
      </div>
    </div>
  );

  return (
    // The -mt-4 is to pull this div up so the "Question Details" header aligns with
    // the "My Templates" and "All Templates" tabs.
    <div className="flex flex-col h-full -mt-4">
      {selectedTemplates.length > 0 ? (
        <div className="flex flex-col h-full">
          <div className="flex-grow">
            {focusedTemplate ? (
              <QuestionDetails selectedTemplate={focusedTemplate} />
            ) : (
              illustration
            )}
          </div>
          <div className="mt-4">
            <div className="flex items-start w-full space-x-4">
              <div className="flex-grow flex items-center space-x-2">
                <div className="flex items-center justify-center rounded-full w-6 h-6 bg-green text-white font-bold">
                  {numQuestions}
                </div>
                <span className="text-sm">questions selected</span>
                <Tooltip>
                  <div className="w-56">
                    <ol className="pl-4 list-decimal">
                      {selectedTemplates.map((template) => {
                        return (
                          <li key={template.id}>{template.templateTitle}</li>
                        );
                      })}
                    </ol>
                  </div>
                </Tooltip>
              </div>
              <ButtonLoading
                hierarchy="primary"
                isLoading={isAddingQuestions}
                onClick={() => {
                  addQuestions();
                }}
                size="md"
                type="button"
              >
                Add Questions
              </ButtonLoading>
            </div>
          </div>
        </div>
      ) : (
        illustration
      )}
    </div>
  );
};

const QuestionDetails = ({
  selectedTemplate,
}: {
  selectedTemplate: QuestionTemplate;
}) => {
  const {
    data: question = null,
    error: loadQuestionError,
    isError: hasLoadQuestionError,
    isLoading: isLoadingQuestion,
  } = useQuery(questionQueries.get({ questionId: selectedTemplate.id }));

  return (
    <div className="h-96 p-4 rounded bg-darker-white overflow-auto">
      <h1 className="mb-2 text-sm font-semibold">Question Details</h1>
      {hasLoadQuestionError && (
        <ErrorDisplay
          message={`Failed to load question details. (${loadQuestionError.message})`}
        />
      )}
      {isLoadingQuestion ? (
        <QuestionDetailsPlaceholderRows />
      ) : question ? (
        <div className="space-y-4 text-sm">
          <div className="inline-flex items-center py-0.5 px-1 rounded border border-primary-d-600 text-primary-d-600 text-tiny">
            <span className="uppercase">
              {isIdeaPresenterQuestion(question)
                ? 'Idea Presenter'
                : // TODO: Address compilation error
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  QUESTION_TYPE_DISPLAY_NAMES[question.questionTypeId]}
            </span>
          </div>
          <FormGroup label="Title">
            <p>{question.title}</p>
          </FormGroup>
          <QuestionDetailsConcepts question={question} />
          <QuestionDetailsOptions question={question} />
          <QuestionDetailsMatrixOptions question={question} />
        </div>
      ) : (
        <ErrorDisplay message="Failed to load question data. Please refresh your page and try again." />
      )}
    </div>
  );
};

const QuestionDetailsPlaceholderRows = (): JSX.Element => {
  const rows = [1, 2, 3, 4];

  return (
    <>
      {rows.map((rowNum) => {
        return (
          <div key={rowNum} className="mb-2">
            <div className="flex items-center space-x-2 animate-pulse">
              <div className="w-5 h-5 rounded-full bg-light-grey" />
              <div className="w-3/4 h-4 bg-light-grey" />
            </div>
          </div>
        );
      })}
    </>
  );
};

const QuestionDetailsConcepts = ({
  question,
}: {
  question: Question;
}): JSX.Element | null => {
  const concepts = question.concepts ?? [];
  if (concepts.length === 0) {
    return null;
  }

  return (
    <FormGroup label="Concepts">
      <ul className="space-y-2">
        {concepts.map((concept, index) => {
          return (
            <li key={concept.id} className="flex items-center space-x-2">
              <div className="flex items-center justify-center w-8 h-8">
                <img
                  className="max-w-full max-h-full"
                  src={getMediaUrl(concept.media)}
                />
              </div>
              <span>{getConceptTitle({ concept, index })}</span>
            </li>
          );
        })}
      </ul>
    </FormGroup>
  );
};

const QuestionDetailsOptions = ({
  question,
}: {
  question: Question;
}): JSX.Element | null => {
  if (
    isIdeaPresenterQuestion(question) ||
    [QUESTION_TYPE.NUMBER, QUESTION_TYPE.OPEN_ENDED].includes(
      question.questionTypeId,
    )
  ) {
    return null;
  }

  if (question.questionTypeId === QUESTION_TYPE.GABOR_GRANGER) {
    const parameters = question.options[0];
    if (!parameters) {
      return null;
    }

    return (
      <FormGroup label="Parameters">
        <ul className="pl-8 list-disc">
          <li>Min: ${parameters.rangeMin}</li>
          <li>Step: ${parameters.rangeStep}</li>
          <li>Max: ${parameters.rangeMax}</li>
        </ul>
      </FormGroup>
    );
  }

  let label = 'Options';
  if (question.questionTypeId === QUESTION_TYPE.MATRIX) {
    label = 'Statements';
  } else if (question.questionTypeId === QUESTION_TYPE.SCALE) {
    label = 'Scales';
  }

  return (
    <FormGroup label={label}>
      <ol className="pl-8 list-decimal">
        {question.options.map((option) => {
          return (
            <li key={option.id}>
              {getOptionTitle({ index: option.sort, option })}
            </li>
          );
        })}
      </ol>
    </FormGroup>
  );
};

const QuestionDetailsMatrixOptions = ({ question }: { question: Question }) => {
  const options = orderBy(question.matrixOptions, (o) => o.sort).map((o) => {
    return { id: o.id, title: o.title };
  });
  if (options.length === 0) {
    return null;
  }

  return (
    <FormGroup label="Options">
      <ol className="pl-8 list-decimal">
        {options.map((option) => {
          return <li key={option.id}>{option.title}</li>;
        })}
      </ol>
    </FormGroup>
  );
};
