function getPipingMatches(title: string) {
  // Example piping from previous question:
  // Matrix - ::(Q91575/M335460/ANSWER)
  // Multiple Choice - ::(Q91938/ANSWER)
  // Open-ended - ::(Q91938/ANSWER)
  // Ranking - ::(Q91573/R1/ANSWER)
  // Scale - ::(Q91571/S335448/ANSWER)
  return title.matchAll(
    /::\(Q(?<questionId>\d+)(\/(M(?<statementId>\d+))|\/(S(?<scaleId>\d+))|\/(R(?<rank>\d+)))*\/ANSWER\)/gi,
  );
}

export function hasPipingReferencesForResource({
  resource,
  title,
}: {
  resource: {
    id: number;
    type: 'concept' | 'matrixOption' | 'option' | 'question';
  };
  title: string;
}) {
  const pipeMatches = getPipingMatches(title);

  // Note that it's possible to pipe multiple responses from multiple previous
  // questions so we need to loop to handle all of them.
  for (const match of pipeMatches) {
    const questionId = Number(match.groups?.questionId);
    if (!questionId) {
      continue;
    }

    if (resource.type === 'question' && questionId === resource.id) {
      return true;
    } else if (match.groups?.scaleId && resource.type === 'option') {
      const scaleId = Number(match.groups.scaleId);

      if (scaleId === resource.id) {
        return true;
      }
    } else if (match.groups?.statementId && resource.type === 'option') {
      const statementId = Number(match.groups.statementId);

      if (statementId === resource.id) {
        return true;
      }
    }
  }

  return false;
}
