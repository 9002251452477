import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { PanelProvider } from 'types/domainModels';

export const LIST_PANEL_PROVIDERS = () => ({
  path: '/panelProviders',
  version: 'v2' as const,
});

export async function listPanelProviders() {
  return (
    await axios.get<
      { integrated: boolean; label: string; value: PanelProvider }[]
    >(getAPIUrl(LIST_PANEL_PROVIDERS()))
  ).data;
}
