import axios from 'axios';

import { getAPIUrl } from './baseAPI';
import { queryStringify } from 'util/api';
import {
  SurveyVariableQuota,
  SurveyVariableQuotaResult,
} from '../../types/domainModels';

export const CREATE_VARIABLE_QUOTAS = ({
  variableId,
}: {
  variableId: number;
}) => ({
  path: `/surveys/variables/${variableId}/quotas`,
  version: 'v1' as const,
});

export async function createVariableQuotas({
  data,
  variableId,
}: {
  data: { quotas: SurveyVariableQuota[] };
  variableId: number;
}) {
  return (
    await axios.post<SurveyVariableQuota[]>(
      getAPIUrl(CREATE_VARIABLE_QUOTAS({ variableId })),
      data,
    )
  ).data;
}

export const DELETE_VARIABLE_QUOTAS = ({
  variableId,
}: {
  variableId: number;
}) => ({
  path: `/surveys/variables/${variableId}/quotas`,
  version: 'v1' as const,
});

export async function deleteVariableQuotas({
  variableId,
}: {
  variableId: number;
}) {
  return (await axios.delete(getAPIUrl(DELETE_VARIABLE_QUOTAS({ variableId }))))
    .data;
}

export const FETCH_VARIABLE_QUOTAS_RESULTS = ({
  surveyId,
}: {
  surveyId: number;
}) => ({
  path: `/surveys/${surveyId}/variable-quota-results`,
  version: 'v1' as const,
});

export async function fetchVariableQuotaResults({
  surveyId,
  waveIds,
}: {
  surveyId: number;
  waveIds?: number[];
}) {
  return (
    await axios.get<SurveyVariableQuotaResult[]>(
      getAPIUrl(FETCH_VARIABLE_QUOTAS_RESULTS({ surveyId })),
      {
        params: { waveIds },
        // The default query param format for the waveIds array is waveIds[]=1&waveIds[]=2,
        // but the backend expects waveIds=1&waveIds=2.
        paramsSerializer: queryStringify,
      },
    )
  ).data;
}
