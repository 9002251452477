import { array, object, string } from 'yup';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { clsx } from 'clsx';
import { ErrorMessage, Form, Formik, useField, useFormikContext } from 'formik';
import { isEqual } from 'lodash-es';
import { ReactNode, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createCharge } from '../../services/backend/billing';
import { createInvoice } from '../../services/backend/invoices';
import {
  EndMessage,
  Question,
  Survey,
  SurveyVariable,
} from '../../types/domainModels';
import {
  fetchSurvey,
  updateSurveyStatus,
} from '../../services/backend/surveys';
import { formatDollars } from '../../util/currency';
import { getApiError } from 'util/api';
import { getNestedErrorMessages } from 'util/forms';
import { isLucidSurvey } from 'util/surveys';
import { showErrorMessage } from '../../util/notifications';
import { SurveyFlowStep } from '../../types/internal';
import { SURVEY_STATUSES } from 'constants/surveyStatuses';
import { surveyQueries, useUpdateSurvey } from 'hooks/backend/surveys';
import { useCurrentOrganization } from '../../hooks/backend/organizations';
import { useSubmitValidation } from '../../hooks/forms';

import ButtonLoading from 'components/common/forms/ButtonLoading';
import Card from '../common/Card';
import FixedHeaderAndCollapsedSidebar from '../layout/FixedHeaderAndCollapsedSidebar';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormFieldError from 'components/common/forms/FormFieldError';
import FormGroup from '../common/forms/FormGroupNew';
import FormGroupOld from '../common/forms/FormGroup';
import FormInput from '../common/forms/FormInput';
import FormLabel from 'components/common/forms/FormLabel';
import Hyperlink from '../common/Hyperlink';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import IndexCard from 'components/common/IndexCard';
import InputFormik from 'components/common/forms/InputFormik';
import ReviewSurveySummary from './ReviewSurveySummary';
import Select from 'components/common/forms/Select';
import { Sidebar } from '../layout/DefaultLayout';
import SkeletonSurveyCard from './SkeletonSurveyCard';
import SurveyEditHeader from './SurveyEditHeader';
import { SurveyWaveTitleEditPage } from './SurveyWaveTitle';
import SurveyWithSidebar from '../layout/SurveyWithSidebar';
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabWithAlert,
} from 'components/common/Tabs';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';

interface ReviewFormData {
  checkout: {
    email: string;
    name: string;
    paidFor: boolean;
    paymentMethod: 'creditCard' | 'invoice';
    phone: string;
    purchaseOrder: string;
  };
  customize: {
    buttonText: { advance: string; done: string };
    endMessages: EndMessage[];
  };
}

function requiredForCreditCard(
  paidFor: ReviewFormData['checkout']['paidFor'],
  paymentMethod: ReviewFormData['checkout']['paymentMethod'],
) {
  return !paidFor && paymentMethod === 'creditCard';
}

const ReviewSchema = object().shape({
  checkout: object().shape({
    email: string().when(['paidFor', 'paymentMethod'], {
      is: requiredForCreditCard,
      then: (schema) =>
        schema
          .email('Please provide a valid email.')
          .required('Please provide your email.'),
    }),
    name: string().when(['paidFor', 'paymentMethod'], {
      is: requiredForCreditCard,
      then: (schema) => schema.required('Please provide your name.'),
    }),
  }),
  customize: object().shape({
    buttonText: object().shape({
      advance: string()
        .max(30, 'Can be at most 30 characters.')
        .required('Can not be empty.'),
      done: string()
        .max(30, 'Can be at most 30 characters.')
        .required('Can not be empty.'),
    }),
    endMessages: array(
      object().shape({
        title: string()
          .max(48, 'Title can be at most 48 characters.')
          .required('Title is required.'),
      }),
    ),
  }),
});

const ReviewStep = ({
  isLoadingSurvey,
  isShowingUnsavedChanges,
  onClickStep,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onReviewDirtyChanged,
  onStepCompleted,
  onSurveySaved,
  questions,
  sidebar,
  survey,
  surveyVariables,
}: {
  isLoadingSurvey: boolean;
  isShowingUnsavedChanges: boolean;
  onClickStep(step: SurveyFlowStep): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onReviewDirtyChanged(dirty: boolean): void;
  onStepCompleted(): void;
  onSurveySaved(): void;
  questions: Question[];
  sidebar: ReactNode;
  survey: Survey | undefined;
  surveyVariables: SurveyVariable[];
}): JSX.Element => {
  if (isLoadingSurvey) {
    return (
      <FixedHeaderAndCollapsedSidebar
        header={
          survey ? (
            <SurveyEditHeader onClickStep={onClickStep} survey={survey} />
          ) : null
        }
        sidebar={<Sidebar isCollapsed />}
      >
        <SurveyWithSidebar sidebar={sidebar}>
          <SurveyWaveTitleEditPage survey={survey} />

          <SkeletonSurveyCard />
        </SurveyWithSidebar>
      </FixedHeaderAndCollapsedSidebar>
    );
  }

  return survey ? (
    <ReviewStepLoaded
      isShowingUnsavedChanges={isShowingUnsavedChanges}
      onClickStep={onClickStep}
      onDiscardChanges={onDiscardChanges}
      onDismissUnsavedChanges={onDismissUnsavedChanges}
      onHasError={onHasError}
      onReviewDirtyChanged={onReviewDirtyChanged}
      onStepCompleted={onStepCompleted}
      onSurveySaved={onSurveySaved}
      questions={questions}
      sidebar={sidebar}
      survey={survey}
      surveyVariables={surveyVariables}
    />
  ) : (
    <p>Failed to load the survey.</p>
  );
};

export default ReviewStep;

const ReviewStepLoaded = ({
  isShowingUnsavedChanges,
  onClickStep,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onReviewDirtyChanged,
  onStepCompleted,
  onSurveySaved,
  questions,
  sidebar,
  survey,
  surveyVariables,
}: {
  isShowingUnsavedChanges: boolean;
  onClickStep(step: SurveyFlowStep): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onReviewDirtyChanged(dirty: boolean): void;
  onStepCompleted(): void;
  onSurveySaved(): void;
  questions: Question[];
  sidebar: ReactNode;
  survey: Survey;
  surveyVariables: SurveyVariable[];
}): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();

  const organization = useCurrentOrganization();
  const canChoosePaymentMethod = !!organization?.useInvoice;

  const initialValues = {
    customize: {
      buttonText: {
        advance: survey.customizations?.buttonText?.advance || 'Next',
        done: survey.customizations?.buttonText?.done || 'Done',
      },
      endMessages: survey.customizations?.endMessages ?? [
        {
          subtitle:
            'We appreciate your interest in this survey. However, it is not currently accepting responses.',
          title: 'Thank you',
          type: 'closed',
        },
        {
          subtitle: 'Thanks for completing this survey.',
          title: "You're all done!",
          type: 'completed',
        },
        {
          subtitle: "You didn't qualify in the audience for this campaign.",
          title: "Sorry, you didn't qualify",
          type: 'disqualified',
        },
      ],
    },
    checkout: {
      email: '',
      name: '',
      paidFor: survey.paidFor,
      paymentMethod: canChoosePaymentMethod ? 'invoice' : 'creditCard',
      phone: '',
      purchaseOrder: '',
    },
  } satisfies ReviewFormData;

  const { isPending: isUpdatingSurvey, mutate: updateSurvey } = useUpdateSurvey(
    {
      onError: (err) => {
        showErrorMessage(
          `There was an error attempting to save your changes. Error: ${err.message}`,
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          surveyQueries.survey({ surveyId: survey.id }),
        );

        onSurveySaved();
      },
    },
  );

  const { isPending: isLaunching, mutate: payAndLaunch } = useMutation({
    mutationFn: async (formData: ReviewFormData) => {
      // We want to be extra careful here and re-fetch the survey from the backend to check
      // if it's been paid for or not (as opposed to relying on front-end state which may be outdated).
      const surveyRefetched = await fetchSurvey({
        surveyId: survey.id,
      });

      if (!surveyRefetched.paidFor) {
        if (formData.checkout.paymentMethod === 'creditCard') {
          const cardElement = elements?.getElement('card');
          if (!stripe || !cardElement) {
            throw new Error('Could not load Stripe to complete payment.');
          }

          const { error, token } = await stripe.createToken(cardElement);
          if (error) {
            throw new Error(error.message);
          }

          await createCharge({
            data: {
              amount: surveyRefetched.estimatedBalance,
              campaignId: surveyRefetched.id,
              cardEmail: formData.checkout.email,
              cardName: formData.checkout.name,
              cardPhone: formData.checkout.phone,
              stripeToken: token?.id || '',
              termsAccepted: true,
            },
          });
        } else {
          await createInvoice({
            data: {
              cost: surveyRefetched.estimatedBalance,
              purchaseOrder: formData.checkout.purchaseOrder,
              surveyId: surveyRefetched.id,
            },
          });
        }
      }

      await updateSurveyStatus({
        data: { statusId: SURVEY_STATUSES.LIVE.id },
        surveyId: surveyRefetched.id,
      });
    },
    onError: (err) => {
      queryClient.invalidateQueries(
        surveyQueries.survey({ surveyId: survey.id }),
      );

      showErrorMessage(
        getApiError(err) ||
          `There was an error attempting to launch your survey. Error: ${err.message}`,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        surveyQueries.survey({ surveyId: survey.id }),
      );
      onStepCompleted();
    },
  });

  // We invalidate the survey on mount because the cost of the survey might have changed since the
  // last time the survey was fetched. We want to ensure we have the most up-to-date value.
  const surveyId = survey.id;
  useEffect(() => {
    queryClient.invalidateQueries(surveyQueries.survey({ surveyId }));
  }, [surveyId, queryClient]);

  return (
    <Formik<ReviewFormData>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(formData) => {
        if (!isEqual(initialValues.customize, formData.customize)) {
          updateSurvey({
            data: {
              customizations: {
                buttonText: formData.customize.buttonText,
                endMessages: formData.customize.endMessages,
              },
            },
            surveyId: survey.id,
          });
        } else {
          payAndLaunch(formData);
        }
      }}
      validateOnChange={false}
      validationSchema={ReviewSchema}
    >
      <Form className="h-full">
        <ReviewForm
          canChoosePaymentMethod={canChoosePaymentMethod}
          initialValues={initialValues}
          isLoading={isUpdatingSurvey || isLaunching}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          onClickStep={onClickStep}
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onHasError={onHasError}
          onReviewDirtyChanged={onReviewDirtyChanged}
          questions={questions}
          sidebar={sidebar}
          survey={survey}
          surveyVariables={surveyVariables}
        />
      </Form>
    </Formik>
  );
};

const ReviewForm = ({
  canChoosePaymentMethod,
  initialValues,
  isLoading,
  isShowingUnsavedChanges,
  onClickStep,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onReviewDirtyChanged,
  questions,
  sidebar,
  survey,
  surveyVariables,
}: {
  canChoosePaymentMethod: boolean;
  initialValues: ReviewFormData;
  isLoading: boolean;
  isShowingUnsavedChanges: boolean;
  onClickStep(step: SurveyFlowStep): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onReviewDirtyChanged(dirty: boolean): void;
  questions: Question[];
  sidebar: ReactNode;
  survey: Survey;
  surveyVariables: SurveyVariable[];
}): JSX.Element => {
  const { dirty, values } = useFormikContext<ReviewFormData>();

  const [{ value: paymentMethod }, , paymentMethodHelpers] = useField<
    ReviewFormData['checkout']['paymentMethod']
  >('checkout.paymentMethod');

  const { errors, onClickSubmit, validateAndSubmit } =
    useSubmitValidation<ReviewFormData>({
      isSaving: isLoading,
      onHasError,
    });

  let submitText =
    survey.paidFor || !isLucidSurvey(survey) ? 'Launch' : 'Pay & Launch';

  // If the customization fields have changed, the first step is to save those changes
  // and then the user can launch the survey.
  const hasDirtyCustomizations =
    dirty && !isEqual(initialValues.customize, values.customize);
  if (hasDirtyCustomizations) {
    submitText = 'Save Changes';
  }

  useEffect(() => {
    onReviewDirtyChanged(hasDirtyCustomizations);
  }, [hasDirtyCustomizations, onReviewDirtyChanged]);

  const nestedErrors = errors ? getNestedErrorMessages(errors) : [];

  return (
    <FixedHeaderAndCollapsedSidebar
      header={
        survey ? (
          <SurveyEditHeader
            actionButton={
              <ButtonLoading
                disabled={nestedErrors.length > 0}
                hierarchy="primary"
                isLoading={isLoading}
                onClick={onClickSubmit}
                size="sm"
                // This can't currently be a submit button since we handle the form submission
                // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
                type="button"
              >
                {submitText}
              </ButtonLoading>
            }
            onClickStep={onClickStep}
            survey={survey}
          />
        ) : null
      }
      sidebar={<Sidebar isCollapsed />}
    >
      <SurveyWithSidebar sidebar={sidebar}>
        {nestedErrors.length > 0 && (
          <div className="mb-8">
            <FormErrorsAlert actionWord="launching" errors={nestedErrors} />
          </div>
        )}

        <SurveyWaveTitleEditPage survey={survey} />

        {isShowingUnsavedChanges && (
          <UnsavedChangesModal
            isSaving={isLoading}
            onClickDiscardChanges={onDiscardChanges}
            onClickSaveChanges={validateAndSubmit}
            onCloseModal={onDismissUnsavedChanges}
          />
        )}

        <TabGroup>
          <TabList size="sm">
            <Tab>Summary</Tab>
            <TabWithAlert hasAlert={!!errors?.customize}>
              Customize
            </TabWithAlert>
            {isLucidSurvey(survey) && (
              <TabWithAlert hasAlert={!!errors?.checkout}>
                Checkout
              </TabWithAlert>
            )}
          </TabList>
          <TabPanels>
            <TabPanel>
              <ReviewSurveySummary
                questions={questions}
                survey={survey}
                surveyVariables={surveyVariables}
              />
            </TabPanel>
            <TabPanel>
              <Customizations />
            </TabPanel>
            {isLucidSurvey(survey) && (
              <TabPanel>
                <Card>
                  <h1 className="mb-2">Checkout</h1>

                  {survey.paidFor ? (
                    <div className="flex items-center mt-4 space-x-2 text-sm">
                      <IconBackground title="Paid For">
                        <div className="w-4 h-4 text-primary-d-600">
                          <Icon id="check" />
                        </div>
                      </IconBackground>
                      <span>This survey has been paid for.</span>
                    </div>
                  ) : (
                    <TabGroup
                      onChange={(index) => {
                        paymentMethodHelpers.setValue(
                          index === 0 ? 'invoice' : 'creditCard',
                        );
                      }}
                      selectedIndex={paymentMethod === 'invoice' ? 0 : 1}
                    >
                      {canChoosePaymentMethod && (
                        <TabList size="sm">
                          <Tab>Pay via Invoice</Tab>
                          <Tab>Pay via Credit Card</Tab>
                        </TabList>
                      )}

                      <div className="space-y-4">
                        <TabPanels>
                          <TabPanel>
                            <InvoiceForm cost={survey.estimatedBalance} />
                          </TabPanel>
                          <TabPanel>
                            <CreditCardForm cost={survey.estimatedBalance} />
                          </TabPanel>
                        </TabPanels>

                        <p className="text-dark-grey text-xs italic">
                          By launching this survey you agree to Glass's{' '}
                          <Hyperlink href="https://www.useglass.com/terms">
                            terms of service
                          </Hyperlink>
                          .
                        </p>
                      </div>
                    </TabGroup>
                  )}
                </Card>
              </TabPanel>
            )}
          </TabPanels>
        </TabGroup>
      </SurveyWithSidebar>
    </FixedHeaderAndCollapsedSidebar>
  );
};

const END_MESSAGE_TYPE_DISPLAYS: Record<EndMessage['type'], string> = {
  closed: 'Survey Closed',
  completed: 'Survey Completed',
  disqualified: 'Respondent Disqualified',
};

const ConstrainedLengthTextField = ({
  label,
  maxLength,
  name,
  value,
}: {
  label: string;
  maxLength: number;
  name: string;
  value: string;
}) => {
  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <InputFormik name={name} size="md" type="text" />
      <div className="flex items-center justify-between">
        <FormFieldError error={<ErrorMessage name={name} />} />
        <div
          className={clsx('text-gray-d-500 text-right text-xs', {
            'text-red font-semibold': value.length > maxLength,
          })}
        >
          {value.length} / {maxLength} characters
        </div>
      </div>
    </FormGroup>
  );
};

const Customizations = () => {
  const [{ value: buttonText }] = useField<
    ReviewFormData['customize']['buttonText']
  >('customize.buttonText');
  const [{ value: endMessages }] = useField<
    ReviewFormData['customize']['endMessages']
  >('customize.endMessages');
  const [endMessageIndex, setEndMessageIndex] = useState(0);

  const currentEndMessage = endMessages[endMessageIndex];
  const endMessagesOptions = endMessages.map(({ type }) => {
    return {
      label: END_MESSAGE_TYPE_DISPLAYS[type],
      value: type,
    };
  });

  return (
    <div className="space-y-4">
      <IndexCard>
        <div className="p-6 space-y-6">
          <div className="space-y-1">
            <span className="text-gray-d-800 font-medium">
              Modify the message your audience gets to see.
            </span>
            <p className="text-gray-d-700">
              Customize your Messages for a personalized survey experience
            </p>
          </div>
          <div className="space-y-4">
            <FormGroup>
              <FormLabel>Type of Message</FormLabel>
              <Select
                onChange={(value) => {
                  setEndMessageIndex(
                    endMessages.findIndex(({ type }) => type === value?.value),
                  );
                }}
                options={endMessagesOptions}
                value={endMessagesOptions[endMessageIndex]}
              />
            </FormGroup>
            <ConstrainedLengthTextField
              key={`customize.endMessages.${endMessageIndex}.title`}
              label="Title"
              maxLength={48}
              name={`customize.endMessages.${endMessageIndex}.title`}
              value={currentEndMessage.title}
            />
            <FormGroup>
              <FormLabel>Subtitle (Optional)</FormLabel>
              <InputFormik
                name={`customize.endMessages.${endMessageIndex}.subtitle`}
                size="md"
                type="text"
              />
            </FormGroup>
          </div>
        </div>
      </IndexCard>
      <IndexCard>
        <div className="p-6 space-y-6">
          <div className="space-y-1">
            <span className="text-gray-d-800 font-medium">
              Modify Button Text
            </span>
            <p className="text-gray-d-700">
              Customize the text on survey buttons.
            </p>
          </div>
          <div className="space-y-4">
            <ConstrainedLengthTextField
              key="buttonText.advance"
              label="Advance Survey Button"
              maxLength={30}
              name="customize.buttonText.advance"
              value={buttonText.advance}
            />
            <ConstrainedLengthTextField
              key="buttonText.done"
              label="Complete Survey Button"
              maxLength={30}
              name="customize.buttonText.done"
              value={buttonText.done}
            />
          </div>
        </div>
      </IndexCard>
    </div>
  );
};

const CreditCardForm = ({ cost }: { cost: string }): JSX.Element => {
  return (
    <div className="space-y-4">
      <p className="text-sm">
        Your credit card will be charged{' '}
        <strong className="font-bold">{formatDollars(Number(cost))}</strong>.
      </p>
      <FormInput
        id="name"
        label="Name"
        labelFor="name"
        name="checkout.name"
        size="md"
        type="text"
      />
      <FormInput
        id="email"
        label="Billing Email"
        labelFor="email"
        name="checkout.email"
        size="md"
        type="email"
      />
      <FormInput
        id="phone"
        label="Phone"
        labelFor="phone"
        name="checkout.phone"
        size="md"
        type="phone"
      />
      <FormGroupOld label="Card Info" labelFor="stripe-card-element">
        <div className="w-full rounded-lg border border-gray-d-300 shadow-sm py-2 px-3">
          <CardElement id="stripe-card-element" />
        </div>
      </FormGroupOld>
    </div>
  );
};

const InvoiceForm = ({ cost }: { cost: string }): JSX.Element => {
  return (
    <div className="space-y-4">
      <p className="text-sm">
        An invoice will be submitted for{' '}
        <strong className="font-bold">{formatDollars(Number(cost))}</strong>.
      </p>
      <FormInput
        id="purchaseOrder"
        label="Purchase Order"
        labelFor="purchaseOrder"
        name="checkout.purchaseOrder"
        placeholder="(Optional)"
        size="md"
        type="text"
      />
    </div>
  );
};
